import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { SelectionData} from "../../../../asyncThunks/main/documents/create/getDocKeyDataThunk";
import {Filter} from "./filterDocNumSlice";

export interface AttributeFilter {
	docKeyPartId : string;
	docKeyPartLabel: string;
	selectionDataList: SelectionData[];
}
export interface DateFilter {
	start?: string, //ISODate
	end?: string //ISODate
}
export interface IConfigureFilterState extends Filter{}

export const initialConfigureFilterState: IConfigureFilterState = {}

const configureFilterSlice = createSlice({
	name: 'configureFilterSlice',
	initialState: initialConfigureFilterState,
	reducers: {

		setConfigureFilter(state, action: PayloadAction<IConfigureFilterState>){
			return action.payload
		},

		resetConfigureFilter: (state: IConfigureFilterState, action?: PayloadAction<undefined>) => {
			return initialConfigureFilterState
		},
		changeAttributeFilter: (state, action: PayloadAction<AttributeFilter>) => {

			if (state.attributeFilterList){
				// filtering out old attributeFilter entry
				state.attributeFilterList = state.attributeFilterList.filter((existingAttributeFilter) => {
					return existingAttributeFilter.docKeyPartId !== action.payload.docKeyPartId
				})

			}else{
				// creating new state if needed
				if (action.payload.selectionDataList.length < 1){
					return
				}
				state.attributeFilterList = []
			}

			// polluting new selectionDataList
			if (action.payload.selectionDataList.length > 0){
				state.attributeFilterList?.push(action.payload)
			}

			// removing .attributeFilterList attribute if not needed
			if (state.attributeFilterList && state.attributeFilterList.length < 1){
				state.attributeFilterList = undefined
			}
		},

		// null is deletion, undefined is ignored
		changeDateFilter: (state, action: PayloadAction< {startDate?: string | null, endDate?: string | null} >) => {

			if (action.payload.startDate){
				if (!(state.dateFilter)){
					state.dateFilter  = {}
				}
				state.dateFilter.start = action.payload.startDate

			}

			if (action.payload.startDate === null){
				// deleting
				if (state.dateFilter){
					state.dateFilter.start = undefined
				}
			}

			if (action.payload.endDate){
				if (!(state.dateFilter)){
					state.dateFilter = {}
				}
				state.dateFilter.end = action.payload.endDate
			}

			if (action.payload.endDate === null){
				// deleting
				if (state.dateFilter){
					state.dateFilter.end = undefined
				}
			}

			// checking if start and end is undefined
			if ((state.dateFilter) && (state.dateFilter.start === undefined )&& (state.dateFilter.end === undefined)){
				// filtering out .dateFilterList
				state.dateFilter = undefined
			}

		}
	}
})

export default configureFilterSlice.reducer
export const {resetConfigureFilter, changeDateFilter,  setConfigureFilter, changeAttributeFilter} = configureFilterSlice.actions
