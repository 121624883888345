import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Text, Badge, Button, createStyles, Loader, Select, SelectItem, Textarea, Tooltip} from '@mantine/core';
import {closeAllModals, closeModal, openConfirmModal, openModal} from "@mantine/modals";
import 'dayjs/locale/de';

import {IconAlertCircle} from "@tabler/icons";
import {
    Attribute,
    AttributeConfig,
    AttributePart,
    AttributeSelectionValue,
    DocKeyData,
    DocKeyPart,
    getDocKeyData,
    MAXIMUM_DOCNUM_COMMENT_LENGTH, SelectionData
} from "../../../redux/asyncThunks/main/documents/create/getDocKeyDataThunk";
import {useAppDispatch} from "../../../redux/hooks";
import {useSelector} from "react-redux";
import {
    configureFilterSelector,
    docNumFilterSelector,
    projectsSelector
} from "../../../redux/rootReducer";
import {UI_TEXTS} from "../../../texts/ui";
import {isAbortError} from "../../../texts/apiErrors";
import {FullscreenLoader} from "../FullscreenLoader";
import {ConfirmModalHeader, ConfirmType} from "../../../common/global/modals/ConfirmModal";
import {DEFAULT_STYLES} from "../../../common/global/themeStyles";
import {nanoid} from "nanoid";
import {ModalHeader} from "../../../common/global/modals/ModalHeader";

import {ModalSettings} from "@mantine/modals/lib/context";
import {getAppStore} from "../../../redux/store";
import {ModalLoader} from "../../../common/global/modals/ModalLoader";
import {
    AttributeSection,
    FillableComponents,
    useSectionComponentStyles, useSectionStyle, SectionIconWidth, AttributeValueSelection
} from "./CreateDocument";
import {DatePicker} from '@mantine/dates';
import {
    Filter,
    setFilter
} from "../../../redux/slices/main/documents/get/filterDocNumSlice";
import {
    AttributeFilter,
    changeAttributeFilter, changeDateFilter,
    resetConfigureFilter, setConfigureFilter
} from "../../../redux/slices/main/documents/get/configureFilterSlice";
import {SectionLabel} from "../../../common/documents/createDocument/SectionLabel";
import SmallLabel from "../../../common/global/headers/SmallLabel";
import {SectionFullHeader} from "../../../common/documents/createDocument/SectionFullHeader";
import {DataDisplayItem} from "../../../common/global/DataDisplay";

export function FilterModal():ModalSettings{

    const modalId = nanoid(3)
    return {
        modalId: modalId,
        onClose: ()=>{
            getAppStore().dispatch(resetConfigureFilter())
        },
        size: DEFAULT_STYLES.modalWidth,
        children: (
            <ModalContainer modalId={modalId}/>
        ),
    }
}

function ModalContainer ({modalId}: {modalId: string}){

    const [docKeyData, setDocKeyData] = useState<null | DocKeyData>(null);
    const [fillableComponents, setFillableComponents] = useState<null | FillableComponents >(null);
    const dispatch = useAppDispatch()
    const projectState = useSelector(projectsSelector)

    useEffect(() => {

        if (!docKeyData){

            // getting docKeyData
            const request = dispatch(getDocKeyData({projectId: projectState.activeProject?._id!}))
            request.then((action)=> {

                if (getDocKeyData.fulfilled.match(action)){

                    if (action.payload){

                        setDocKeyData(action.payload)

                        // building fillableComponent items.
                        // for the Filter Modal this is only the AttributeComponent
                        const attributeComponents = action.payload.activeDocKeyParts.fillableParts.attributesParts.map((element) => {
                            return <AttributeComponent hideRequirement attributeConfig={element.attributeConfig} key={element.attributeConfig._id} docKeyPart={element.docKeyPart} attributes={element.attributes}/>
                        })

                        setFillableComponents({attributesComponents: attributeComponents, commentsComponents: <></> })
                    }

                }else{

                    // check if aborted
                    if (isAbortError(action.error)){
                        return
                    }

                    // unable to get DocKeyData
                    // todo configure error modal
                    openConfirmModal({
                        cancelProps: { style: {display:"none"} },
                        onConfirm() {
                            closeAllModals()
                        },
                        labels: {cancel: "", confirm: UI_TEXTS.main.global.docNum.create.errorOpeningButton},
                        title: <ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.main.global.docNum.create.errorOpeningTitle} message={UI_TEXTS.main.global.docNum.create.errorOpeningMessage} />
                    })
                }
            })

            return () => {
                // `createAsyncThunk` attaches an `abort()` method to the promise
                request.abort()
            }
        }
    }, []);

    return (
        <div key={modalId.concat("0")} style={{
            padding: DEFAULT_STYLES.modalContainerPadding
        }}>
            <ModalHeader closeCallback={()=>{

                //check if there are any inputs
                closeAllModals()
                dispatch(resetConfigureFilter())

            }} modalId={modalId} key={modalId.concat("1")} title="Filter verwalten" subtitle=""></ModalHeader>
            {
                [((docKeyData !== null) && (fillableComponents !== null)) ? <Content docKeyData={docKeyData} key={docKeyData.docKeyId} fillableComponents={fillableComponents!}/> : <ModalLoader/>]
            }
        </div>
    )
}

function DateSection({title, children} : {title?: string, children: any}) {

    const {classes} = useSectionStyle()

    return (
        <div className={classes.section}>
            <div className={classes.headerContainer}>
                <img src='./illustrations/main/documents/DateIcon.svg' width={SectionIconWidth}/>
                <p className={classes.header}>{title ? title : "Datum"}</p>
            </div>
            <div>
                {children}
            </div>

        </div>
    );
}
function DateFilter() {

    const {classes} = useSectionComponentStyles()
    const dispatch = useAppDispatch()

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const today = new Date();
    const maxStartDate = (endDate) ? (endDate <= today ? endDate : today) : today
    const configureFilter = useSelector(configureFilterSelector)



    useEffect(() => {

         let startDateValue: Date | null = null
        let endDateValue: Date | null = null

        if (configureFilter.dateFilter && configureFilter.dateFilter.start){
            startDateValue = new Date(configureFilter.dateFilter.start)
            setStartDate(startDateValue)

        }else{
            setStartDate(null)
        }

        if (configureFilter.dateFilter && configureFilter.dateFilter.end){
            endDateValue = new Date(configureFilter.dateFilter.end)
            setEndDate(endDateValue)

        }else{
            setEndDate(null)
        }

    }, [configureFilter]);


    return (<div className={classes.container}>

        <div style={{
            display:"flex",
            gap: 40
        }}>

            <DatePicker
                locale="de"
                maxDate={maxStartDate}
                value={startDate}
                onChange={(newStartDate) => {
                    if (newStartDate && endDate && newStartDate > endDate){
                        setEndDate(null)
                    }
                    setStartDate((newStartDate))
                    const newValue = newStartDate ? newStartDate.toISOString() : null
                    dispatch(changeDateFilter({startDate: newValue}))
                }}
                transition={DEFAULT_STYLES.transition} transitionDuration={DEFAULT_STYLES.transitionDuration}
                label="Startdatum"
                placeholder="Auswählen"

            />
            <DatePicker
                locale="de"
                value={endDate}
                transition={DEFAULT_STYLES.transition} transitionDuration={DEFAULT_STYLES.transitionDuration}
                maxDate={today}
                minDate={(startDate) ?  new Date(startDate.setDate(startDate.getDate() + 1)) : undefined}
                onChange={(newEndDate) => {
                    if (newEndDate && startDate && newEndDate < startDate){
                        setStartDate(null)

                    }
                    setEndDate((newEndDate))

                    const newValue = newEndDate ? newEndDate.toISOString() : null
                    dispatch(changeDateFilter({endDate: newValue}))

                }}
                label="Enddatum"
                placeholder="Auswählen"
            />

        </div>

    </div>)
}


function AttributeComponent({docKeyPart, attributes, attributeConfig, hideRequirement}: {attributeConfig: AttributeConfig, docKeyPart: AttributePart, attributes: Attribute[], hideRequirement?: boolean} ) {

    const {classes} = useSectionComponentStyles()

    const [selectionDataList, setSelectionDataList] = useState<SelectionData[]>([]);
    const dispatch = useAppDispatch()

    const configureFilter = useSelector(configureFilterSelector)
    const [didMount, setDidMount] = useState(false)
    useEffect(() => { setDidMount(true) }, [])

    // checking if data already exists
    useMemo(() => {

        if (configureFilter.attributeFilterList){
            const docKeyPartValue = configureFilter.attributeFilterList.find((attributeFilter: AttributeFilter) => {
                return attributeFilter.docKeyPartId === docKeyPart._id
            })
            if (docKeyPartValue){
                // there is a stored data for this part
                // changeSelectionList
                const attributeSelectionValues = docKeyPartValue.selectionDataList
                setSelectionDataList(attributeSelectionValues)
            }

        }

    }, []);

    useEffect(() => {

        if (didMount) {

            if (!configureFilter.attributeFilterList){
                // reset occurred
                setSelectionDataList([])
            }
        }


    }, [configureFilter]);

    // callback that will be passed to AttributeFilter
    const changeSelectionList = (oldItem: SelectionData | null, newItem: SelectionData | null)=> {

        let newSelectionDataList = [...selectionDataList]

        if ((oldItem !== null)){
            // filter out the old item

            newSelectionDataList = newSelectionDataList.filter((element) => {
                return element.attributeValueId !== oldItem.attributeValueId;
            })

            // old item is now removed.
            // filtering all dependencies parentAttributeValueIds
            let deletedAttributeId: string[] = []
            deletedAttributeId.push(oldItem.attributeId)

            while(true) {

                let noElementsFiltered = true
                newSelectionDataList = newSelectionDataList.filter((element) => {

                    if (deletedAttributeId.includes(element.parentAttributeId)){

                        // selectedList we want to remove
                        // filter out
                        deletedAttributeId.push(element.attributeId)
                        noElementsFiltered = false
                        return false
                    }else{
                        return true
                    }
                })

                if (noElementsFiltered){
                    // all elements filteredOut
                    break
                }
            }
        }

        // adding new selection
        if (newItem !== null){
            newSelectionDataList.push(newItem)
        }

        setSelectionDataList(newSelectionDataList)

        const attributeFilter: AttributeFilter = {
            docKeyPartId : docKeyPart._id!,
            docKeyPartLabel: docKeyPart.label,
            selectionDataList: newSelectionDataList
        }
        dispatch(changeAttributeFilter(attributeFilter))
    }

    const attributeSelections: any = useMemo(() => {

        const isSingleSelection = attributes.length === 1
        const isNestedSelection = attributes.length >= 2

        if (isSingleSelection){

            // setting up single selection
            const renderedAttribute = attributes[0]
            return ((<AttributeValueSelection key={renderedAttribute._id} selectionDataList={selectionDataList} isSingleSelection changeSelectionList={changeSelectionList}  required={false} attribute={renderedAttribute}/>))

        }else if (isNestedSelection){

            return attributeConfig.activeAttributes.map((activeAttributeId, index) => {

                // getting data of Attribute
                const activeAttribute = attributes.find((currentAttribute)=>{
                    return activeAttributeId === currentAttribute._id
                })

                if (!activeAttribute){
                    return(<Alert styles={{
                        root:{
                            paddingTop: 20,
                            paddingRight:30
                        }
                    }} icon={<IconAlertCircle size={16} />} title={"Fehler bei Attribut mit Id: " + activeAttributeId } color="red">{null}</Alert>)
                }

                return (<AttributeValueSelection key={activeAttributeId} parentAttributeId={attributeConfig.activeAttributes[index - 1]} selectionDataList={selectionDataList} isSingleSelection={false} changeSelectionList={changeSelectionList} required={false} attribute={activeAttribute}/>)
            })

        }else{

            // there is no attribute
            return(<Alert styles={{
                root:{
                    paddingTop: 20,
                    paddingRight:30
                }
            }} icon={<IconAlertCircle size={16} />} title="Keine Attribute hinterlegt!" color="yellow">{null}</Alert>)
        }

    }, [selectionDataList]);

    return (<div className={classes.container}>

        <SectionFullHeader required={hideRequirement ? false : (!(docKeyPart.isOptional))} label={docKeyPart.label} description={docKeyPart.description}/>

        <div className={classes.selectionContainer}>{
            attributeSelections
        }</div>

    </div>)
}


export function FilterBadge({label, value}: {label: string, value: string}) {

    const {classes} = createStyles((theme, _params) => ({
        filterContainer: {
            display: "inline-flex",
            backgroundColor: "#f8f6ee",
            color: "#000000",
            paddingTop:8,
            flexDirection: "row",
            paddingBottom:8,
            alignContent: "center",
            alignItems: "center",
            paddingLeft:18,
            paddingRight:18,
            borderRadius: 100,
            gap: 10,
            fontSize: theme.fontSizes.sm
        }
    }))()

    return (
        <div className={classes.filterContainer}>
            <div style={{width: 4, height: 4, borderRadius: 100, backgroundColor: "#f1ad03"}}/>
            <div style={{display: "flex", gap: 7}}>
                <p style={{fontWeight: 500}}>{label +":"} </p>
                <p>{value}</p>
            </div>
        </div>
    );
}

export enum FilterDisplayType {
    ConfigurationFilter = "ConfigurationFilter",
    DocNumFilter = "DocNumFilter"
}
export function FilterDisplay({filter, type} : {filter: Filter, type: FilterDisplayType}) {

    const {classes} = useSectionStyle()

    const badges = useMemo(() => {

        const views = []
        // building possible dateFilter views
        if (filter.dateFilter){
            if (filter.dateFilter.start){
                views.push(    <FilterBadge label="Startdatum" value={(new Date (filter.dateFilter.start).toLocaleString("de-DE", {
                    timeZone: 'Europe/Berlin',
                    year: "numeric", month: "long", day: "numeric"
                }))} />)
            }
            if (filter.dateFilter.end){
                views.push(    <FilterBadge label="Enddatum" value={(new Date (filter.dateFilter.end).toLocaleString("de-DE", {
                    timeZone: 'Europe/Berlin',
                    year: "numeric", month: "long", day: "numeric"
                }))} />)
            }
        }

        if (filter.attributeFilterList){

            for (const filterElement of (filter.attributeFilterList)){

                for (const selectionData of filterElement.selectionDataList){
                    views.push(   <FilterBadge label={selectionData.attributeLabel} value={selectionData.attributeValueLabel} />)
                }

            }
        }
        return (views.length > 0) ? views : null
    }, [filter]);

    if (!badges && (type === FilterDisplayType.DocNumFilter)){
        return <></>
    }
    if (!badges){
        return null
    }
    return (
        <div style={{paddingBottom: type === FilterDisplayType.DocNumFilter ? 8 : 50,
                    marginTop: type === FilterDisplayType.DocNumFilter ? 66 : undefined,
                    borderBottom: type === FilterDisplayType.DocNumFilter ? "none" : undefined
            }} className={classes.section}>
            {


            }

            {type === FilterDisplayType.DocNumFilter ? <div style={{display:"flex", gap: 6,marginBottom: 8}}>
                <SmallLabel>Filter aktiv</SmallLabel>
            </div> : <></>}
            {type === FilterDisplayType.ConfigurationFilter ? <div style={{display:"flex", flexDirection: "column", gap: 1, marginBottom: 25}}>
                <Text  weight={"600"} size={DEFAULT_STYLES.fontSize+1}>Konfigurierter Filter</Text>
                <SectionLabel>Folgende Werte wurden momentan für den Filter definiert:</SectionLabel>
            </div> : <></>}



            {badges ? ( <div style={{display: "flex", flexWrap:"wrap", gap: 16}}>
                {
                    badges
                }
            </div>) :  (<Alert styles={{

                root: {
                    color: "#383b3e",
                    backgroundColor: DEFAULT_STYLES.defaultBackgroundColor,

                },
                title: {
                    fontWeight: 500
                },
                wrapper: {
                    backgroundColor: DEFAULT_STYLES.defaultBackgroundColor,
                    marginTop: 8
                }
            }} icon={<IconAlertCircle size={20} />} variant="light" title="Kein Filter definiert" >
                {null}
            </Alert>)}

        </div>
    );
}


function Content({fillableComponents, docKeyData}: {docKeyData: DocKeyData, fillableComponents: FillableComponents}) {

    const [loading, setLoading] = useState(false);
    const configurationFilter = useSelector(configureFilterSelector)
    const docNumFilter = useSelector(docNumFilterSelector)
    const dispatch = useAppDispatch()

    useMemo(() => {

        dispatch(setConfigureFilter(docNumFilter.filter))

    }, [docNumFilter.filter]);

    const showResetButton = (configurationFilter.attributeFilterList && configurationFilter.attributeFilterList.length >= 0) || (configurationFilter.dateFilter && (configurationFilter.dateFilter.start || configurationFilter.dateFilter?.end))

    return (
        <div>
            <FullscreenLoader visible={loading}/>
            <div key={docKeyData.docKeyId}>

                <FilterDisplay type={FilterDisplayType.ConfigurationFilter} filter={configurationFilter}/>

                <DateSection title={"Datum"}><DateFilter /></DateSection>

                {fillableComponents.attributesComponents.length > 0 ?  <AttributeSection title={"Attribute"}>{fillableComponents.attributesComponents}</AttributeSection> : <></> }

                <div style={{display: "flex", marginTop: 30}}>

                    <Button style={{display: showResetButton ? undefined : "none"}} variant="default" onClick={()=>{

                        dispatch(resetConfigureFilter())
                    }}>Zurücksetzen</Button>

                    <Button style={{marginLeft: "auto"}} disabled={false} onClick={()=>{
                        const questionModalId = nanoid(4)
                        openConfirmModal({
                            modalId: questionModalId,
                            onConfirm() {

                                openConfirmModal({
                                    cancelProps: { style: {display:"none"} },
                                    onConfirm() {
                                        closeAllModals()
                                        // dispatch to the docNumFilter
                                        dispatch(setFilter(configurationFilter))
                                    },
                                    closeOnClickOutside: false,
                                    closeOnConfirm: false,
                                    title: <ConfirmModalHeader  type={ConfirmType.Success}
                                                                title={UI_TEXTS.main.global.docNum.filter.saveFilterSuccessTitle}
                                                                message={UI_TEXTS.main.global.docNum.filter.saveFilterSuccessMessage}/>
                                })

                            },
                            closeOnConfirm: false,
                            closeOnClickOutside: false,
                            title: <ConfirmModalHeader  type={ConfirmType.None}
                                                        title={UI_TEXTS.main.global.docNum.filter.saveFilterTitle}
                                                        message={UI_TEXTS.main.global.docNum.filter.saveFilterMessage}/>
                        })
                    }}>Speichern</Button>
                </div>
            </div>
        </div>
    );
}





