import {createAsyncThunk} from "@reduxjs/toolkit"
import {API_ENDPOINTS, axiosInstance} from "../../../apiConfigs";



export interface ICreateAttributeValueRequest{
	projectId: string,
	attributeId: string ,
	parentId: string, //parent AttributeValueId
	description: string,
	value: string
}

export const createAttributeValueThunk = createAsyncThunk(
	'createAttributeValueThunk/start',
	async (data: ICreateAttributeValueRequest, thunkAPI) => {
		const res = await axiosInstance.post(API_ENDPOINTS.private.attributeValue.create, data)
		return res.data
	}
)

