import {createAsyncThunk} from "@reduxjs/toolkit"
import {API_ENDPOINTS, axiosInstance} from "../../../../apiConfigs";

export interface SelectionData { parentAttributeId: string, parentAttributeValueId: string, attributeValueId: string, attributeId: string, attributeValueLabel: string, attributeLabel: string }

export interface AttributeSelectionValue {
		attributeValues: SelectionData[]
}

export const MAXIMUM_DOCNUM_COMMENT_LENGTH = 100
export interface AttributeConfig {
	_id: string;
	docKeyPartId: string; //foreignKey of a DocKeyPart
	activeAttributes: string[] //IDs of attributes that are active
}
export interface DocKeyData {
	docKeyId : string,
	docKeyLabel: string,
	docKeyDescription: string,
	activeDocKeyParts: {
		// active DocKeyParts that can be polluted by the FE
		// currently only attributes and comment
		all: DocKeyPart[],
		fillableParts: FillableParts //NOTE: can be empty
	},
	allDocKeyParts: DocKeyPart [] // with inactive and active - for the future, unstructured
}
interface FillableParts {
	attributesParts: {
		docKeyPart: AttributePart,
		attributeConfig: AttributeConfig,
		attributes: Attribute[] //active attributes only. I.e, those that are in attributeConfig. NOTE: can also be empty
	}[],
	commentsParts: CommentPart[]
}
export interface DocKeyPart { // basic docKey data

	_id?: string;
	typeId: string; //foreignKey of DocKeyPartType
	projectId: string; //foreignKey of Project
	docKeyId: string; //foreignKey of DocKey
	description: string;
	label: string; //identifies this part in a script
	isOptional: boolean //only comments can be .isOptional. Everything else will be ignored. //todo check how we can improve this
	isActive: boolean
}
export interface AttributePart extends DocKeyPart{

	//attributes: Attribute[] with minimum of 1 element is required when creating a new docKeyPart
	attributeValueSeparator: string; // rule: must have a of 1 length
}

export interface CommentPart extends DocKeyPart{}

export interface Attribute {
	_id: string,
	attributeConfigId: string, //foreignKey of an AttributeConfig
	label: string,
	description: string,
	isActive: boolean,
	isOptional: boolean,
}

export const getDocKeyData = createAsyncThunk(
	'getDocKeyData/start',
	async (data: { projectId: string }, thunkAPI) => {
		const res = await axiosInstance.post(API_ENDPOINTS.private.docKey.getAll, data)
		return res.data as DocKeyData
	}
)

