import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AttributeFilter, DateFilter, IConfigureFilterState} from "./configureFilterSlice";
import {nanoid} from "nanoid";

export interface IFilterDocNumState {
	filter: Filter,
	searchValue: string
}

export interface Filter {
	filterId?: any,
	attributeFilterList?: AttributeFilter []
	dateFilter?: DateFilter
}
export const initialFilterDocNumSate: IFilterDocNumState = {
	filter: {},
	searchValue: ""
}

const filterDocNumSlice = createSlice({ //will be used to send to backend
	name: 'filterDocNumSlice',
	initialState: initialFilterDocNumSate,
	reducers: {

		setFilter(state, action: PayloadAction<IConfigureFilterState>){
			state.filter = {
				...action.payload,
				filterId: nanoid(6)
			}
		},
		changeSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload
		}

	}
})

export default filterDocNumSlice.reducer
export const {changeSearchValue, setFilter} = filterDocNumSlice.actions
