import React from 'react';
import {CancelButton} from "../buttons/CancelButton";
import {ConfirmButton} from "../buttons/ConfirmButton";
import {createStyles} from "@mantine/core";


export enum ActionGroupType  {
    Separated,
    Together
}

export function ModalActionGroup({type, buttons} : {type: ActionGroupType, buttons: { rightButton?: React.ReactNode | undefined, leftButton?: React.ReactNode | undefined }}) {

    switch (type){
        case ActionGroupType.Separated:
            return (<Container>
                {buttons.leftButton ? buttons.leftButton : <></>}
                <div style={{marginLeft: "auto"}}>
                    {buttons.rightButton ? buttons.rightButton : <></>}
                </div>
            </Container>)
        case ActionGroupType.Together:
            return (<Container>
                <div style={{marginLeft: "auto", display: "flex", gap: 14}}>
                {buttons.leftButton ? buttons.leftButton : <></>}
                    {buttons.rightButton ? buttons.rightButton : <></>}
                </div>
            </Container>)
        default:
            throw new Error ("No valid ActionGroupType provided.")
    }

}


function Container({children}: {children: any}) {
    const {classes} = createStyles((theme, _params) => ({
        container: {
            display: "flex",paddingTop: 22, borderTop: `1px solid #E9ECEF`
        }
    }))()

    return (
        <div className={classes.container}>
            {children}
        </div>    );
}

