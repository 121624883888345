import React from 'react';
import {Button, createStyles} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {DEFAULT_STYLES} from "../../common/global/themeStyles";
import {AppLogo} from "../../common/assetComponents/logos/appLogo";

export function AuthNavigation(props: {hideRightSection?: boolean}) {

    const {classes} = useStyles()
    const navigate = useNavigate();

    return (
        <div className={classes.navigation}>
            <div onClick={(event) => {
                navigate("/");
            }}className={classes.navigationLeft}>
                <AppLogo size={DEFAULT_STYLES.logoSizes.lg}/>
            </div>
            <div className="LandingHeader_Centre">

            </div>
            { props.hideRightSection ? null : (<div className="LandingHeader_Right">

                <Button variant="default" onClick={() => {
                    navigate("/auth/register");
                }} > Registrieren</Button>

            </div>) }

        </div>
    );
}

const useStyles = createStyles((theme, _params, getRef) => ({

    navigation: {
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        maxHeight: 50,
        height: DEFAULT_STYLES.defaultPadding,
        padding: 30,
        justifyContent:"space-between"
    },
    navigationLeft: {
        '&:hover': { cursor: "pointer"
        }
    },


}));

