import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Project} from "../auth/projectsSlice";


export interface ISessionConfig{
    lastActiveProject: Project | null
}

export const initialSessionConfig: ISessionConfig = {
    lastActiveProject: null
}

const sessionSlice = createSlice({
    name: 'sessionConfig',
    initialState: initialSessionConfig,
    reducers: {
        setSessionConfigProject: (state, action: PayloadAction<Project | null>) => {
            state.lastActiveProject = action.payload ? action.payload : null
        }
    }
})

export default sessionSlice.reducer
export const {setSessionConfigProject} = sessionSlice.actions
