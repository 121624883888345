import React from 'react';


export const SmallLabelStyle = {
    fontWeight: 500,
    fontSize: 15,
    color: "#7c7e87", //8a8a8d 797980FF
}

export function SmallLabel(props: {children: any, color?: string, fontSize?: number}) {

    const style = {...SmallLabelStyle}
    if (props.color){
        style.color = props.color
    }
    if (props.fontSize){
        style.fontSize = props.fontSize
    }
    return (
        <p style={style}>{props.children}</p>
    );
}

export default SmallLabel;