import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Documents from './routes/main/Documents';
import Login from './routes/auth/Login';
import NotFound from './routes/NotFound';
import {MainRoot} from './routes/main/MainRoot';
import ResetPassword from "./routes/auth/ResetPassword";
import NewPassword from "./routes/auth/NewPassword";
import Register from "./routes/auth/Register";
import VerifyUser from "./routes/auth/VerifyUser";
import {createStyles} from "@mantine/core";
import AuthRoot from "./routes/auth/AuthRoot";
import {useEffect} from "react";

const App = ()  => {

	useEffect(() => {
		const ele = document.getElementById('ipl-progress-indicator')
		if(ele){
			// fade out
			ele.classList.add('available')
			setTimeout(() => {
				// remove from DOM
				ele.outerHTML = ''
			}, 0)
		}
	}, []);


	//NOTE defining routes for the app
	const router = createBrowserRouter([
		{
			path: '/',
			element: <MainRoot />,
			errorElement: <NotFound />,
			children: [
				{
					path: '/documents',
					element: <Documents/>
				}
			],
		},
		{
			path: '/auth',
			element: <AuthRoot />,
			errorElement: <NotFound />,
			children: [
				{
					path: '/auth/login',
					element: <Login />,
				},
				{
					path: '/auth/resetPassword',
					element: <ResetPassword />
				},
				{
					path: '/auth/newPassword',
					element: <NewPassword />
				},
				{
					path: '/auth/register',
					element: <Register />
				},
				{
					path: '/auth/verifyUser',
					element: <VerifyUser />
				}
			],
		},

	])

	const {classes} = useStyles()

	return (
	  <div className={classes.contentContainer} >
		  <RouterProvider router={router} />
	  </div>
  );
}

const useStyles = createStyles((theme, _params) => ({

	contentContainer: {
		backgroundColor: "#FFFFFF" //#FAFAFA
	}

}));

export default App;
