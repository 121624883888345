import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RowData} from "../../../../../components/main/documents/ListDocuments";

export interface IDocNumListState {
	rowData: RowData[]
}
export const initialDocNumList: IDocNumListState = {
	rowData: [],
}
const docNumListSlice = createSlice({ //will be used to send to backend
	name: 'docNumList',
	initialState: initialDocNumList,
	reducers: {
		setRowData(state, action: PayloadAction<RowData[]>){
			state.rowData = action.payload
		},
	}
})

export default docNumListSlice.reducer
export const {setRowData} = docNumListSlice.actions
