import {LoadingOverlay, MantineProvider} from '@mantine/core';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import {ModalsProvider} from "@mantine/modals";
import {
	DEFAULT_STYLES, getComponentStyles,
} from "./common/global/themeStyles";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import {getAppStore} from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const persistor = persistStore(getAppStore());

root.render(
		<Provider store={getAppStore()}>
			<PersistGate loading={<LoadingOverlay visible={true}/>} persistor={persistor}>
				<MantineProvider
					withGlobalStyles
					withNormalizeCSS
					theme={{
// @ts-ignore
						components: getComponentStyles(),
						colorScheme: 'light',
						colors: {
							mediumBlue: ["#0804C9", "#3B5BDB", "#4263EB" , "#4C6EF5", "#5C7CFA", "#748FFC","#91A7FF" , "#BAC8FF" ,"#DBE4FF", "#EDF2FF"],
						},
						focusRing: "never",
						primaryColor: "mediumBlue",
						fontSizes: {
							xs: 12,
							sm: DEFAULT_STYLES.fontSize,
							md: 17,
							lg: 20,
							xl: 25,
						},
						radius: {
							xs: 4,
							sm: 6,
							md: DEFAULT_STYLES.defaultRadius,
							lg: 12,
							xl: 18
						},
						defaultRadius: DEFAULT_STYLES.defaultRadius,
						headings: {
							fontFamily: 'Euclid Square'
						},
						fontFamily: "Euclid Square"
					}}
				>

					<ModalsProvider labels={{ confirm: 'Weiter', cancel: 'Abbrechen' }} modalProps={{
						size: DEFAULT_STYLES.confirmModalWidth,
						withCloseButton: false,
					}}>
						<App />
					</ModalsProvider>

				</MantineProvider>
			</PersistGate>
		</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


