import React, {useMemo, useState} from 'react'
import {MainHeader} from "../../common/global/headers/MainHeader";
import {ActionIcon, Button, createStyles, Menu, Text, TextInput, Tooltip} from "@mantine/core";
import {DEFAULT_STYLES} from "../../common/global/themeStyles";
import {IconSearch} from "@tabler/icons";
import {useSelector} from "react-redux";
import {docNumFilterSelector, docNumListSelector, projectsSelector, userSelector} from "../../redux/rootReducer";
import {closeAllModals, openConfirmModal, openModal} from "@mantine/modals";
import {CreateDocumentModal} from "../../components/main/documents/CreateDocument";
import {ListDocuments} from "../../components/main/documents/ListDocuments";
import {useAppDispatch} from "../../redux/hooks";
import {changeSearchValue} from "../../redux/slices/main/documents/get/filterDocNumSlice";
import {nanoid} from "nanoid";
import {FilterDisplay, FilterDisplayType, FilterModal} from "../../components/main/documents/FilterModal";
import {UI_TEXTS} from "../../texts/ui";
import {ConfirmModalHeader, ConfirmType} from "../../common/global/modals/ConfirmModal";
// @ts-ignore
import {CSVLink} from "react-csv";
import {Dots} from "tabler-icons-react";
import {APP_ROLE, hasMinimumAppRole} from "../../redux/slices/auth/userSlice";
import {LoadingStatus} from "../../redux/util";
import {FullscreenLoader} from "../../components/main/FullscreenLoader";
import {AlternativeContent, AlternativeContentType} from "./MainRoot";
import {hasMinimumProjectRole, PROJECT_ROLE} from "../../redux/slices/auth/projectsSlice";

const Documents = () => {
	const {classes} = createStyles(theme => ({


		content: {

			paddingLeft: DEFAULT_STYLES.defaultPadding,
			paddingRight: DEFAULT_STYLES.defaultPadding,
			paddingTop: DEFAULT_STYLES.defaultPadding,
			paddingBottom: 80,
			minHeight: "100%",
			backgroundColor:"#FAFAFA"
		}
	}))()

	const projectsState = useSelector(projectsSelector)
	const userState = (useSelector(userSelector))

	const [tableRowsLength, setTableRowsLength] = useState<number | null>(null);

	const changeTableRowsLength = (newLength: number | null) =>{
		setTableRowsLength(newLength)
	}

	const headerId = useMemo(() => nanoid(6),[projectsState]);
	const contentContainerId = useMemo(() => nanoid(6),[projectsState]);

	// <ListDocuments key={projectsState.activeProject?._id} changeTableRowsLength={changeTableRowsLength} projectId={projectsState.activeProject!._id}/>
	// <NewList/>
	if (!userState || !userState.data){
		return null
	}
	if (projectsState.loading === LoadingStatus.pending){
		return <FullscreenLoader visible={true}/>

	}else if (projectsState.loading === LoadingStatus.rejected){
		return (<AlternativeContent type={AlternativeContentType.DataError}/>)

	}else{

		if (!hasMinimumAppRole(userState.data.appRole, APP_ROLE.USER)){
			return (<AlternativeContent type={AlternativeContentType.NoLicense}/>)

		}

		if (!(projectsState.activeProject)){
			return <AlternativeContent type={AlternativeContentType.NoProjects}/>
		}
	}

	return (
		<>
			<Header key={headerId} tableRowsLength={tableRowsLength}/>
			<div key={contentContainerId} className={classes.content}>
				<ListDocuments key={projectsState.activeProject?._id} changeTableRowsLength={changeTableRowsLength} projectId={projectsState.activeProject!._id}/>

			</div>
		</>
	)
}

const Header = ({tableRowsLength} : {tableRowsLength: number | null}) => {
	const {classes} = createStyles(theme => ({

		searchInput: {
			root: {
				backgroundColor:"white"
			}
		},
		header: {
			padding: DEFAULT_STYLES.defaultPadding,
			backgroundColor:"white",
			marginTop: 22,
			marginBottom: 22,
		},
		resultHeader: {
			color: DEFAULT_STYLES.headerColor,
			marginTop: 1,
			marginBottom: 0,
		}
	}))()
	const dispatch = useAppDispatch()
	const docNumFilter = useSelector(docNumFilterSelector)
	const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false)
	const [isMoreTooltipOpen, setIsMoreTooltipOpen] = useState(false);
	const projectsState = (useSelector(projectsSelector))

	return (

		<div>

			<div className={classes.header}>

				<div style={{
					marginBottom: 8 // 16
				}}>
					<MainHeader>Dokumente</MainHeader>
					{tableRowsLength !== null ? (<Text weight={500} style={{display: "none"}} className={classes.resultHeader}> { Intl.NumberFormat('de-DE').format(tableRowsLength)} Ergebnisse verfügbar</Text>) : (<h3 style={{display: "none"}} className={classes.resultHeader}></h3>) }

				</div>

				<div style={{
					display: "flex"
				}}>

					<div>
						<TextInput

							onChange={(event) => {
								dispatch(changeSearchValue(event.currentTarget.value))
							}}


							styles={
							{
								input: {

									borderStyle: "solid",
									borderWidth: 1,
									backgroundColor: (docNumFilter.searchValue.length > 0) ? DEFAULT_STYLES.activationColor : undefined,
									borderColor: "#e5e5e5",
									'&:focus': {
										backgroundColor: (docNumFilter.searchValue.length > 0) ? "#e6eaf8 !important;" : undefined,
									},
									'&:hover': {
										backgroundColor:"#eaebec"
									}
								},
								root: {
									width: 500,
									marginRight: 30,
								}
							}}  icon={<IconSearch size={20} color="#636363"/>} className={classes.searchInput} variant="filled" placeholder="Suchen"/>
					</div>

					<div style={{
						marginLeft: "auto",
						display: "flex",
						gap: 10
					}}>
						{
							hasMinimumProjectRole(projectsState.activeProject?.projectRole, PROJECT_ROLE.USER) ? (<Tooltip
								label="Neues Dokument erstellen"
								withArrow
								offset={10}
								position="top"
							>
								<Button variant="filled" onClick={()=>{
									openModal(CreateDocumentModal());
								}}
								>Erstellen</Button>

							</Tooltip>) : <></>
						}

						<Tooltip
							label="Liste mit Attributen filtern"
							withArrow
							offset={10}
							position="top"
						>
						<Button onClick={()=>{
							openModal(FilterModal());
						}} leftIcon={ <img src={"./illustrations/main/documents/FilterIcon.svg"}/>}  variant="outline">Filtern</Button>
						</Tooltip>

							<Menu opened={isMoreMenuOpen} onOpen={() => setIsMoreMenuOpen(true)}
								  onClose={() => setIsMoreMenuOpen(false)}
								  position="bottom-end"
								  transition={DEFAULT_STYLES.transition} transitionDuration={DEFAULT_STYLES.transitionDuration}
								  withArrow
							>

								<Menu.Target>
									<Tooltip
										opened={isMoreTooltipOpen}
										label="Weitere Aktionen"
										openDelay={2000}
										offset={10}
										withArrow
									>

										<ActionIcon
											variant="default"
											onMouseLeave={() => setIsMoreTooltipOpen(false)}
											onMouseOver={() => {
												if (!isMoreMenuOpen) {
													setIsMoreTooltipOpen(true)
												} else {
													setIsMoreTooltipOpen(false)
												}}}
											onClick={() => {
											setIsMoreTooltipOpen(!isMoreTooltipOpen)
											setIsMoreMenuOpen(!isMoreMenuOpen)
										}} >

											<Dots size={22} color={DEFAULT_STYLES.defaultIconColor}/>
										</ActionIcon>


									</Tooltip>
								</Menu.Target>

								<Menu.Dropdown>
									<Menu.Label>Weitere Aktionen</Menu.Label>
									<Menu.Item key={"export-01"} icon={<img width={14} src={"./illustrations/main/documents/ExportIcon.svg"}/>}
									>
										{									<CsvDownload>Export</CsvDownload>
										}
									</Menu.Item>

								</Menu.Dropdown>
							</Menu>




					</div>
				</div>
				<FilterDisplay type={FilterDisplayType.DocNumFilter} filter={docNumFilter.filter}/>
			</div>
		</div>
	)
}



function CsvDownload({children}: { children?: any }) {

	const docNumListData = useSelector(docNumListSelector)
	const headers = [
		{ label: "Dokument", key: "name" },
		{ label: "Ersteller", key: "creatorName" },
		{ label: "Datum", key: "date" },
		{ label: "Aktiv", key: "activeState" },
	];

	return (
		<CSVLink
			filename={"export-" + new Date().toISOString() +".csv"}
			headers={headers}
			separator={";"}
			target="_blank"
			style={{
				color: "black",
				textDecoration: "none"
			}} data={docNumListData.rowData}
			onClick={() => {

				if (docNumListData.rowData.length > 0){
					return true;
				}else{
					openConfirmModal({
						cancelProps: { style: {display:"none"} },
						onConfirm() {
							closeAllModals()
						},
						title: <ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.main.global.docNum.export.noDocumentsTitle} message={UI_TEXTS.main.global.docNum.export.noDocumentsMessage} />
					})
					return false; // stopping the handling of component
				}

			}}
		>{children}</CSVLink>
	);
}


export default Documents