import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_ENDPOINTS, axiosInstance} from "../../../apiConfigs";
import {DocNum} from "./create/createDocNumThunk";
import {AttributeValue} from "./create/getAttributeValueThunk";
import {Attribute} from "./create/getDocKeyDataThunk";

export interface AttributeValueLink{
    _id: string;
    attributeValueId: string; //foreignKey of an AttributeValue
    docNumId: string;
    isActive: boolean;
    timestamp: Date; //ISODate in MongoDb
}

export interface ExtendedAttributeValueLink extends AttributeValueLink {
    attributeValue: AttributeValue,
    attribute: Attribute
}
export interface GetDocNumDataResponse {
    docNum : DocNum,
    creatorName: string,
    extendedAttributeValueLinks: ExtendedAttributeValueLink[],
    projectName: string
}

export const getDocNumDataThunk = createAsyncThunk(
    'getDocNumDataThunk/start',
    async (docNumId: string, thunkAPI) => {
        const res = await axiosInstance.get(API_ENDPOINTS.private.docNum.getAllData + "?id=" + docNumId)
        return res.data as GetDocNumDataResponse
    }
)
