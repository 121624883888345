import {Button, createStyles} from '@mantine/core';
import {closeModal, openConfirmModal} from '@mantine/modals';
import {DEFAULT_STYLES} from "../themeStyles";
import {nanoid} from "nanoid";
import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch} from "../../../redux/hooks";
import {getErrorText, IErrorText, isAbortError} from "../../../texts/apiErrors";

const Icons = {
    SuccessIcon : (
        <svg width="44" height="44" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.3884 0.198242C10.9444 0.198242 0.0458984 11.0968 0.0458984 24.5408C0.0458984 37.9848 10.9444 48.8833 24.3884 48.8833C37.8325 48.8833 48.731 37.9848 48.731 24.5408C48.731 11.0968 37.8325 0.198242 24.3884 0.198242Z" fill="#6FA965"/>
            <path d="M14.4609 26.0951L20.2109 31.8451L33.6276 18.4285" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    ErrorIcon: (
        <svg width="44" height="44" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.5217 0.0217285C10.9908 0.0217285 0.0217285 10.9908 0.0217285 24.5217C0.0217285 38.0527 10.9908 49.0217 24.5217 49.0217C38.0527 49.0217 49.0217 38.0527 49.0217 24.5217C49.0217 10.9908 38.0527 0.0217285 24.5217 0.0217285ZM20.7865 17.5382C19.8895 16.6412 18.4352 16.6412 17.5382 17.5382C16.6412 18.4352 16.6412 19.8895 17.5382 20.7865L21.2735 24.5217L17.5382 28.257C16.6412 29.154 16.6412 30.6083 17.5382 31.5052C18.4352 32.4022 19.8895 32.4022 20.7865 31.5052L24.5217 27.77L28.257 31.5052C29.154 32.4022 30.6083 32.4022 31.5052 31.5052C32.4022 30.6083 32.4022 29.154 31.5052 28.257L27.77 24.5217L31.5052 20.7865C32.4022 19.8895 32.4022 18.4352 31.5052 17.5382C30.6083 16.6412 29.154 16.6412 28.257 17.5382L24.5217 21.2735L20.7865 17.5382Z" fill="#D75249"/>
        </svg>
    ),
    QuestionIcon: (<svg width="44" height="44" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 24C48 17.6347 45.4714 11.5304 40.9704 7.02955C36.4698 2.52855 30.3651 0 24 0C17.6349 0 11.5304 2.52855 7.02955 7.02955C2.52855 11.5302 0 17.6349 0 24C0 30.3651 2.52855 36.4696 7.02955 40.9704C11.5302 45.4714 17.6349 48 24 48C28.2127 48 32.3514 46.891 35.9999 44.7847C39.6484 42.678 42.6779 39.6486 44.7847 35.9999C46.891 32.3514 48 28.2127 48 24ZM20.4911 18.6624C20.4184 18.9624 20.3672 19.2674 20.3377 19.5744C20.3461 19.6454 20.3193 19.7161 20.2654 19.7633C20.2115 19.8101 20.1378 19.8276 20.0688 19.8098H18.3168H16.5168C16.4063 19.8098 16.3249 19.8098 16.3296 19.6561C16.3403 18.4357 16.5951 17.2297 17.0783 16.109C17.7322 14.7098 18.8572 13.5852 20.256 12.9313C22.5237 11.8344 25.1397 11.7062 27.5039 12.576C29.1887 13.1211 30.5434 14.3878 31.1999 16.032C31.5984 17.071 31.74 18.1906 31.6128 19.2962C31.4896 20.6546 30.8631 21.9182 29.856 22.8385C29.0879 23.5825 28.2622 24.2786 27.456 24.9747C26.8245 25.4461 26.328 26.0749 26.0159 26.7984C25.8231 27.3666 25.7166 27.9603 25.6992 28.5599C25.6992 28.7712 25.6657 28.9872 25.656 29.1984C25.6627 29.242 25.648 29.2865 25.6168 29.3176C25.5854 29.3488 25.5412 29.3635 25.4976 29.3568H22.3343C22.2944 29.3675 22.2516 29.3568 22.2214 29.3287C22.191 29.3002 22.1776 29.2584 22.1856 29.2175C22.1699 28.1782 22.276 27.1409 22.5024 26.1265C22.8151 25.0721 23.4211 24.1284 24.2495 23.4048C24.96 22.7472 25.6895 22.0849 26.3421 21.3888C26.9146 20.8139 27.304 20.0823 27.4607 19.2865C27.7199 17.9379 27.216 16.9394 25.9246 16.469C24.6791 15.9902 23.2922 16.0404 22.0848 16.6083C21.2595 17.0037 20.6692 17.7647 20.4911 18.6624ZM21.8447 33.7728C21.8538 33.2127 22.0848 32.6789 22.4872 32.2892C22.8894 31.8995 23.4301 31.6855 23.9902 31.6946C24.5504 31.7033 25.0841 31.9343 25.4739 32.3367C25.8636 32.7392 26.0775 33.2799 26.0688 33.8401C26.0568 34.5944 25.6433 35.2855 24.9837 35.6521C24.3241 36.0191 23.5192 36.0064 22.8716 35.6187C22.2241 35.231 21.8326 34.5275 21.8447 33.7728Z" fill="#CCCCD3"/>
        </svg>
    )
}
 export enum ConfirmType {
    Success = "Success",
    Error = "Error",
    Question = "Question",
     None = "None"
}

export function ConfirmModalHeader({type, title, message}: {type: ConfirmType, title: string, message: string | React.ReactNode}) {
    return (
        <>
            <Header type={type}>{title}</Header>
            <div style={{marginBottom: 22}}>
                <ConfirmModalSubHeading>{message}</ConfirmModalSubHeading>
            </div>
        </>
    );
}
function Header({children, type}: {type: ConfirmType, children: any}) {

    const {classes} = createStyles((theme, _params) => ({
        heading: {
            color: DEFAULT_STYLES.headerColor,
            fontSize: 27,
            marginBottom: 8,
            fontStyle: "bold"
        },
        group: {
            display: "flex",
            alignItems: "center",
            gap: 16,
            marginTop: 14,
            marginBottom: 8
        },
        subHeading: {
            color: "#6B6D84",
            fontWeight: 500,
            fontSize: theme.fontSizes.sm,
            margin: 0
        },
        icon: {},

    }))()

    let icon = null
    switch (type){
        case ConfirmType.Error:
            icon = Icons.ErrorIcon
            break
        case ConfirmType.Success:
            icon = Icons.SuccessIcon
            break
        case ConfirmType.Question:
            icon = Icons.QuestionIcon
            break
        default:
            icon = null
    }
    return (
        <div className={classes.group}>

            { icon !== null ? <div className={classes.icon}>
                {icon}
            </div> : <></>}

            <h2 className={classes.heading}>{children}</h2>
        </div>
    );
}
export function ConfirmModalSubHeading({children}: {children: any}) {

    const {classes} = (createStyles((theme) => ({

        subHeader: {
            color: "#696b82",
            fontWeight: 500,
            fontSize: theme.fontSizes.sm,
            margin: 0
        }
    })))()

    return (
        <p className={classes.subHeader}>{children}</p>
    );
}


// Button children for confirmModal
/*
  // You must add the following group property to the openConfirm Modal function
  groupProps: {
                   style: {
                      display: "none"
                   }
  },
 */
export function ThunkButtons({data,thunk, responseTexts, modalId, labels, callback, errorMessages, successCallback} : { data: any, successCallback?: Function, callback?: Function, thunk: any, labels? : {cancel?: string, confirm?: string} ,responseTexts: {
        success: { title: string, message: string | React.ReactNode, buttonContinue: string },
        error: { title?: string, message?: string, buttonContinue: string },
    }, modalId: string, errorMessages?: IErrorText[] }) {

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch()
    const requestPromise = useRef<any>(null);
    const canceled = useRef<any>(null);


    useEffect(() => {

        requestPromise.current = null

        return () => {

            if (requestPromise.current !== null && typeof requestPromise.current.abort === 'function'){
                // abort promise
                requestPromise.current.abort()
            }

        };
    }, [canceled]);

    return (
        <div style={{marginBottom: -16, paddingTop: 6, width: "100%", display: "flex"}}>

            <div style={{marginLeft: "auto", gap: 16, display: "flex"}}>
                <Button disabled={isLoading}  onClick={()=> {
                    closeModal(modalId)
                }} variant="default">{(labels && labels.cancel) ? labels.cancel : "Abbrechen"}</Button>

                <Button loading={isLoading} onClick={()=> {
                    setIsLoading(true)

                    const request = dispatch(thunk(data))
                    requestPromise.current = request
                    request.then((action: any)=> {


                        const requestResultModalId = nanoid(4)
                        setIsLoading(false)
                        if (thunk.fulfilled.match(action)){

                            // open success message
                            openConfirmModal({
                                modalId: requestResultModalId,
                                cancelProps: { style: {display:"none"} },
                                onConfirm() {
                                    closeModal(modalId)
                                },
                                labels: {cancel: "", confirm: responseTexts.success.buttonContinue},
                                // @ts-ignore
                                title: <ConfirmModalHeader type={ConfirmType.Success} title={responseTexts.success.title} message={responseTexts.success.message} />
                            })
                            if (successCallback) successCallback()
                            if (callback) callback()
                            return;

                        }else{

                            // check if aborted
                            if (isAbortError(action.error)){
                                return
                            }
                        }

                            // extracting error message and title
                            let errorText = undefined
                            if (errorMessages){
                                errorText = getErrorText(errorMessages, String(action.error.code), true)
                            }
                            if (errorText){
                                openConfirmModal({
                                    modalId: requestResultModalId,
                                    cancelProps: { style: {display:"none"} },
                                    onConfirm() {
                                        closeModal(modalId)
                                    },
                                    labels: {cancel: "", confirm: responseTexts.error.buttonContinue},
                                    title: <ConfirmModalHeader type={ConfirmType.Error}  title={errorText.title} message={errorText.message} />
                                })

                            // no errorText found, try to return responseText
                            }else if (responseTexts.error.title && responseTexts.error.message){
                                // showing the default error codes
                                openConfirmModal({
                                    modalId: requestResultModalId,
                                    cancelProps: { style: {display:"none"} },
                                    onConfirm() {
                                        closeModal(modalId)
                                    },
                                    labels: {cancel: "", confirm: responseTexts.error.buttonContinue},
                                    title: <ConfirmModalHeader type={ConfirmType.Error}  title={responseTexts.error.title} message={responseTexts.error.message} />
                                })

                            }else {
                                throw new Error ("ThunkButtons ConfirmModal requires either errorMessages or a responseText object")
                            }

                        if (callback) callback()
                        return;

                    })

                }}>{(labels && labels.confirm) ? labels.confirm : "Weiter"}</Button>
            </div>

        </div>
    );
}


