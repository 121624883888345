import React from 'react';
import {Button} from "@mantine/core";
import {DEFAULT_STYLES} from "../themeStyles";

export function ConfirmButton({children, onClick}: {children: any, onClick: ()=>void}) {
    return (
        <Button onClick={onClick} styles={{
            root: {
                backgroundColor:  DEFAULT_STYLES.confirmColor,
                '&:hover': {
                    backgroundColor: "#2f6d23",
                }
            }
        }} color="red">{children ? children : "Bestätigen"}</Button>
    );
}

