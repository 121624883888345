import {AuthContainer} from "../../common/auth/AuthContainer";
import {Button, createStyles, PasswordInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {Lock} from 'tabler-icons-react';
import {AuthNavigation} from "../../components/auth/AuthNavigation";
import {openConfirmModal, openModal} from '@mantine/modals';
import {INPUT_ICON_SIZE} from "../../common/global/themeStyles";
import {useNavigate, useSearchParams} from "react-router-dom";
import HelpButton, {handleHelpClick} from "../../common/auth/HelpButton";
import {useModalCleanup} from "../../hooks/useModalCleanup";
import {UI_TEXTS} from "../../texts/ui";
import {useAppDispatch} from "../../redux/hooks";
import {isPasswordValid} from "./Register";
import {ERROR_TEXTS, getErrorText} from "../../texts/apiErrors";
import {newPasswordThunk} from "../../redux/asyncThunks/auth/resetPasswordThunks";
import {LoadingStatus} from "../../redux/util";
import {ConfirmModalHeader, ConfirmType} from "../../common/global/modals/ConfirmModal";

export const NewPassword = () => {
	return (
		<div>
			<AuthNavigation/>
			<AuthContainer showReturnButton={true} title={"Neues Passwort"} subTitle={"Bitte setze nun ein neues, sicheres Passwort für dein Docul Konto."} >
				<ContainerContent/>
			</AuthContainer>
		</div>
	)
}

const useStyles = createStyles((theme, _params) => ({

	inputSection: {
		width: "100%",
		marginBottom: 32,
		display: "flex",
		flexDirection: "column",
		gap: 25
	},

	buttonGroup: {
        width: "100%",
        display: "flex",
        marginBottom: 150
	},


	buttonGroup_rightSection: {
		marginLeft: "auto"
	},

	buttonGroup_footer: {
		width: "100%",
		display: "flex",
		justifySelf: "flex-end"
	},

	buttonGroup_footer_right: {
		marginLeft: "auto",
		marginRight:-12
	},
	buttonGroup_footer_left: {
		marginLeft:-13
	}




}));
function ContainerContent() {

	const {classes} = useStyles()
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const navigate = useNavigate();

	const dispatch = useAppDispatch()

	const [searchParams] = useSearchParams();
	const userId = searchParams.get("userId")
	const verificationCode = searchParams.get("verificationCode")

	useModalCleanup()
	const [requestState, setRequestState] = useState(LoadingStatus.idle);


	//todo on error, offer a retry button
	useEffect(() => {

		//checking if userId & verificationCode query params were provided
		if(userId === null || verificationCode === null){

			openConfirmModal({
				cancelProps: { style: {display:"none"} },
				closeOnClickOutside: false,
				onConfirm: () =>{
					navigate("/auth/resetPassword");
				},
				labels: {confirm: UI_TEXTS.auth.newPassword.toResetPassword, cancel: "" },
				title: <ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.auth.newPassword.modalWrongParamsTitle} message={UI_TEXTS.auth.newPassword.modalWrongParamsDescription} />
			})

			return
		}

	},[]);

	return (
		<div>
			<div className={classes.inputSection}>
				<PasswordInput error={passwordError} value={password} onChange={(event) => setPassword(event.currentTarget.value)} required label="Password" placeholder="Neues Passwort eingeben" icon={<Lock size={INPUT_ICON_SIZE} />}/>
			</div>

			<div className={classes.buttonGroup}>
				<div className={classes.buttonGroup_rightSection}>
					<Button loading={requestState === LoadingStatus.pending} variant="filled"  onClick={() => {

						setPasswordError("")

						//checking password
						if (!isPasswordValid(password)){
							setPasswordError(UI_TEXTS.auth.newPassword.invalidPassword)
							return
						}

						const promise = dispatch(newPasswordThunk({
							userId: userId as string,
							verificationCode: verificationCode as string,
							newPassword: password
						}))

						setRequestState(LoadingStatus.pending)
						//a failed request or error in a thunk will never return a rejected promise
						promise.then((action) => {


							if (newPasswordThunk.fulfilled.match(action)){

								openConfirmModal({
									cancelProps: { style: {display:"none"} },
									closeOnClickOutside: false,
									onConfirm: () =>{
										navigate("/auth/login");
									},
									labels: {confirm: UI_TEXTS.auth.newPassword.toLoginButton, cancel: "" },
									title: <ConfirmModalHeader type={ConfirmType.Success} title={UI_TEXTS.auth.newPassword.modalSuccessTitle} message={UI_TEXTS.auth.newPassword.modalSuccessDescription} />
								})


							}else{
								const errorText = getErrorText(ERROR_TEXTS.public.auth.newPassword, String(action.error.code))!

								openConfirmModal({
									cancelProps: { style: {display:"none"} },
									title: <ConfirmModalHeader type={ConfirmType.Error} title={errorText.title} message={errorText.message} />
								})
							}

						}).finally(()=>{
							setRequestState(LoadingStatus.idle)
						} )


					}
					}>Weiter</Button>
				</div>

			</div>

			<div className={classes.buttonGroup_footer}>

				<div className={classes.buttonGroup_footer_left}>

				</div>

				<div className={classes.buttonGroup_footer_right}>
					<HelpButton/>

				</div>

			</div>

		</div>
	);
}


export default NewPassword

