
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthNavigation} from "../../components/auth/AuthNavigation";
import {closeAllModals, openConfirmModal, openModal} from "@mantine/modals";
import {useModalCleanup} from "../../hooks/useModalCleanup";
import {useAppDispatch} from "../../redux/hooks";
import {UI_TEXTS} from "../../texts/ui";
import {ERROR_TEXTS, getErrorText} from "../../texts/apiErrors";
import {verifyNewUser} from "../../redux/asyncThunks/auth/registerThunks";
import {FullscreenLoader} from "../../components/main/FullscreenLoader";
import {ConfirmModalHeader, ConfirmType} from "../../common/global/modals/ConfirmModal";

export const VerifyUser = () => {

    const navigate = useNavigate();
    useModalCleanup()

    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams();
    const userId = searchParams.get("userId")
    const verificationCode = searchParams.get("verificationCode")

    const [showLoader, setShowLoader] = useState(true);
    useModalCleanup()

    useEffect(() => {

        //checking if userId & verificationCode query params were provided
        if(userId === null || verificationCode === null){

            openConfirmModal({
                cancelProps: { style: {display:"none"} },
                closeOnClickOutside: false,
                onConfirm: () =>{
                    navigate("/auth/login");
                },
                labels: {confirm: UI_TEXTS.auth.verifyUser.toLoginButton, cancel: "" },
                title: <ConfirmModalHeader type={ConfirmType.Success} title={UI_TEXTS.auth.verifyUser.modalWrongParamsTitle} message={UI_TEXTS.auth.verifyUser.modalWrongParamsDescription} />
            })

            setShowLoader(false)
            return
        }

        //performing request
        const promise = dispatch(verifyNewUser({verificationCode, userId}))

        promise.then((action) => {

            if (verifyNewUser.fulfilled.match(action)){

                openConfirmModal({
                    cancelProps: { style: {display:"none"} },
                    closeOnClickOutside: false,
                    onConfirm: () =>{
                        navigate("/auth/login");
                    },
                    labels: {confirm: UI_TEXTS.auth.verifyUser.toLoginButton, cancel: "" },
                    title: <ConfirmModalHeader type={ConfirmType.Success} title={UI_TEXTS.auth.verifyUser.modalSuccessTitle} message={UI_TEXTS.auth.verifyUser.modalSuccessDescription} />
                })

            }else{
                const errorText = getErrorText(ERROR_TEXTS.public.auth.verifyUser, String(action.error.code))!

                openConfirmModal({
                    cancelProps: { style: {display:"none"} },
                    closeOnClickOutside: false,
                    onConfirm: () =>{
                        navigate("/auth/login");
                    },
                    labels: {confirm: UI_TEXTS.auth.verifyUser.toLoginButton, cancel: "" },
                    title: <ConfirmModalHeader type={ConfirmType.Error} title={errorText.title} message={errorText.message} />
                })

            }
        }).finally(()=>{
            setShowLoader(false)
        })
    },[]);


    return (
        <div>
            <FullscreenLoader visible={showLoader}/>
            <AuthNavigation hideRightSection/>
        </div>
    );
}



export default VerifyUser

