import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_ENDPOINTS, axiosInstance} from "../../../../apiConfigs";
import {DocKeyPartValue} from "../../../../slices/main/documents/create/createDocNumDataSlice";

export interface DocNum {

	_id: string;
	createdBy: string; //userId that created this docNum
	value: string; //the full documentation number
	isActive: boolean; //indicates whether the document is active or not.
	projectId: string; //foreignKey of project
	mainVersion: number; //when docKey has no mainVersion, it will be 0
	minorVersion: number; //when docKey has no minorVersion, it will be 0
	consecutiveNumber: number;
	timestamp: Date; //important for bulkAdds - ISO STRING

}

export interface SimulateDocNumCreationResponse{
	docNum: DocNum,
	associatedAttributeValues: string[] //ids of all associated attributes
}

export interface SimulateDocNumCreationRequest {
	projectId: string,
	docKeyPartValues: DocKeyPartValue[]
}

export const simulateCreateDocNumThunk = createAsyncThunk(
	'simulateCreateDocNumThunk/start',
	async (data: SimulateDocNumCreationRequest) => {
		const res = await axiosInstance.post(API_ENDPOINTS.private.docNum.simulateCreation, data)
		return res.data as SimulateDocNumCreationResponse
	}
)

export const createDocNumThunk = createAsyncThunk(
	'createDocNumThunk/start',
	async (data: SimulateDocNumCreationRequest) => {
		const res = await axiosInstance.post(API_ENDPOINTS.private.docNum.create, data)
		return res.data as SimulateDocNumCreationResponse
	}
)