import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_ENDPOINTS, axiosInstance} from "../../../apiConfigs";
import {DocNum} from "./create/createDocNumThunk";
import {AttributeValue} from "./create/getAttributeValueThunk";
import {Filter} from "../../../slices/main/documents/get/filterDocNumSlice";



export interface GetDocNumRequest { //will be used to check if
    projectId: string,
    filter: Filter,
}

export interface DocNumQuery extends DocNum{
    creator: {
        userId: string,
        firstName: string,
        lastName: string,
        email: string,
        organisationId: string
    },
    attributeValues: AttributeValue[]
}

export interface GetDocNumResponse {
    projectId: string,
    data: DocNumQuery[],
    filter: Filter,
    timestamp: string, //time of completion
    queryDuration: string //how long did the request take to be completed - todo add to log
}


export const getDocNumThunk = createAsyncThunk(
    'getDocNumThunk/start',
    async (data: GetDocNumRequest, thunkAPI) => {
        const res = await axiosInstance.post(API_ENDPOINTS.private.docNum.get, data)
        return res.data as GetDocNumResponse
    }
)
