import {createStyles, Text, Button} from '@mantine/core'
import React, {Fragment, PropsWithChildren, useEffect, useState} from 'react'
import {Outlet, useMatch, useNavigate} from 'react-router-dom'
import {Sidebar} from '../../components/main/Sidebar'
import AppNavigationBar from "../../components/main/AppNavigationBar";
import {DEFAULT_STYLES} from "../../common/global/themeStyles";
import {Footer} from "../../components/main/Footer";
import {projectsSelector, userSelector} from "../../redux/rootReducer";
import {useSelector} from "react-redux";
import {FullscreenLoader} from "../../components/main/FullscreenLoader";
import {LoadingStatus} from "../../redux/util";
import {Reload} from "tabler-icons-react";

export enum AlternativeContentType {
	NoLicense="NoLicense",
	NoProjects="NoProjects",
	DataError="DataError"
}

export const MainRoot = () => {

	const {classes} = createStyles(theme => ({
		layout: {
			marginLeft: "auto",
			marginRight: "auto",
			display: 'flex',
			minHeight: "100vh",
			height:"100vh",
			maxHeight: "100vh",
			boxSizing: 'border-box',
			overflowY: "hidden",
		},

		contentContainer: {
			width: '100%',
			height:"100vh",
			minHeight:"100vh",
			maxHeight:"100vh",
			overflowY: "scroll",
			boxSizing: 'border-box'
		}

	}))()
	const navigate = useNavigate();
	const isMainRoute = useMatch("/");
	const userState = (useSelector(userSelector))
	const [isReady, setIsReady] = useState(false);
	const projectsState = useSelector(projectsSelector)

	useEffect(() => {

		if (!userState.data){
			navigate("/auth")
		}

	}, [userState]);

	//NOTE: reroute empty path
	useEffect(() => {

		if (!userState.data){
			navigate("/auth")
			return
		}
		if (isMainRoute){
			navigate("/documents") // auth also navigates to /document after successful login
			return;
		}
		setIsReady(true)
	}, [isMainRoute]);

	//todo display no projects


	return (

		<div>

			<FullscreenLoader visible={!isReady}/>

			{(isReady && (userState && (userState.data !== null)) )? (<div className={classes.layout}>

					<Sidebar />


					<div className={classes.contentContainer}>


						<AppNavigationBar key={userState.data.id}/>


						<Content>
							<Outlet />
						</Content>


						{(projectsState.loading === LoadingStatus.pending) ? null : <Footer /> }

					</div>
				</div>
			) : null}
		</div>

	)
}

export function AlternativeContent({type}: { type: AlternativeContentType }){
	const {classes} = createStyles(theme => ({
		container: {
			backgroundColor: DEFAULT_STYLES.defaultBackgroundColor,
			paddingTop: 60,
			paddingLeft: 50,
			paddingRight: 50,
			borderRadius: 16,
			alignItems: "center",
			flexFlow: "column",
			gap: 2,
			display: 'flex',
			paddingBottom: 75,
		}
	}))()

	let image = <></>
	let titel = "Unbekannter Error"
	let description ="Es entstand ein unbekannter Error."

	switch (type){

		case AlternativeContentType.NoLicense:
			image = <img src='./illustrations/main/documents/NoLicenseIcon.svg' width={98}/>
			titel = "Keine Benutzerlizenz"
			description = "Du hast noch keine Benutzerlizenz. Bitte wende dich an deinem Projekt Administrator."
			break
		case AlternativeContentType.NoProjects:
			image = <img src='./illustrations/main/documents/NoProjectsIcon.svg' width={95}/>
			titel = "Keine Projekte zugeteilt"
			description = "Dir wurden bis jetzt keine Projekte zugeteilt. Bitte wende dich an deinem Projekt Administrator."
			break
		case AlternativeContentType.DataError:
			titel = "Fehler beim Laden der Benutzerdaten."
			description = "Die Benutzerdaten konnten nicht geladen werden. Bitte lade die Seite neu und probiere es erneut."
			image = <img src='./illustrations/main/documents/DataErrorIcon.svg' width={90}/>

			break
		default:
			image = <img src='./illustrations/main/documents/DataErrorIcon.svg' width={90}/>
	}
	return (
		<div style={{height:"100%"}}>
		<div style={{
			padding: DEFAULT_STYLES.defaultPadding,
			display:"flex",
			alignItems: "center",
			flexDirection: "column",
		}}>

			<div className={classes.container}>
				<div style={{
					margin: 20
				}}>
					{image}
				</div>

				<Text align="center" size={25} weight={600}>
					{titel}
				</Text>
				<Text align="center" size="sm" weight={400}>
					{description}
				</Text>

				{
					type === AlternativeContentType.DataError ? <Button onClick={()=> {
						window.location.reload();
					}
					} style={{marginTop: 40}} leftIcon={<Reload size={23} strokeWidth={2.5}/>}>Erneut versuchen</Button> : <></>

				}
				</div>



			</div>
		</div>
	);
}


const Content = (props: PropsWithChildren) => {
	return (
		<Fragment>
			{props.children}

		</Fragment>
	)
}