import {createSlice} from "@reduxjs/toolkit"


export interface ISidebarState{
    open: boolean
}

export const initialSidebarState: ISidebarState = {
    //todo Check if screen is big enough
    open: false
}

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: initialSidebarState,
    reducers: {
        setSlidebar: (state, action) => {
            state.open = action.payload
        }
    }
})

export default sidebarSlice.reducer
export const {setSlidebar} = sidebarSlice.actions
