import axios, {AxiosError} from "axios";
import {SerializedError} from "@reduxjs/toolkit";
import {ERROR_TEXTS} from "../texts/apiErrors";
import {resetState} from "./rootReducer";
import {StoreType} from "./store";
import {openConfirmModal} from "@mantine/modals";
import {ConfirmModalHeader, ConfirmType} from "../common/global/modals/ConfirmModal";
import React from "react";
import {runtimeIsProduction} from "../util/global";

const getBaseUrl = ()=>{

	const baseRoute = "/api"
	if (runtimeIsProduction){
		const baseProtocol = "https://"
		const productionHostName = "backend.proquinn.com"
		return `${baseProtocol}${productionHostName}${baseRoute}`
	}else{
		const baseProtocol = "http://"
		const localHostName = "localhost:8081"
		return `${baseProtocol}${localHostName}${baseRoute}`
	}
}
export const axiosInstance = axios.create({
	baseURL: getBaseUrl() ,
	timeout: 10000,
	headers: {'X-Custom-Header': 'foobar'},
	withCredentials: true
});

axiosInstance.interceptors.request.use((config: any) => {
	return config
})

let globalStore: StoreType
export const injectStoreToAPI =( _store: StoreType )=> {
	globalStore = _store
}

export class ApiError extends Error implements SerializedError{

	message: string;
	code: string;
	stack: string;

	constructor(message: string, code: string, stack: string) {
		super(message);
		this.message = message;
		this.code = String(code)
		this.stack = stack
	}

}
// handling errors and unauthenticated users
axiosInstance.interceptors.response.use((response) => response, (error: AxiosError) => {

	let message: string = "Request failed. Unknown reason."
	let code: string = "400"

	let parsedMessage = error.response?.data
	if (parsedMessage && typeof parsedMessage === "string"){
		message = parsedMessage
	}

	let parsedStatus = error.response?.status
	if (parsedStatus && typeof parsedStatus === "number"){ //well, status is string in SerializedError
		code = String(parsedStatus)
	}

	if (code === ERROR_TEXTS.global.unauthenticated.code){
		globalStore.dispatch(resetState())

		openConfirmModal({
			cancelProps: { style: {display:"none"} },
			title: <ConfirmModalHeader type={ConfirmType.Error} title={ERROR_TEXTS.global.unauthenticated.title} message={ERROR_TEXTS.global.unauthenticated.message} />
		})

		//NOTE: this is the promise returned from createAsyncThunk dispatch
		return new Promise(()=>{console.log("User is unauthenticated.")})
	}

	throw new ApiError(message, code,  "Error via:" + error.request.responseURL)
});

const PUBLIC_ROUTES = {
	root:  "/public",
	auth : "/auth"
}

const PRIVATE_ROUTES = {
	root:  "",
	projects: "/projects",
	docKey: "/docKey",
	docNum: "/docNum",
	attributeValue: "/attributeValue",

}
export const API_ENDPOINTS = (() => {
	const publicEndpoints = {
		auth : (()=> {
			const route = PUBLIC_ROUTES.root.concat(PUBLIC_ROUTES.auth)
			return {
				login: route + "/login",
				logout: route + "/logout",
				verifyUser: route + "/verifyUser",
				register: route + "/register",
				resetPassword: route + "/resetPassword",
				newPassword: route + "/verifyResetPassword",
			}
		})()
	}
	const privateRoutes = {
		projects : (()=> {
			const route = PRIVATE_ROUTES.root.concat(PRIVATE_ROUTES.projects)
			return {
				getAll: route + "/getAll",
			}
		})(),
		docKey : (()=> {
			const route = PRIVATE_ROUTES.root.concat(PRIVATE_ROUTES.docKey)
			return {
				getAll: route + "/getData",
			}
		})(),
		attributeValue : (()=> {
			const route = PRIVATE_ROUTES.root.concat(PRIVATE_ROUTES.attributeValue)
			return {
				getAll: route + "/getData",
				create: route + "/create",
				deactivate: route + "/deactivate",
			}
		})(),
		docNum : (()=> {
			const route = PRIVATE_ROUTES.root.concat(PRIVATE_ROUTES.docNum)
			return {
				simulateCreation: route + "/simulateCreation",
				create: route + "/create",
				get: route + "/get",
				getAllData: route + "/getAllData", //returns data for a single DocNum,
				deactivate: route + "/deactivate"
	}
})()
	}

	return {
		public: publicEndpoints,
		private: privateRoutes
	}
})()