import React from 'react';
import {createStyles, Text} from "@mantine/core";
import {DEFAULT_STYLES} from "./themeStyles";

function Description({children, key} : {children: any, key?: any}) {

    const {classes} = createStyles((theme, _params) => ({

        description: { // description in the custom item

            fontSize: theme.fontSizes.xs,
            color: DEFAULT_STYLES.descriptionColor

        }

    }))()

    if (key){
        return (
            <Text key={key} className={classes.description} >{children}</Text>
        );
    }
    return (
        <Text className={classes.description} >{children}</Text>
    );

}

export default Description;