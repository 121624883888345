import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {LoadingStatus} from "../../util";
import {API_ENDPOINTS, axiosInstance} from "../../apiConfigs";

export enum APP_ROLE {
	ADMIN = 1,
	USER = 2, // initial state after successful registration
	VERIFIED = 3, // can already use private APIs & is verified
	BLOCKED = 4,
	UNVERIFIED = 0
}

export function hasMinimumAppRole(userAppRole: APP_ROLE, targetRole: APP_ROLE): boolean{

	switch (targetRole){

		case APP_ROLE.ADMIN:
			if (userAppRole === APP_ROLE.ADMIN){
				return true
			}
			break;

		case APP_ROLE.USER:
			if ((userAppRole === APP_ROLE.ADMIN) || (userAppRole === APP_ROLE.USER)){
				return true

			}
			break;

		case APP_ROLE.VERIFIED:
			if ((userAppRole === APP_ROLE.ADMIN) || (userAppRole === APP_ROLE.USER) || (userAppRole === APP_ROLE.VERIFIED)){
				return true

			}
			break

		case APP_ROLE.UNVERIFIED:
			if ((userAppRole === APP_ROLE.ADMIN) || (userAppRole === APP_ROLE.USER) || (userAppRole === APP_ROLE.VERIFIED) || (userAppRole === APP_ROLE.UNVERIFIED)){
				return true
			}
			break;

		default: // No app role specified, continue
			return true;

	}
	return false

}

interface User { //API_MODEL data is returned by the API
	id: string,
	firstName: string,
	lastName: string,
	email: string,
	appRole: APP_ROLE,
	organisationId: string | null
}

//TODO same interfaces
export interface UserResponse { //ILoginResponse, API_MODEL data is returned by the API
	id: string,
	firstName: string,
	lastName: string,
	email: string,
	appRole: APP_ROLE,
	organisationId: string | null
}

export interface IUserState {
	data: User | null, //when data is null, user is logged out
	loading: LoadingStatus
}

export const initialUserState: IUserState = {
	data:  null,
	loading: LoadingStatus.idle
}

export const loginUser = createAsyncThunk(
	'user/login',
	async (loginData: ILoginData, thunkAPI) => {
		const res = await axiosInstance.post<UserResponse>(API_ENDPOINTS.public.auth.login, loginData)
		return res.data
	}
)

export interface ILoginData {
	email: string, password: string
}
const userSlice = createSlice({
	name: 'user',
	initialState: initialUserState,
	reducers: {
		logoutUser: (state, action: PayloadAction<undefined>) => {
			state.data = null
			state.loading = LoadingStatus.idle
		},
		updateUserData: (state, action: PayloadAction<UserResponse>) => {
			state.data = action.payload
		}
	},
	extraReducers: (builder) => {

		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(loginUser.pending, (state, action) => {
			// Add user to the state array
			state.loading = LoadingStatus.pending
			state.data = null
		})

		builder.addCase(loginUser.rejected,
			(state, action) => {
				// Add user to the state array
				state.loading = LoadingStatus.rejected
				state.data = null
			})

		builder.addCase(loginUser.fulfilled,
			(state, action) => {
				// Add user to the state array
				state.loading = LoadingStatus.fulfilled
				state.data = action.payload
			})

	}
})


export default userSlice.reducer
export const {logoutUser,updateUserData} = userSlice.actions
