import React, {forwardRef, useEffect, useMemo, useState} from 'react';
import {
    ActionIcon,
    Alert,
    Button,
    createStyles,
    Divider,
    Loader,
    Menu,
    Select,
    SelectItem,
    Text,
    Textarea,
    Tooltip
} from '@mantine/core';
import {closeAllModals, openConfirmModal, openModal} from "@mantine/modals";

import {IconAlertCircle, IconPlus} from "@tabler/icons";
import {
    Attribute,
    AttributeConfig,
    AttributePart,
    AttributeSelectionValue,
    DocKeyData,
    DocKeyPart,
    getDocKeyData,
    MAXIMUM_DOCNUM_COMMENT_LENGTH,
    SelectionData
} from "../../../redux/asyncThunks/main/documents/create/getDocKeyDataThunk";
import {useAppDispatch} from "../../../redux/hooks";
import {useSelector} from "react-redux";
import {createDocNumDataSelector, projectsSelector} from "../../../redux/rootReducer";
import {UI_TEXTS} from "../../../texts/ui";
import {ERROR_TEXTS, isAbortError} from "../../../texts/apiErrors";
import {getAttributeValue} from "../../../redux/asyncThunks/main/documents/create/getAttributeValueThunk";
import {FullscreenLoader} from "../FullscreenLoader";
import {ConfirmModalHeader, ConfirmType, ThunkButtons} from "../../../common/global/modals/ConfirmModal";
import {DEFAULT_STYLES} from "../../../common/global/themeStyles";
import {nanoid} from "nanoid";
import {ModalHeader} from "../../../common/global/modals/ModalHeader";
import {SimulateCreationModal} from "./SimulateCreation";
import {
    changeCreateDocNumData,
    DocKeyPartValue,
    resetCreateDocNumData
} from "../../../redux/slices/main/documents/create/createDocNumDataSlice";
import {ModalSettings} from "@mantine/modals/lib/context";
import {getAppStore} from "../../../redux/store";
import {ModalLoader} from "../../../common/global/modals/ModalLoader";
import {
    deactivateAttributeValueThunk,
    IDeactivateAttributeValueThunk
} from "../../../redux/asyncThunks/main/documents/deactivateAttributeValueThunk";
import {Edit, Trash} from "tabler-icons-react";
import {CreateAttributeValueModal, ICreateAttributeValue} from "./CreateAttributeValue";
import {SectionFullHeader} from "../../../common/documents/createDocument/SectionFullHeader";
import {hasMinimumProjectRole, PROJECT_ROLE} from "../../../redux/slices/auth/projectsSlice";
import Description from "../../../common/global/Description";


export function CreateDocumentModal():ModalSettings{

    const modalId = nanoid(3)
    return {
        modalId: modalId,
        onClose: ()=>{
            getAppStore().dispatch(resetCreateDocNumData())
        },
        size: DEFAULT_STYLES.modalWidth,
        children: (
            <ModalContainer modalId={modalId}/>
        ),
    }
}

function ModalContainer ({modalId}: {modalId: string}){

    const [docKeyData, setDocKeyData] = useState<null | DocKeyData>(null);
    const [fillableComponents, setFillableComponents] = useState<null | FillableComponents >(null);
    const dispatch = useAppDispatch()
    const projectState = useSelector(projectsSelector)
    const createDocNumData = useSelector(createDocNumDataSelector)

    useEffect(() => {
        if (!docKeyData){
            const request = dispatch(getDocKeyData({projectId: projectState.activeProject?._id!}))
            request.then((action)=> {

                if (getDocKeyData.fulfilled.match(action)){

                    if (action.payload){
                        setDocKeyData(action.payload)
                        // building fillableComponent items.
                        const attributeComponents = action.payload.activeDocKeyParts.fillableParts.attributesParts.map((element) => {
                            return <AttributeComponent attributeConfig={element.attributeConfig} key={element.attributeConfig._id} docKeyPart={element.docKeyPart} attributes={element.attributes}/>
                        })


                        const commentsComponents = action.payload.activeDocKeyParts.fillableParts.commentsParts.map((element) => {

                            const docKeyPartValue = createDocNumData.find((partValue: DocKeyPartValue) => {
                                return partValue.docKeyPartId === element._id
                            })
                            if (!docKeyPartValue){
                                const newDocNumData: DocKeyPartValue = {docKeyPartId: element._id!, typeId: element.typeId, value: ""}
                            }
                            return (<CommentComponent key={element._id}  docKeyPart={element}/> )
                        })

                        setFillableComponents({attributesComponents: attributeComponents, commentsComponents: commentsComponents })
                    }

                }else{

                    // check if aborted
                    if (isAbortError(action.error)){
                        return
                    }
                    openConfirmModal({
                        cancelProps: { style: {display:"none"} },
                        onConfirm() {
                            closeAllModals()
                        },
                        labels: {cancel: "", confirm: UI_TEXTS.main.global.docNum.create.errorOpeningButton},
                        title: <ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.main.global.docNum.create.errorOpeningTitle} message={UI_TEXTS.main.global.docNum.create.errorOpeningMessage} />
                    })
                }
            })

            return () => {
                // `createAsyncThunk` attaches an `abort()` method to the promise
                request.abort()
            }
        }
    }, []);

    return (
        <div key={modalId.concat("0")} style={{
            padding: DEFAULT_STYLES.modalContainerPadding
        }}>
            <ModalHeader closeCallback={()=>{

                //check if there are any inputs
                closeAllModals()
                dispatch(resetCreateDocNumData())

            }} modalId={modalId} key={modalId.concat("1")} title="Dokument erstellen" subtitle="Bitte alle notwendigen Felder auswählen und fortfahren"></ModalHeader>
            {
                [((docKeyData !== null) && (fillableComponents !== null)) ? <Content docKeyData={docKeyData} key={docKeyData.docKeyId} fillableComponents={fillableComponents!}/> : <ModalLoader/>]
            }
        </div>
    )
}

export const useSectionStyle = createStyles((theme, _params) => ({
    section: {
        marginTop: 53,
        borderBottom: `1px solid ${theme.colors.gray[2]}`
    },
    headerContainer: {
        display: "flex",
        gap: 16,
        alignItems: "center",
        marginBottom: 32 // todo standardise
    },
    header: {
        fontWeight: "bold",
        color: "#1f5aad",
        fontSize: theme.fontSizes.md + 2
    }
}))

export function AttributeSection({children, title} : {children: any, title?: string}) {

    const {classes} = useSectionStyle()

    return (
        <div className={classes.section}>
            <div className={classes.headerContainer}>
                <img src='./illustrations/main/documents/AttributeIcon.svg' width={SectionIconWidth}/>
                <p className={classes.header}>{title ? title : "Attribute"}</p>
            </div>
            <div>
                {children}
            </div>

        </div>
    );
}

export function OthersSection({children, title} : {children: any, title?: string}) {

    const {classes} = useSectionStyle()

    return (
        <div className={classes.section}>
            <div className={classes.headerContainer}>
                <img src='./illustrations/main/documents/OthersIcon.svg' width={SectionIconWidth}/>
                <p className={classes.header}>{title ? title : "Weiteres"}</p>
            </div>

            <div>
                {children}
            </div>

        </div>
    );
}

function Content({fillableComponents, docKeyData}: {docKeyData: DocKeyData, fillableComponents: FillableComponents}) {

    const [informUser, setInformUser] = useState(false);
    const createDocNumData = useSelector(createDocNumDataSelector)

    const {buttonIsClickable, message} = useMemo(() => {

        // check if all ids where isOptional are there.#.
        let valid = true

        const incompleteDocKeyPartLabels: string[] = [] // list of incomplete docKeyPart labels

        // performing attributeCheck
        const checkAttributes = ()=> {
            for (const attributePart of docKeyData.activeDocKeyParts.fillableParts.attributesParts){

                const foundDocKeyPartValue = createDocNumData.find((element: DocKeyPartValue)=> {
                    return element.docKeyPartId === attributePart.docKeyPart._id
                })

                if (!foundDocKeyPartValue){
                    if (!(attributePart.docKeyPart.isOptional)){
                        //console.log("!attributePart.docKeyPart.isOptional AND not found - " + attributePart.docKeyPart.label)
                        valid = false
                        incompleteDocKeyPartLabels.push(attributePart.docKeyPart.label)
                    }
                    continue;
                }

                // not optional
                if (attributePart.attributeConfig.activeAttributes.length >= 2){
                    // it's nested Selection

                    for (const attribute of attributePart.attributes ){

                        // checking if all required nested attributes are selected
                        // docKeyPart found
                        const selectedAttributeValues = (foundDocKeyPartValue.value as AttributeSelectionValue).attributeValues

                        // we now have all the selectedAttributes
                        // search if optional attribute is in selectedAttributs
                        const foundAttribute = selectedAttributeValues.find((element)=> {
                            return element.attributeId === attribute._id
                        })

                        if (!foundAttribute){
                            // we didn't find the required attribute
                            // check if it's optional
                            // todo: make further checks
                            if (!(attribute.isOptional)){
                                valid = false
                                incompleteDocKeyPartLabels.push(attributePart.docKeyPart.label)
                            }
                            // break the chain
                            // break;
                        }
                    }

                }else if (attributePart.attributeConfig.activeAttributes.length === 1){
                    // single Selection
                    const found = createDocNumData.find((element: DocKeyPartValue)=> {
                        return element.docKeyPartId === attributePart.docKeyPart._id
                    })
                    if (!found){
                        valid = false
                        incompleteDocKeyPartLabels.push(attributePart.docKeyPart.label)
                    }
                }else{
                    //NOTE: there is nothing to check
                }
            }
        }
        checkAttributes()

        // performing other checks
        const checkComments = ()=> {
            for (const commentPart of docKeyData.activeDocKeyParts.fillableParts.commentsParts){

                const foundDocKeyPartValue = createDocNumData.find((element: DocKeyPartValue)=> {
                    return element.docKeyPartId === commentPart._id
                })

                if (!foundDocKeyPartValue){

                    if (!(commentPart.isOptional)){
                        valid = false
                        incompleteDocKeyPartLabels.push(commentPart.label)
                        break
                    }

                    // is optional and not provided, ignore.
                    continue;
                }

                // we found the comment
                if ((typeof foundDocKeyPartValue.value !== "string") ||
                    (foundDocKeyPartValue.value.length > MAXIMUM_DOCNUM_COMMENT_LENGTH) ||
                    (!(commentPart.isOptional) && (foundDocKeyPartValue.value.length < 1))){
                    valid = false
                    incompleteDocKeyPartLabels.push(commentPart.label)
                    break
                }
            }
        }
        checkComments()

        const message = <div><p>Alle Stern markierten Felder müssen ausgefüllt werden.</p><br/><p style={{fontWeight: 500}}>Folgende Felder fehlen:</p> <p>{incompleteDocKeyPartLabels.join(", ")}</p></div>

        return {buttonIsClickable: valid, message}

    }, [createDocNumData]);
    const [loading, setLoading] = useState(false);

    return (
        <div>
            <FullscreenLoader visible={loading}/>
            <div key={docKeyData.docKeyId}>

                {fillableComponents.attributesComponents.length > 0 ? <AttributeSection>{fillableComponents.attributesComponents}</AttributeSection> : <></>}

                {fillableComponents.commentsComponents.length > 0 ?  <OthersSection>{fillableComponents.commentsComponents}</OthersSection>
                    : <></>}

                {(fillableComponents.attributesComponents.length < 1 && fillableComponents.commentsComponents.length < 1) ? <Alert icon={<IconAlertCircle size={16} />} title="Dokumentenname nicht konfigurierbar" color="yellow">
                    Der Dokumentenschlüssel in diesem Projekt hat keine auswählbaren Felder.
                </Alert> : <></>}


                <div style={{
                    marginBottom: 20,
                    marginTop: 20
                }}>
                    {((informUser) && (!buttonIsClickable) )? (<Alert icon={<IconAlertCircle size={16} />} title="Nicht alle notwendigen Felder ausgefüllt!" color="red">
                            {message}
                    </Alert>) : null}

                </div>
                <div style={{display: "flex", marginTop: 30}}>

                    <Button style={{marginLeft: "auto"}} disabled={(informUser) && (!buttonIsClickable)} onClick={()=>{

                        setInformUser(false)
                        if (!buttonIsClickable){
                            setInformUser(true)
                            return
                        }

                        // we can perform simulation
                        setLoading(true)
                        openModal(SimulateCreationModal())

                    }}>Weiter</Button>
                </div>
            </div>
        </div>
    );
}

export interface FillableComponents { attributesComponents: any, commentsComponents: any}

export const SectionIconWidth = 40



export const useSectionComponentStyles =  createStyles((theme, _params) => ({

    selectionContainer: {
        maxWidth: "100%",
        margin: 8,
        display: "flex",
        gap: 32,
        flexWrap: "wrap"
    },
    container: {
        marginBottom: 22,
        paddingBottom: 22,
    }

}))
export function CommentComponent({docKeyPart} : {docKeyPart: DocKeyPart}) {

    const {classes} = useSectionComponentStyles()

    const [error, setError] = useState("");
    const [value, setValue] = useState('');
    const dispatch = useAppDispatch()
    const createDocNumData = useSelector(createDocNumDataSelector)

    useMemo(() => {

        const docKeyPartValue = createDocNumData.find((partValue: DocKeyPartValue) => {
            return partValue.docKeyPartId === docKeyPart._id
        })
        if (docKeyPartValue){
            setValue(docKeyPartValue.value as string)
        }

    }, []);

    return (<div className={classes.container}>

        <SectionFullHeader required={!(docKeyPart.isOptional)} label={docKeyPart.label} description={docKeyPart.description}/>
        <Textarea
            styles={{
                input: {
                    overflowY: "hidden"
                }
            }}
            placeholder={docKeyPart.label + " hinzufügen "}
            label={null}
            autosize
            value={value} onChange={(event) => {

                const newValue = (event.currentTarget.value)

                if (newValue.length >= MAXIMUM_DOCNUM_COMMENT_LENGTH){
                    setError("Maximal 100 Zeichen")
                }else{

                    setError("")

                    // whiteSpace can't be first element
                    if (newValue === " " && value === "") return
                    if (newValue[newValue.length - 1] === "\n") return

                    setValue(newValue)

                    // check if value is ""
                    const newDocNumData: DocKeyPartValue = {
                        docKeyPartId : docKeyPart._id!,
                        typeId: docKeyPart.typeId,
                        value: newValue === "" ? null : newValue
                    }

                    dispatch(changeCreateDocNumData(newDocNumData))
                }

            }}
            error={error}
            required={!(docKeyPart.isOptional)}
            minRows={1}
        />


    </div>)
}
function AttributeComponent({docKeyPart, attributes, attributeConfig}: {attributeConfig: AttributeConfig,docKeyPart: AttributePart, attributes: Attribute[]} ) {

    const {classes} = useSectionComponentStyles()
    const createDocNumData = useSelector(createDocNumDataSelector)

    const [selectionDataList, setSelection] = useState<SelectionData[]>([]);
    const dispatch = useAppDispatch()
    const changeSelectionList = (oldItem: SelectionData | null, newItem: SelectionData |null)=> {

        let newSelectionList = [...selectionDataList]

        if ((oldItem !== null)){
            // filter out the old item

            newSelectionList = newSelectionList.filter((element) => {
                return element.attributeValueId !== oldItem.attributeValueId;
            })

            // old item is now removed.
            // filtering all dependencies parentAttributeValueIds
            let deletedAttributeId: string[] = []
            deletedAttributeId.push(oldItem.attributeId)

            while(true) {

                let noElementsFiltered = true
                newSelectionList = newSelectionList.filter((element) => {

                    if (deletedAttributeId.includes(element.parentAttributeId)){

                        // selectedList we want to remove
                        // filter out
                        deletedAttributeId.push(element.attributeId)
                        noElementsFiltered = false
                        return false
                    }else{
                        return true
                    }
                })

                if (noElementsFiltered){
                    // all elements filteredOut
                    break
                }
            }
        }

        //adding new selection
        if (newItem !== null){
            newSelectionList.push(newItem)
        }

        setSelection(newSelectionList)

        // propagate to global state
        let value
        if (newSelectionList.length > 0){
            value = {attributeValues: newSelectionList}
        }else{
            value = null
        }

        const newDocKeyPartValue: DocKeyPartValue = {
            docKeyPartId : docKeyPart._id!,
            typeId: docKeyPart.typeId,
            value: value
        }
        dispatch(changeCreateDocNumData(newDocKeyPartValue))
    }


    // checking if data already exists
    useMemo(() => {

        const docKeyPartValue = createDocNumData.find((partValue: DocKeyPartValue) => {
            return partValue.docKeyPartId === docKeyPart._id
        })
        if (docKeyPartValue){
            // there is a stored docNumData for this part
            // changeSelectionList
            const attributeSelectionValues = docKeyPartValue.value
            if (attributeSelectionValues && (typeof attributeSelectionValues !== "string")){
                if (Array.isArray(attributeSelectionValues.attributeValues)){
                    setSelection(attributeSelectionValues.attributeValues)
                }
            }
        }

    }, []);

    const attributeSelections: any = useMemo(() => {

        const isSingleSelection = attributes.length === 1
        const isNestedSelection = attributes.length >= 2

        if (isSingleSelection){

            // setting up single selection
            const renderedAttribute = attributes[0]
            //todo check if really is creatable
            return ((<AttributeValueSelection creatable={true} key={renderedAttribute._id} selectionDataList={selectionDataList} isSingleSelection changeSelectionList={changeSelectionList}  required={!docKeyPart.isOptional } attribute={renderedAttribute}/>))

        }else if (isNestedSelection){

            return attributeConfig.activeAttributes.map((activeAttributeId, index) => {

                // getting data of Attribute
                const activeAttribute = attributes.find((currentAttribute)=>{
                    return activeAttributeId === currentAttribute._id
                })

                if (!activeAttribute){
                    return(<Alert styles={{
                        root:{
                            paddingTop: 20,
                            paddingRight:30
                        }
                    }} icon={<IconAlertCircle size={16} />} title={"Fehler bei Attribut mit Id: " + activeAttributeId } color="red">{null}</Alert>)
                }
                //todo check if really is creatable

                return (<AttributeValueSelection key={activeAttributeId} creatable={true} parentAttributeId={attributeConfig.activeAttributes[index - 1]} selectionDataList={selectionDataList} isSingleSelection={false} changeSelectionList={changeSelectionList} required={!(activeAttribute.isOptional)} attribute={activeAttribute}/>)
            })

        }else{

            // there is no attribute
            return(<Alert styles={{
                root:{
                    paddingTop: 20,
                    paddingRight:30
                }
            }} icon={<IconAlertCircle size={16} />} title="Keine Werte hinterlegt!" color="yellow">{null}</Alert>)
        }

    }, [selectionDataList]);

    //overwrite isOptional
    return (<div className={classes.container}>

        <SectionFullHeader required={!(docKeyPart.isOptional)} label={docKeyPart.label} description={docKeyPart.description}/>

        <div className={classes.selectionContainer}>{
            attributeSelections
        }</div>

    </div>)
}

export const getSelectStyles = ()=>{
    return {
        input: {
            borderStyle: "none !important;",
            '&:hover': {
                cursor: "not-allowed !important;"
            }
        },
        root: {
            opacity: 0.4
        },
        wrapper: {
            width: 60
        }}
}
export function AttributeValueSelection({attribute, parentAttributeId, selectionDataList, isSingleSelection, required, changeSelectionList, creatable}: {parentAttributeId?: string | undefined, creatable?: boolean,isSingleSelection: boolean, selectionDataList: SelectionData[],attribute: Attribute, required: boolean, changeSelectionList: Function}) {

    const [searchValue, onSearchChange] = useState('');
    const [fetchedData, setFetchedData] = useState<null | {parentAttributeValueId: string, selectItems: SelectItem[]}>(null) //holds attributeValues
    const [error, setError] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const projectState = useSelector(projectsSelector)
    const [selectionData, setSelectionData] = useState<SelectionData | null>(null);
    const [active, setActive] = useState(false);
    const [parentAttributeValueId, setParentAttributeValueId] = useState("");
    const [value, setValue] = useState<string | null>(null);
    const [isEditMenuOpen, setIsEditMenuOpen] = useState(false)
    const [isEditTooltipOpen, setIsEditTooltipOpen] = useState(false);

    const {classes} = createStyles((theme, _params) => ({

        customItem: {

            minWidth: "100%", padding: 5,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            borderRadius: 22,
            '&:hover': {
                cursor: "pointer",
            },
        },

        createItem: {
            display: "flex",
            padding: 4,
            gap: 16,
            alignItems: "center",

        }

    }))()

    const SEARCH_LIMIT = 50
    // handle selection change
    useEffect(() => {

        if (parentAttributeId){

            // check if selectionData of the parent attribute exists
            let parentSelectionData: SelectionData | undefined | boolean = selectionDataList.find((activeSelection) => {
                return activeSelection.attributeId === parentAttributeId
            })

            // now checking if an entry exists on this one.
            if (!parentSelectionData){

                // is not active, reset
                setParentAttributeValueId("")
                setActive(false)
                setSelectionData(null)
                onSearchChange("")
                setValue(null)
                setFetchedData(null)
            }else{

                // parent is selected
                // check if the fetched data is valid
                if ((fetchedData) && (fetchedData.parentAttributeValueId === parentSelectionData.attributeValueId)){
                    // data is loaded and parent selection matches
                    // do not reload
                    return;
                }

                setActive(true)

                // resetting data
                setFetchedData(null)
                setParentAttributeValueId(parentSelectionData.attributeValueId)
                setSelectionData(null)
                onSearchChange("")
            }

        }else{
            if (selectionDataList.length <= 0){
                setValue(null)
                setSelectionData(null)
            }
            if (active){
                return
            }
            // is root element
            setActive(true)
            setParentAttributeValueId("")

        }

    }, [selectionDataList]);

    // fetch data if active
    const fetchAPI = () => {
        const request = dispatch(getAttributeValue({projectId: projectState.activeProject?._id!, attributeId: attribute._id, parentId: parentAttributeValueId }))
        request.then((action)=> {

            if (getAttributeValue.fulfilled.match(action)){

                if (action.payload){

                    const data = (action.payload.attributeValues.map((element => {
                        return  {
                            value: element._id, //id of attributeValue
                            description: element.description,
                            label: element.value
                        } as SelectItem
                    })))
                    setFetchedData({parentAttributeValueId: parentAttributeValueId, selectItems: data})
                    setError(false)
                }

            }else{
                // check if aborted
                if (isAbortError(action.error)){
                    return
                }
                setError(true)

            }
        })
        return request
    }

    useEffect(() => {

        if (!fetchedData && active && (!error)){
            const request = fetchAPI()
            return () => {
                request.abort()
            }
        }else if (fetchedData && active && !error){

            // check if selectionData of the parent attribute exists
            if (parentAttributeId){
                // first make a check if data matches
                let parentSelectionData: SelectionData | undefined | boolean = selectionDataList.find((activeSelection) => {
                    return activeSelection.attributeId === parentAttributeId
                })

                if (!parentSelectionData){
                    return
                }

                if(fetchedData.parentAttributeValueId !== parentSelectionData.attributeValueId){
                    return // does not match. Do nothing
                }
            }

            const restoredSelection = selectionDataList.find((selectionData)=>{
                return selectionData.attributeId === attribute._id
            })
            if (restoredSelection){
                setValue(restoredSelection.attributeValueId)
                setSelectionData(restoredSelection)
                return
            }
        }

    }, [fetchedData, active, error]);

    if (!active){
        return (
            <Tooltip multiline width={250} openDelay={1500} offset={6} withArrow position={"bottom-start"} label={"Das vorherige Element muss zuerst ausgewählt werden"} >
                <Select
                    key={attribute._id}

                    styles={getSelectStyles()}
                    required={required}
                    label={attribute.label}
                    nothingFound=""
                    data={[]}
                />
            </Tooltip>
        );
    }

    if (error){

        return <Select
            key={attribute._id}

            styles={{
                wrapper:{
                     width: 300
                }
             }
            }
            required={required}
            rightSection={<Button onClick={()=> setError(false)} variant="subtle">Erneut laden</Button>}
            rightSectionWidth={120}
            label={ isSingleSelection ? null : attribute.label}
            placeholder="Fehler"
            error="Fehler beim Laden der Werte."
            data={[]}
        />

    }

    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        label: string;
        value: string;
        description: string | null;
    }


    const customItemComponent = forwardRef<HTMLDivElement, ItemProps>(
        ({value, label, description, ...others}: ItemProps, ref) =>  {   // value is the id of the selected Item, label the name

            let showDivider = true

            let isLastSearchLimitItem

            if (fetchedData && (fetchedData.selectItems.length >= SEARCH_LIMIT)){
                isLastSearchLimitItem = (value === fetchedData.selectItems[SEARCH_LIMIT - 1].value)
                if (isLastSearchLimitItem && !searchValue){
                    return <div style={{

                        padding: 16
                    }}>
                        <Text color={DEFAULT_STYLES.descriptionColor} weight={500}>
                           Suchfunktion verwenden um weitere Werte anzuzeigen.
                        </Text>
                    </div>
                }

            }

            if ((fetchedData && fetchedData.selectItems && fetchedData.selectItems.length > 1)){

                if ((fetchedData.selectItems.length >= SEARCH_LIMIT) && isLastSearchLimitItem){
                    showDivider = false

                }else if ((value === fetchedData.selectItems[fetchedData.selectItems.length - 1].value)){
                    showDivider = false
                }
            }else{
                showDivider = false
            }

            return (

                <div  ref={ref} key={value}>
                    <div style={{borderRadius: 6}} {...others}>

                        <div  className={classes.customItem}>

                                    <Text>{label}</Text>
                                    { ((typeof description === "string") && (description) ? <Description key={"011" + value} >{description}</Description> : null)}
                        </div>
                    </div>

                    <Divider
                        style={{marginLeft: 6, marginRight: 6,display: showDivider ?  undefined : "none"}}
                        size={1} color={DEFAULT_STYLES.dividerColor}/>

                </div>

            )
        }
    );


    if (fetchedData){
        return (
            <Select

                key={attribute._id}
                shouldCreate={(query, items): boolean => {
                    if (!hasMinimumProjectRole(projectState.activeProject?.projectRole, PROJECT_ROLE.SUPER_USER)){
                        return false
                    }

                    const trimmed = query.trim();
                    if(trimmed && !items.find((i) => i.label?.toLowerCase() === trimmed.toLowerCase())){
                        return true
                    }
                    return false
                }}
                maxDropdownHeight={250}
                label={ isSingleSelection ? null : attribute.label}
                placeholder={"Auswählen"}
                clearable
                creatable={creatable}
                getCreateLabel={(query) => {
                    if (query && query.length > 0 && query.length < 100){
                        return  <div className={classes.createItem}><IconPlus color={DEFAULT_STYLES.confirmColor} strokeWidth={2.5} size={17}/> <Text weight={400}> <Text span weight={500}>'{query}'</Text> Erstellen</Text></div>
                    }else{
                        return undefined
                    }
                }}
                limit={SEARCH_LIMIT}
                itemComponent={customItemComponent}
                allowDeselect
                value={value}
                onChange={(selectedAttributeValueId)=> {

                    if ((selectedAttributeValueId === selectionData?.attributeValueId) || (selectionData === null && selectedAttributeValueId === null)){
                        // same selection do nothing
                        return
                    }


                    //NEW:
                    // check if last selection
                    if (fetchedData && (fetchedData.selectItems.length >= SEARCH_LIMIT)){
                        const isLastSearchLimitItem = (value === fetchedData.selectItems[SEARCH_LIMIT - 1].value)
                        if (isLastSearchLimitItem && !searchValue){
                            return
                        }

                    }


                    let newSelectionData: SelectionData | null
                    if (selectedAttributeValueId === null){
                        newSelectionData = null
                    }else{

                        const foundSelectItem = (fetchedData.selectItems.find((selectItem)=>{
                            return selectItem.value === selectedAttributeValueId
                        }))
                        let attributeValueLabel = "Unbekannter Wert"
                        if (foundSelectItem && foundSelectItem.label){
                            attributeValueLabel = foundSelectItem.label
                        }

                        newSelectionData = {
                            parentAttributeId: (parentAttributeId === undefined) ? "" : parentAttributeId,
                            attributeValueId: selectedAttributeValueId,
                            parentAttributeValueId: parentAttributeValueId,
                            attributeId: attribute._id,
                            attributeLabel: attribute.label,
                            attributeValueLabel: attributeValueLabel
                        }
                    }

                    setValue(selectedAttributeValueId)
                    changeSelectionList(selectionData, newSelectionData)
                    setSelectionData(newSelectionData)

                }}
                styles={{
                    withIcon: { paddingLeft: 52}, icon: {
                    width: 45,
                    borderRadius: 100,
                    marginLeft: 2, pointerEvents: 'all' } }}

                icon={value && hasMinimumProjectRole(projectState.activeProject?.projectRole, PROJECT_ROLE.SUPER_USER)?
                    <Menu
                        opened={isEditMenuOpen} onOpen={() => setIsEditMenuOpen(true)}
                          onClose={() => setIsEditMenuOpen(false)}
                          position="bottom-start"
                          transition={DEFAULT_STYLES.transition} transitionDuration={DEFAULT_STYLES.transitionDuration}
                          withArrow
                        offset={6}
                          width={250}
                        styles={{
                            dropdown: {
                                marginLeft: 8
                            }
                        }}
                    >

                        <Menu.Target>
                            <Tooltip
                                opened={isEditTooltipOpen}
                                label="Attributwert bearbeiten"
                                offset={8}
                                position="bottom-start"
                                withArrow
                                styles={{tooltip: {
                                        marginLeft: 8
                                    }}
                                }

                            >

                            <ActionIcon
                                sx={{
                                    "&:hover": {
                                        opacity: 0.5,
                                        backgroundColor: "#e3e4e5",
                                        transform: "translateY(-1px)"
                                    }
                                }}
                                onMouseLeave={() => setIsEditTooltipOpen(false)}
                                onMouseOver={() => {
                                    if (!isEditMenuOpen) {
                                        setIsEditTooltipOpen(true)
                                    } else {
                                        setIsEditTooltipOpen(false)
                                    }}}
                                variant="subtle"
                                style={{borderRadius: 100 }}
                                onClick={() => {
                                    setIsEditMenuOpen(!isEditMenuOpen)
                                    setIsEditTooltipOpen(!isEditTooltipOpen)

                                }}>

                                <Edit color={DEFAULT_STYLES.defaultIconColor} strokeWidth={2} size={20}/>

                            </ActionIcon>

                            </Tooltip>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Label>Attributwert bearbeiten</Menu.Label>
                            <Menu.Item onClick={()=>{

                                if (!selectionData || !projectState.activeProject){
                                    return
                                }

                                const requestData: IDeactivateAttributeValueThunk ={
                                    attributeValueId: selectionData.attributeValueId,
                                    projectId: projectState.activeProject?._id
                                }

                                const confirmModal = nanoid()
                                openConfirmModal({
                                    modalId: confirmModal,
                                    children: (
                                        <ThunkButtons modalId={confirmModal} labels={{
                                            confirm: UI_TEXTS.main.global.attributeValue.delete.confirmButton
                                        }} successCallback={()=>{
                                            //removing selection
                                            setSelectionData(null)
                                            changeSelectionList(selectionData, null)

                                        }} responseTexts={{
                                            success: {
                                                title: UI_TEXTS.main.global.attributeValue.delete.deletionSuccessTitle,
                                                message: <Text style={{marginTop: 16}} color={"#393944"} weight={400}><Text span weight={600}>'{selectionData?.attributeValueLabel}'</Text> {UI_TEXTS.main.global.attributeValue.delete.deletionSuccessMessage1}  <Text span weight={600}>'{attribute.label}'</Text>  {UI_TEXTS.main.global.attributeValue.delete.deletionSuccessMessage2}</Text>,
                                                buttonContinue: UI_TEXTS.main.global.attributeValue.delete.deletionSuccessButton
                                            },
                                            error: {
                                                title: UI_TEXTS.main.global.attributeValue.delete.deletionFailedTitle,
                                                message: UI_TEXTS.main.global.attributeValue.delete.deletionFailedMessage,
                                                buttonContinue: UI_TEXTS.main.global.attributeValue.delete.deletionFailedButton
                                            },
                                        }} thunk={deactivateAttributeValueThunk} data={requestData} errorMessages={ERROR_TEXTS.private.documents.attributeValue.deactivate}/>
                                    ),
                                    groupProps: {
                                        style: {
                                            display: "none"
                                        }
                                    },
                                    closeOnClickOutside: true,
                                    title: <ConfirmModalHeader  type={ConfirmType.None}
                                                                title={UI_TEXTS.main.global.attributeValue.delete.confirmationTitle}
                                                                message={<Text style={{marginTop: 16}} color={"#393944"} weight={400}>{UI_TEXTS.main.global.attributeValue.delete.confirmMessage1_beforeAttributeValue} <Text span weight={600}>'{selectionData?.attributeValueLabel}'</Text> {UI_TEXTS.main.global.attributeValue.delete.confirmMessage2_beforeAttribute}  <Text span weight={600}>'{attribute.label}'</Text>  {UI_TEXTS.main.global.attributeValue.delete.confirmMessage3_afterAttribute} </Text> }/>
                                })

                               // <br/> <br/> {UI_TEXTS.main.global.attributeValue.delete.confirmMessage4_end}
                            }
                            } color="red" icon={<Trash size={21}> </Trash>}
                            >
                                Löschen
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>: undefined}

                onCreate={(newValue) => {

                    const confirmModal = nanoid()
                    if (!projectState?.activeProject?._id) return

                    /*
                     const requestData: ICreateAttributeValueRequest = {
                        projectId: projectState.activeProject._id,
                        attributeId: attribute._id ,
                        parentId: parentAttributeValueId, //parent AttributeValueId
                        value: newValue
                    }
                     */
                    const requestData: ICreateAttributeValue = {
                        value: newValue, //the new value that the user wants to create
                        projectId: projectState.activeProject._id, //associated projectId
                        parentId: parentAttributeValueId, //id of parent attributeValue
                        description: "",
                        attribute: attribute
                    }

                    openModal(CreateAttributeValueModal(requestData));


                    /*
                    openConfirmModal({
                        modalId: confirmModal,
                        children: (
                            <ThunkButtons modalId={confirmModal} labels={{
                                confirm: UI_TEXTS.main.global.attributeValue.create.confirmButton
                            }} responseTexts={{
                                success: {
                                    title: UI_TEXTS.main.global.attributeValue.create.creationSuccessTitle,
                                    message: <Text style={{marginTop: 16}} color={"#393944"} weight={400}><Text span weight={600}>'{newValue}'</Text> {UI_TEXTS.main.global.attributeValue.create.creationSuccessMessage1}  <Text span weight={600}>'{attribute.label}'</Text>  {UI_TEXTS.main.global.attributeValue.create.creationSuccessMessage2}</Text>,
                                    buttonContinue: UI_TEXTS.main.global.attributeValue.create.creationSuccessButton
                                },
                                error: {
                                    title: UI_TEXTS.main.global.attributeValue.create.creationFailedTitle,
                                    message: UI_TEXTS.main.global.attributeValue.create.creationFailedMessage,
                                    buttonContinue: UI_TEXTS.main.global.attributeValue.create.creationFailedButton
                                },
                            }} thunk={createAttributeValueThunk} data={requestData}/>
                        ),
                        groupProps: {
                            style: {
                                display: "none"
                            }
                        },
                        closeOnClickOutside: true,
                        title: <ConfirmModalHeader  type={ConfirmType.None}
                                                    title={UI_TEXTS.main.global.attributeValue.create.confirmationTitle}
                                                    message={<Text style={{marginTop: 16}} color={"#393944"} weight={400}>{UI_TEXTS.main.global.attributeValue.create.confirmMessage1_beforeAttributeValue} <Text span weight={600}>'{newValue}'</Text> {UI_TEXTS.main.global.attributeValue.create.confirmMessage2_beforeAttribute}  <Text span weight={600}>'{attribute.label}'</Text>  {UI_TEXTS.main.global.attributeValue.create.confirmMessage3} <br/> {UI_TEXTS.main.global.attributeValue.create.confirmMessage4_end}</Text> }/>
                    })
                     */


                    return null;
                }}
                required={required}
                searchable
                onSearchChange={onSearchChange}
                searchValue={searchValue}
                nothingFound={fetchedData.selectItems.length > 0 ? "Kein Wert gefunden" : "Keine Werte verfügbar"}
                data={fetchedData.selectItems}
                filter={(value, item) => {

                    let includesLabel
                    let includesDescription

                    if (item.label) {
                        includesLabel =  item.label.toLowerCase().includes(value.toLowerCase().trim())
                    }

                    if (item.description && value){
                        includesDescription =  item.description.toLowerCase().includes(value.toLowerCase().trim())
                    }

                    return includesLabel || includesDescription
                }

                }

            />
        );
    }else{
        return (
            <Select
                key={attribute._id}
                required={required}
                rightSection={<Loader style={{marginRight: 3}}/>}
                rightSectionWidth={28}
                label={ isSingleSelection ? null : attribute.label}
                placeholder="Lädt.."
                disabled
                data={[]}
            />
        );
    }

}

/*
                onChange={(selectedAttributeValueId)=> {

                    console.log("changed")
                    if ((selectedAttributeValueId === selectionData?.attributeValueId) || (selectionData === null && selectedAttributeValueId === null)){
                        // same selection do nothing
                        return
                    }

                    let newSelectionData: SelectionData | null
                    if (selectedAttributeValueId === null){
                        newSelectionData = null
                    }else{

                        const foundSelectItem = (fetchedData.selectItems.find((selectItem)=>{
                            return selectItem.value === selectedAttributeValueId
                        }))
                        let attributeValueLabel = "Unbekannter Wert"
                        if (foundSelectItem && foundSelectItem.label){
                            attributeValueLabel = foundSelectItem.label
                        }

                        newSelectionData = {
                            parentAttributeId: (parentAttributeId === undefined) ? "" : parentAttributeId,
                            attributeValueId: selectedAttributeValueId,
                            parentAttributeValueId: parentAttributeValueId,
                            attributeId: attribute._id,
                            attributeLabel: attribute.label,
                            attributeValueLabel: attributeValueLabel
                        }
                    }

                    setValue(selectedAttributeValueId)
                    changeSelectionList(selectionData, newSelectionData)
                    setSelectionData(newSelectionData)

                }}

 */