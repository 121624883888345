import React from 'react';
import {createStyles, Text} from "@mantine/core";

export function DataDisplayItem({label, data} : {label: string, data?: string |any}) {

    const {classes} = createStyles((theme, _params) => ({

        value: {
            marginLeft: 0,
            marginTop: 4,
            fontWeight: 500

        },
        label: {
            fontWeight: 600,
            fontSize: 13,
            color: "#9ea0b1", //8a8a8d 7b7b7f 9193A3FF
        }
    }))()

    return (
        <div key={label} >
            <Text className={classes.label}>{label.toUpperCase()}</Text>
            {
                typeof data === "string" ? (<Text className={classes.value}>{data}</Text>) : ((typeof data === "object") ? (data) : <></>)
            }
        </div>
    );
}



export function DataDisplay({items} : {items: React.ReactNode[]}){

    const {classes} = createStyles((theme, _params) => ({
        container: {
            paddingBottom: 30,
            display: "flex",
            flexDirection: "column",
            gap: 27
        }
    }))()

    if (items.length > 0){
        return <div  className={classes.container}>

            {items}

        </div>
    }
    else return <></>
}