import {useEffect, useRef} from "react";
import {closeAllModals} from "@mantine/modals";

// checks if a component is mounted. Important for modal popups
// (isMounted.current) ...
export const useIsMounted = ()=>{

    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, [])

    return {isMounted}

}