import {useEffect} from "react";
import {closeAllModals} from "@mantine/modals";

// cleans all modals after first render and after exit
export const useModalCleanup = ()=>{
    return (useEffect(() => {

        //cleanup
        closeAllModals()

        return ()=> {
            //cleanup
            closeAllModals()
        }

    }, []))
}