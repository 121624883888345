// for all mantine components

import {ButtonStylesParams, MantineTheme, MantineTransition} from "@mantine/core";
import {SmallLabelStyle} from "./headers/SmallLabel";
import {DayModifiers} from "@mantine/dates";
import {ChevronDown} from "tabler-icons-react";
import React from "react";
import {highlighter} from "@mantine/core/lib/Highlight/highlighter/highlighter";

export const INPUT_ICON_SIZE = 19 //the default size for all input icons
export const DEFAULT_STYLES = {
    defaultRadius: 7, //the default radius for all components
    fontSize: 15, //default font size for all mantine components
    inputIconSize: INPUT_ICON_SIZE,
    headerColor: "#060240",
    maxWidth: 1920,
    logoSizes: {
        xs: 70,
        sm: 80,
        md: 90,
        lg: 113,
        xl: 120
    },
    buttonGrayIconColor: "#7C7C7C",
    confirmModalWidth: 505,
    modalWidth: 900,
    modalContainerPadding: 8,
    defaultPadding: 27, //28
    defaultBackgroundColor: "#FAFAFA",
    defaultFocusBackground: "#eaebec",
    defaultSubIconColor:"#8f9099",
    descriptionColor:"#81828b",
    defaultIconColor: "#636363",
    activationColor:"#E6EBFC",
    activationFontColor:"#0805C9",
    transition:"pop-bottom-right" as MantineTransition,
    transitionDuration: 200,
    selectWidth: 240,
    dividerColor: "#E9ECEF",
    cancelColor: "#de3131",
    confirmColor: "#68a857",
    primaryColor: "#0000cd", // use theme if possible, this is just for reference.
    modalSubHeaderColor: "#73758d" // from 6B6D84FF


}

function getButtonStyles(){
    return {
        defaultProps: {
            radius: "lg"
        },

        // Subscribe to theme and component params
        styles: (theme: MantineTheme, params: ButtonStylesParams) => {

            let specificStyling = {}
            switch (params.variant){
                case "filled":
                    specificStyling = {
                        backgroundColor: theme.primaryColor,
                        color: theme.white,
                        '&:hover': {
                            backgroundColor: "#030371",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px;",
                            transform: "translateY(-3px)"
                        }
                    }
                    break

                case "light":
                    specificStyling = {
                        backgroundColor: "#ebeaff",
                        '&:hover': {
                            backgroundColor: "#c1bef1",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px;",
                            transform: "translateY(-3px)"
                        },
                        color: theme.primaryColor
                    }
                    break

                case "outline":
                    specificStyling = {
                        backgroundColor: "transparent",
                        color: "#303030",
                        '&:hover': {
                            backgroundColor: DEFAULT_STYLES.defaultFocusBackground,
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px;",
                            transform: "translateY(-3px)"
                        },
                    }
                    break

                case "default":
                    specificStyling = {
                        backgroundColor: "#f1f2f5",
                        '&:hover': {
                            backgroundColor: "#d4d7dc",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px;",
                            transform: "translateY(-3px)"
                        },
                        color: "#303030"
                    }

                    break

                case "subtle":
                    specificStyling = {
                        backgroundColor: "transparent",
                        color: theme.primaryColor,
                        borderWidth: 0,

                        borderColor: "transparent",
                        '&:hover': {
                            backgroundColor: "transparent",
                            textDecoration: "underline",
                            boxShadow: "none",
                            transform: "translateY(-3px)"

                        }
                    }
                    break


            }

            return (

                {
                    leftIcon: {
                        margin: 0,
                        marginRight: 16,
                        marginLeft: -2
                    },
                    rightIcon: {
                        margin: 0,
                        marginLeft: 16,
                        marginRight: -2
                    },
                    root: {

                        height: 46,
                        paddingLeft: 16,
                        paddingRight: 16,
                        fontWeight: 600,
                        cursor: "pointer",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e5e5e5",
                        transition: "all 0.2s ease 0s",
                        '&:active:hover': {
                            transform: "translateY(5px)",
                            opacity: 0.5
                        },
                        ...specificStyling
                    },
                })

        },
    }
}
function getModalStyles(){
    return {
        defaultProps: {
            overlayOpacity: 0.85
        },
        styles: (theme: MantineTheme) => ({
            modal: {
                padding: "23px !important;",
                borderRadius: theme.defaultRadius
            },

        })
    }
}
function getMenuStyles(){
    return {

        defaultProps: {
            width: 230,
            arrowSize: 6,
            shadow: "xl"
        },
        styles: (theme: MantineTheme) => ({
            label: {
                fontWeight: 500,
                color: SmallLabelStyle.color,
                fontSize: 13
            },
            itemIcon: {

                paddingRight: 6
            },

        })
    }
}

function getTextInputStyle(){
    return {

        styles: (theme: MantineTheme, params: ButtonStylesParams) => ({


            root: {

                transition: "all 0.4s ease 0s",
                '&:hover': {
                    transform: "translateY(-2px)"
                }

            },

            label: {
                fontWeight: 600,
                marginBottom: 3
            },
            input: {
                height: 46,
                paddingLeft: 16,
                paddingRight: 16,
                '&:hover': {
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 14px;"
                }
            },
            icon: {
                marginLeft: 6
            },
            withIcon: {
                paddingLeft: 45
            }
        }),
    }
}
function getPasswordInputStyle(){
    return {

        styles: (theme: MantineTheme, params: ButtonStylesParams) => ({


            root: {
                transition: "all 0.4s ease 0s",
                '&:hover': {
                    transform: "translateY(-2px)"
                }

            },
            label: {
                fontWeight: 600,
                marginBottom: 3
            },
            input: {
                height: 46,
                '&:hover': {
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 14px;"
                }
            },
            icon: {
                marginLeft: 6
            },
            innerInput: {
                height: 46,
                marginLeft: 10
            }
        }),
    }
}
function getActionIconStyle(){
    return {
        defaultProps: {
            radius: "lg",
            size: 46
        },
        styles: (theme: MantineTheme, params: ButtonStylesParams) => {

            //TODO prettify

            let specificStyling = {}
            switch (params.variant){
                case "filled":
                    specificStyling = {
                        backgroundColor: theme.primaryColor,
                        color: theme.white,
                        '&:hover': {
                            backgroundColor: "#030371",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px;",
                            transform: "translateY(-3px)"
                        }
                    }
                    break
                case "outline":
                    specificStyling = {
                        color: "#505253",
                        backgroundColor: "transparent",
                        '&:hover': {
                            backgroundColor: DEFAULT_STYLES.defaultFocusBackground,
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px;",
                            transform: "translateY(-3px)"
                        },
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#D6D6D6",
                    }
                    break

                case "default":
                    specificStyling = {
                        backgroundColor: "#F1F1F1",
                        '&:hover': {
                            backgroundColor: "#d4d7dc",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px;",
                            transform: "translateY(-3px)"
                        },
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#D6D6D6",
                    }
                    break

                case "subtle":
                    specificStyling = {
                        backgroundColor: "transparent",
                        radius: 0,
                        borderColor: "transparent",
                        '&:hover': {
                            backgroundColor: "#F1F1F1",
                            textDecoration: "underline",
                            boxShadow: "none"
                        }
                    }
                    break

            }

            return (

                {
                    root: {
                        fontWeight: 600,
                        cursor: "pointer",
                        transition: "all 0.2s ease 0s",
                        '&:active:hover': {
                            transform: "translateY(3px)",
                            opacity: 0.5
                        },
                        ...specificStyling
                    },
                })

        },
    }
}

const InputStyles = {
    backgroundColor: "#f1f2f5",
    height: 46,
    paddingLeft: 16,
    paddingRight: 16,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e5e5e5",
    '&:hover': {
        cursor: "pointer"
    },
    labelColor: "#61616a" //56565e

}
function getSelectStyle(){
    return {
        defaultProps: {
            radius: "lg",
            dropdownPosition: "bottom-start",
            transition: DEFAULT_STYLES.transition,
            transitionDuration:DEFAULT_STYLES.transitionDuration
        },
        styles: (theme: MantineTheme, params: any) => {


            return (
                {
                    rightSection: {
                        borderStyle: "none !important;",
                        '&:hover': {
                            // createDocument, editButton has the same hover effect
                            opacity: 0.4,
                            transform: "translateY(-1px)"
                        },
                        svg:{
                            path :{
                                fill: DEFAULT_STYLES.defaultIconColor
                            }
                        }
                    },
                    dropdown: {
                        marginTop: 0

                    },
                    wrapper: {
                        width: DEFAULT_STYLES.selectWidth,

                    },
                    item: {
                        '&[data-selected]': {
                            backgroundColor: DEFAULT_STYLES.activationColor,
                            color: DEFAULT_STYLES.activationFontColor,
                            fontWeight: 500


                        }
                    },
                    input: InputStyles,
                    label: {
                        fontWeight: 500,
                        color: InputStyles.labelColor
                    }
                })

        },
    }
}

// icon: <ChevronDown size={19} color="#8f9099"/>,
function getDatePickerStyle(){
    return {
        defaultProps: {
            radius: "lg",
            inputFormat:"DD.MM.YYYY",
            shadow: "lg",
            dropdownPosition: "bottom-start",
            transition: DEFAULT_STYLES.transition,
            transitionDuration:DEFAULT_STYLES.transitionDuration,
            dayStyle: (date: Date, modifiers: DayModifiers) => {


                return {
                    backgroundColor: modifiers.selected ? DEFAULT_STYLES.activationColor : (modifiers.disabled ? "rgba(248,248,248,0.49)" : undefined),
                    color: (modifiers.selected ? DEFAULT_STYLES.activationFontColor : (modifiers.disabled ? "rgba(248,248,248,0.49)" : (modifiers.outside ? "#909090" : undefined))),
                    borderRadius: (modifiers.disabled ? 0 : 100)
                }
            }
        },
        styles: (theme: MantineTheme, params: any) => {


            return (
                {
                    day: {
                        borderRadius: 100
                    },
                    root: {


                    },
                    dropdown: {
                      marginTop: -5
                    },
                    rightSection: {
                        borderStyle: "none !important;",
                    },
                    wrapper: {
                        width: DEFAULT_STYLES.selectWidth

                    },
                    input: InputStyles,
                    label: {
                        fontWeight: 500,
                        color: InputStyles.labelColor
                    }

                })

        },
    }
}


export function getComponentStyles(){
    return {
        Text:{defaultProps: {
                size:"sm"
            }},
        Button: getButtonStyles(),
        TextInput: getTextInputStyle(),
        PasswordInput: getPasswordInputStyle(),
        Modal: getModalStyles(),
        ActionIcon: getActionIconStyle(),
        Menu: getMenuStyles(),
        Select: getSelectStyle(),
        DatePicker: getDatePickerStyle(),
    }
}