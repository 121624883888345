import {Button, createStyles, TextInput} from "@mantine/core";
import {At} from "tabler-icons-react";
import HelpButton, {handleHelpClick} from "../../common/auth/HelpButton";
import {AuthContainer} from "../../common/auth/AuthContainer";
import {closeAllModals, openConfirmModal, openModal} from "@mantine/modals";
import {INPUT_ICON_SIZE} from "../../common/global/themeStyles";
import {AuthNavigation} from "../../components/auth/AuthNavigation";
import React, {useState} from "react";
import {UI_TEXTS} from "../../texts/ui";
import {ERROR_TEXTS, getErrorText} from "../../texts/apiErrors";
import {useModalCleanup} from "../../hooks/useModalCleanup";
import {useAppDispatch} from "../../redux/hooks";
import {resetPasswordThunk} from "../../redux/asyncThunks/auth/resetPasswordThunks";
import {LoadingStatus} from "../../redux/util";
import {useIsMounted} from "../../hooks/useIsMounted";
import {ConfirmModalHeader, ConfirmType} from "../../common/global/modals/ConfirmModal";

const validator = require('validator');

export const ResetPassword = () => {
	return (
		<div>
			<AuthNavigation/>
			<AuthContainer showReturnButton={true} title={"Passwort zurücksetzen"} subTitle={"Wir werden dir einen Link schicken, mit du dein Passwort zurücksetzen kannst."} >
				<ContainerContent/>
			</AuthContainer>
		</div>
	)
}

const useStyles = createStyles((theme, _params) => ({

	inputSection: {
		width: "100%",
		marginBottom: 32,
		display: "flex",
		flexDirection: "column",
		gap: 25
	},

	buttonGroup: {
        width: "100%",
        display: "flex",
        marginBottom: 150
	},


	buttonGroup_rightSection: {
		marginLeft: "auto"
	},

	buttonGroup_footer: {
		width: "100%",
		display: "flex",
		justifySelf: "flex-end"
	},

	buttonGroup_footer_right: {
		marginLeft: "auto",
		marginRight:-12
	},
	buttonGroup_footer_left: {
		marginLeft:-13
	}




}));
function ContainerContent() {

	const {classes} = useStyles()
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	useModalCleanup()
	const dispatch = useAppDispatch()
	const [requestState, setRequestState] = useState(LoadingStatus.idle);
	const {isMounted} = useIsMounted()

	return (
		<div>
			<div className={classes.inputSection}>
				<TextInput error={emailError} value={email} onChange={(event) => setEmail(event.currentTarget.value)} required label="E-Mail Adresse" placeholder="E-Mail eingeben" icon={<At size={INPUT_ICON_SIZE} />} />
			</div>

			<div className={classes.buttonGroup}>
				<div className={classes.buttonGroup_rightSection}>
					<Button loading={requestState === LoadingStatus.pending} variant="filled"  onClick={() => {

						if (!validator.isEmail(email)){
							//checking email
							setEmailError(UI_TEXTS.auth.login.invalidEmail)
							return;
						}else{
							setEmailError("")
						}
						setRequestState(LoadingStatus.pending)
						// dispatching api
						const promise = dispatch(resetPasswordThunk({email: email}))
						//a failed request or error in a thunk will never return a rejected promise
						promise.then((action) => {

							// don't display modal popup when component isn't mounted
							if (!isMounted.current) return

							if (resetPasswordThunk.fulfilled.match(action)){
								// can perform login
								openConfirmModal({
									cancelProps: { style: {display:"none"} },
									confirmProps: { variant: "subtle" },
									closeOnClickOutside: true,
									onConfirm: () =>{
										handleHelpClick()
									},
									labels: {confirm: UI_TEXTS.auth.resetPassword.modalHelpTitle, cancel: "" },
									title: <ConfirmModalHeader type={ConfirmType.Success} title={UI_TEXTS.auth.resetPassword.modalSuccessTitle} message={UI_TEXTS.auth.resetPassword.modalSuccessDescription} />
								})

							}else{
								const errorText = getErrorText(ERROR_TEXTS.public.auth.resetPassword, String(action.error.code))!

								openConfirmModal({
									cancelProps : { variant: "outline"},
									closeOnClickOutside: true,
									onCancel: () =>{
										handleHelpClick()
									},
									onConfirm:()=> {
										closeAllModals()
									},
									labels: {cancel: UI_TEXTS.auth.resetPassword.modalHelpTitle, confirm: "Weiter" },
									title: <ConfirmModalHeader type={ConfirmType.Error} title={errorText.title} message={errorText.message} />
								})

							}
						}).finally(()=>{
							setRequestState(LoadingStatus.idle)
						} )

					}	}>Zurücksetzen</Button>

				</div>

			</div>

			<div className={classes.buttonGroup_footer}>


				<div className={classes.buttonGroup_footer_left}>
				</div>

				<div className={classes.buttonGroup_footer_right}>
					<HelpButton/>

				</div>

			</div>

		</div>
	);
}


export default ResetPassword

