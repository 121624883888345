
//texts for UI errors or Modal views
export const UI_TEXTS = {

        auth : {
            login : {
                invalidPassword: "Das Passwort muss mindestens 8 Buchstaben haben",
                invalidEmail: "Bitte eine gültige E-Mail eingeben"
            },
            register : {
                invalidFirstName: "Vorname muss angeben werden",
                nameTooLong: "Der Name ist zu lang. Bitte maximal 30 Buchstaben",
                invalidLastName: "Nachname muss angeben werden",
                invalidEmail: "Bitte eine gültige E-Mail eingeben",
                invalidPassword: "Das Passwort muss mindestens 8 Buchstaben haben",
                modalSuccessTitle: "Email überprüfen",
                privacyPolicyNotCheckedTitle: "Nutzungsbedingungen",
                privacyPolicyNotCheckedDescription: "Um ein Konto zu erstellen, müssen die Nutzungsbedingungen und unsere Datenschutzerklärungen akzeptiert werden",
                modalSuccessDescription: "Wir haben dir per Email einen Link geschickt, mit dem du dein Konto bestätigen kannst",
                modalHelpTitle: "Du brauchst Hilfe?",
            },
            verifyUser : {
                modalSuccessTitle: "Konto erstellt",
                modalSuccessDescription: "Deine Registrierung war erfolgreich & dein Konto kann nun erstellt werden.",
                modalWrongParamsTitle: "Fehler",
                modalWrongParamsDescription: "Es ist ein Fehler entstanden und dein Konto kann nicht registriert werden. Bitte kehre zur Anmeldung zurück",
                toLoginButton: "Zur Anmeldung"
            },
            resetPassword : {
                modalSuccessTitle: "Email überprüfen",
                modalSuccessDescription: "Wir haben dir per Email einen Link geschickt, mit dem du dein Passwort zurücksetzen kannst.",
                modalWrongParamsTitle: "Fehler",
                modalWrongParamsDescription: "Es ist ein Fehler entstanden und dein Konto kann nicht registriert werden. Bitte kehre zur Anmeldung zurück",
                toLoginButton: "Zur Anmeldung",
                modalHelpTitle: "Hilfe",
            },
            newPassword : {
                modalSuccessTitle: "Passwort zurückgesetzt",
                modalSuccessDescription: "Dein Passwort wurde geändert. Du kannst dich ab jetzt mit deinem neuen Passwort anmelden.",
                invalidPassword: "Das Passwort muss mindestens 8 Buchstaben haben",
                modalWrongParamsTitle: "Link nicht valid",
                modalWrongParamsDescription: "Achte darauf, dass du den vollständigen Link in der E-Mail kopiert hast und probiere es erneut",
                toResetPassword: "Zurück",
                toLoginButton: "Zur Anmeldung",

            },



        },
        main : {
            global : {
                projects: {
                    getAllProjectsErrorTitle: "Daten nicht abrufbar",
                    getAllProjectsErrorTryAgain: "Erneut versuchen",
                    getAllProjectsErrorDescription: "Leider konnten die Projektdaten nicht erfolgreich abgerufen werden. Bitte versuch es noch einmal und aktualisiere die Seite."
                },
                attributeValue: {
                    create: {
                        confirmationTitle: "Wert neu erstellen?",
                        confirmMessage1_beforeAttributeValue: "",
                        confirmMessage2_beforeAttribute: " wird zum Attribut ",
                        confirmMessage3: " hinzugefügt.",
                        confirmMessage4_end: "Bitte noch einmal überprüfen und bestätigen.",
                        confirmButton: "Erstellen",

                        creationFailedTitle: "Erstellung fehlgeschlagen",
                        creationFailedMessage: "Die Erstellung ist gescheitert. Bitte versuche es noch einmal oder überprüfe deine Projektrechte.",
                        creationFailedButton: "Schließen",

                        creationSuccessTitle: "Erstellung erfolgreich",
                        creationSuccessMessage1: "wurde erfolgreich zum Attribut ",
                        creationSuccessMessage2: "hinzugefügt",
                        creationSuccessButton: "Fortfahren",

                    },
                    delete: {
                        confirmationTitle: "Attributwert löschen?",
                        confirmMessage1_beforeAttributeValue: "Der Wert ",
                        confirmMessage2_beforeAttribute: " wird vom Attribut ",
                        confirmMessage3_afterAttribute: " entfernt. Dieser Vorgang kann nicht rückgängig gemacht werden.",
                        confirmMessage4_end: "Bitte überprüfe die Aktion und stelle sicher, dass keine Verknüpfungen mit diesem Wert vorhanden sind.",

                        confirmButton: "Löschen",

                        deletionFailedTitle: "Löschung fehlgeschlagen",
                        deletionFailedMessage: "Die Löschung ist gescheitert. Bitte versuche es noch einmal oder überprüfe deine Projektrechte.",
                        deletionFailedButton: "Schließen",

                        deletionSuccessTitle: "Löschung erfolgreich",
                        deletionSuccessMessage1: " wurde erfolgreich vom Attribut ",
                        deletionSuccessMessage2: "gelöscht.",
                        deletionSuccessButton: "Fortfahren",

                    }
                },
                docNum: {
                    filter: {
                        saveFilterTitle: "Filter speichern?",
                        saveFilterMessage: "Die Dokumentenliste wird dann anhand der Konfiguration gefiltert.",
                        saveFilterSuccessTitle: "Filter gespeichert",
                        saveFilterSuccessMessage: "Der Filter wurde erfolgreich gespeichert und ist nun aktiv.",

                    },
                    create: {
                        errorOpeningTitle: "Daten nicht abrufbar",
                        errorOpeningMessage: "Es gab ein Fehler beim laden des Dokumentenschlüssels. Bitte versuche es erneut. Falls das Problem häufig auftritt melde dich beim Support.",
                        errorOpeningButton: "Schließen",

                        simulationFailedTitle: "Fehler bei der Simulierung",
                        simulationFailedDescription: "Der Name des Dokuments konnte nicht simuliert werden. Bitte probiere es noch einmal oder kontaktiere den Support.",
                        simulationFailedButton: "Weiter",


                        creationSuccessfulButton: "Weiter",
                        creationSuccessfulTitle: "Dokument erstellt",
                        creationSuccessfulDescription: "Das Dokument wurde erfolgreich erstellt und ist nun abrufbar.",
                        creationSuccessfulDescription2: "Der Dokumentenname lautet:",
                        creationFailedTitle: "Fehler bei Erstellung",
                        creationFailedDescription: "Das Dokument konnte nicht erstellt werden. Bitte probiere es noch einmal oder kontaktiere den Support.",
                        creationFailedButton: "Weiter",


                    },
                    export: {
                        noDocumentsTitle: "Keine Dokumente",
                        noDocumentsMessage: "Leider gibt es keine Dokumente die zum exportieren bereit stehen."
                    },
                    manage: {
                        errorOpeningTitle: "Daten nicht abrufbar",
                        errorOpeningMessage: "Es gab ein Fehler beim laden der Dokumentendaten. Bitte probiere es noch einmal oder kontaktiere den Support",
                        errorOpeningButton: "Schließen",

                    },
                    deactivate: {
                        deactivationQuestionTitle: "Dokument deaktivieren?",
                        deactivationQuestionMessage: "Dieser Vorgang kann nicht mehr rückgängig gemacht werden. Nach Deaktivierung kann man das Dokument auch nicht mehr bearbeiten und wird als gelöscht gewertet.",
                        deactivationQuestionButton: "Deaktivieren",
                        deactivationFailedTitle: "Deaktivierung fehlgeschlagen",
                        deactivationFailedMessage: "Das Dokument konnte nicht deaktiviert werden. Bitte probiere es noch einmal.",
                        deactivationFailedButton: "Weiter",

                        deactivationSuccessTitle: "Dokument deaktiviert",
                        deactivationSuccessMessage: "Deaktivierung erfolgreich. Das Dokument kann nun nicht mehr bearbeitet werden und wird in der Datenbank als gelöscht betrachtet.",
                        deactivationSuccessButton: "Weiter",
                    }


                    }

            }


    }


}

