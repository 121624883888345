import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {LoadingStatus} from "../../util";
import {API_ENDPOINTS, axiosInstance} from "../../apiConfigs";
import {UserResponse} from "./userSlice";

export interface IProjectsState {
	allProjects: Project[] | null,
	activeProject: Project | null,
	loading: LoadingStatus
}

export const initialProjectState: IProjectsState = {
	allProjects:  null,
	activeProject: null,
	loading: LoadingStatus.idle
}

export enum PROJECT_ROLE { // frontend reference
	ADMIN = 1,
	SUPER_USER = 2,
	USER = 3,
	VIEWER = 4,
}

export function hasMinimumProjectRole(userProjectRole: PROJECT_ROLE |any, targetRole: PROJECT_ROLE): boolean{

	switch (targetRole){

		case PROJECT_ROLE.ADMIN:
			if (userProjectRole === PROJECT_ROLE.ADMIN){
				return true
			}
			break;

		case PROJECT_ROLE.SUPER_USER:
			if ((userProjectRole === PROJECT_ROLE.ADMIN) || (userProjectRole === PROJECT_ROLE.SUPER_USER)){
				return true
			}
			break;

		case PROJECT_ROLE.USER:
			if ((userProjectRole === PROJECT_ROLE.ADMIN) || (userProjectRole === PROJECT_ROLE.SUPER_USER) || (userProjectRole === PROJECT_ROLE.USER)){
				return true

			}
			break

		case PROJECT_ROLE.VIEWER:
			if ((userProjectRole === PROJECT_ROLE.ADMIN) || (userProjectRole === PROJECT_ROLE.SUPER_USER) || (userProjectRole === PROJECT_ROLE.USER) || (userProjectRole === PROJECT_ROLE.VIEWER)){
				return true
			}
			break;

		default: // No app role specified, continue
			return true;

	}
	return false

}

export interface Project {
	_id: string,
	projectName: string, // the full name of the project
	label: string, // the label that will be displayed on the frontend
	isActive: boolean, // the project that is active
	organisationId: string | null, // if null, then no organisation is assigned to this project
	projectRole: PROJECT_ROLE
}

interface IGetAllProjectsResponse{
	allProjects: Project[],
	userData: UserResponse;

}
export const getAllProjects = createAsyncThunk(
	'projects/getAll',
	async (_, thunkAPI) => {
		const res = await axiosInstance.get<IGetAllProjectsResponse>(API_ENDPOINTS.private.projects.getAll)
		return res.data
	}
)

const projectsSlice = createSlice({
	name: 'projects',
	initialState: initialProjectState,
	reducers: {
		changeActiveProject: (state, action: PayloadAction< Project>) => {
			state.activeProject = action.payload
			state.loading = LoadingStatus.idle
		}
	},
	extraReducers: (builder) => {

		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(getAllProjects.pending, (state, action) => {
			state.loading = LoadingStatus.pending
		})

		builder.addCase(getAllProjects.rejected,
			(state, action) => {
				state.loading = LoadingStatus.rejected
			})

		builder.addCase(getAllProjects.fulfilled,
			(state, action ) => {
				state.loading = LoadingStatus.fulfilled
				state.allProjects = action.payload.allProjects
			})

	}
})


export default projectsSlice.reducer
export const {changeActiveProject} = projectsSlice.actions
