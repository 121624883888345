import React from 'react';
import {Button} from "@mantine/core";

function HelpButton(props: {title?: string}) {
    let title = "Hilfe"
    if (props.title){
        title = props.title
    }
    return (
        <Button variant="subtle" onClick={() => {
            window.open('mailto:support@proquinn.com')
        }}>{title}</Button>
    );
}

export function handleHelpClick(){
    window.open('mailto:support@proquinn.com')
}
export default HelpButton;