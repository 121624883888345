import {Button, createStyles} from "@mantine/core";
import {SubMainHeader} from "../global/headers/SubMainHeader";
import {MainHeader} from "../global/headers/MainHeader";
import React from "react";
import {useNavigate} from "react-router-dom";
import {DEFAULT_STYLES} from "../global/themeStyles";

const Icons = {
    ReturnIcon : (<svg width="17" height="14" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9852 5.00874L1.25205 5.00872C0.804748 5.00872 0.391397 5.24725 0.167741 5.63461C-0.055915 6.02197 -0.055915 6.49945 0.167741 6.88683C0.391397 7.27419 0.804743 7.51272 1.25206 7.51272L10.9852 7.51275C13.3087 7.51547 15.7644 7.25784 17.4077 8.90103C19.0508 10.5442 18.9468 10.9203 18.9495 13.244L18.9495 15.748C18.9495 16.1953 19.1882 16.6086 19.5756 16.8323C19.963 17.0559 20.4402 17.0559 20.8276 16.8323C21.215 16.6086 21.4537 16.1953 21.4537 15.748L21.4537 13.244C21.4499 10.2564 21.3459 9.41602 19.2335 7.30341C17.1208 5.19097 13.9727 5.01228 10.9852 5.00874Z" fill="#636363"/>
            <path d="M6.26001 0.000188039C5.92799 0.000397719 5.60959 0.132241 5.37483 0.367215L0.366607 5.37544C0.131843 5.6102 -5.74979e-07 5.92862 -5.45955e-07 6.26062C-5.16931e-07 6.59262 0.131843 6.91104 0.366607 7.1458L5.37483 12.154C5.69281 12.4613 6.14936 12.5779 6.57569 12.4611C7.00204 12.3441 7.33531 12.0111 7.45208 11.5845C7.56904 11.1582 7.45249 10.7016 7.1452 10.3836L3.02216 6.2606L7.1452 2.13756C7.37996 1.9028 7.51181 1.58421 7.51181 1.25216C7.51181 0.92035 7.37996 0.601747 7.1452 0.366984C6.91044 0.13222 6.59202 0.000375997 6.26002 0.00016675L6.26001 0.000188039Z" fill="#636363"/>
        </svg>
    )
}

export function AuthContainer(props: {title: string, subTitle: string | undefined, children: any, showReturnButton: boolean}) {

    const styles = useStyles();
    const navigate = useNavigate();

    return (
        <div className={styles.classes.component}>
            <div className={styles.classes.container}>

                {props.showReturnButton ? (<Button leftIcon={Icons.ReturnIcon} className={styles.classes.backButton} variant="outline" onClick={() => {
                    navigate("/auth/login");
                }}>Anmelden</Button>) : null }



                <div className={styles.classes.headerGroup}>

                    <MainHeader>{props.title}</MainHeader>
                    {(props.subTitle !== undefined || props.subTitle !== "" ) ? (<div className={styles.classes.subHeader}>
                        <SubMainHeader>{props.subTitle} </SubMainHeader>
                    </div>): null}
                </div>

                <div >
                    {props.children}
                </div>

            </div>
        </div>

    );
    }

const useStyles = createStyles((theme, _params, getRef) => ({

    component: {
        paddingBottom: 1,
    },
    container: {

        boxShadow: "rgba(17, 17, 26, 0.04) 0px 0px 16px;",
        maxWidth: 505,
        minHeight: 0,
        paddingTop: 48,
        paddingLeft: 39,
        paddingRight: 39,
        paddingBottom: 20,
        backgroundColor: "white",
        borderRadius: theme.defaultRadius,
        margin: "50px auto 120px auto"
    },
    subHeader: {
        marginTop: 0
    },
    backButton: {
        marginBottom: DEFAULT_STYLES.defaultPadding
    },
    headerGroup: {
        margin: "0 0 45px 0px"
    },

}));
