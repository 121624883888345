import {createStyles} from "@mantine/core";

export function SubMainHeader({color, children}: { color?: string, children: any}) {

    if (!color){
        color = "#6B6D84"
    }
    const { classes } = useStyles({ color });

    return (
        <p className={classes.subHeader}>{children}</p>
    );
}
const useStyles = createStyles((theme, {color}: {color: string}) => ({

    subHeader: {
        color: color,
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,
        margin: 0
    }
}));
