import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {API_ENDPOINTS, axiosInstance} from "../../apiConfigs";


//__legacy__ code
/*

export interface IResetPasswordState {
	resetPasswordRequest: LoadingStatus,
	newPasswordRequest: LoadingStatus
}
const initialState: IResetPasswordState = {
	resetPasswordRequest: LoadingStatus.idle,
	newPasswordRequest: LoadingStatus.idle
}

const resetPasswordSlice = createSlice({
	name: 'resetPassword',
	initialState,
	reducers: {},
	extraReducers: (builder) => {


		const resetPasswordStart = (()=>{
			// Add reducers for additional action types here, and handle loading state as needed
			builder.addCase(resetPasswordThunk.pending, (state, action) => {
				// Add user to the state array
				state.resetPasswordRequest = LoadingStatus.pending
			})

			builder.addCase(resetPasswordThunk.rejected,
				(state, action) => {
					// Add user to the state array
					state.resetPasswordRequest = LoadingStatus.rejected
				})

			builder.addCase(resetPasswordThunk.fulfilled,
				(state, action) => {
					// Add user to the state array
					state.resetPasswordRequest = LoadingStatus.fulfilled
				})
		})

		const newPassword = (()=> {
			// Add reducers for additional action types here, and handle loading state as needed
			builder.addCase(newPasswordThunk.pending, (state, action) => {
				// Add user to the state array
				state.newPasswordRequest = LoadingStatus.pending
			})

			builder.addCase(newPasswordThunk.rejected,
				(state, action) => {
					// Add user to the state array
					state.newPasswordRequest = LoadingStatus.rejected
				})

			builder.addCase(newPasswordThunk.fulfilled,
				(state, action) => {
					// Add user to the state array
					state.newPasswordRequest = LoadingStatus.fulfilled
				})
		})

		// adding cases
		resetPasswordStart()
		newPassword()
	}
})

export default resetPasswordSlice.reducer

 */

export interface IResetPasswordData {
	email: string
}

export const resetPasswordThunk = createAsyncThunk(
	'resetPassword/start',
	async (data: IResetPasswordData, thunkAPI) => {
		const res = await axiosInstance.get(API_ENDPOINTS.public.auth.resetPassword+"?email=" + data.email)
		return res.data
	}
)


export interface INewPasswordData {
	verificationCode: string,
	newPassword: string,
	userId: string
}

export const newPasswordThunk = createAsyncThunk(
	'resetPassword/newPassword',
	async (data: INewPasswordData, thunkAPI) => {
		const res = await axiosInstance.post(API_ENDPOINTS.public.auth.newPassword, data)
		return res.data
	}
)

