import React, {useState} from 'react';
import {Alert, Badge, Button, Text, createStyles, Textarea} from '@mantine/core';
import {closeAllModals, closeModal, openConfirmModal, openModal} from "@mantine/modals";
import 'dayjs/locale/de';
import {UI_TEXTS} from "../../../texts/ui";
import {DEFAULT_STYLES} from "../../../common/global/themeStyles";
import {nanoid} from "nanoid";
import {ModalHeader, modalSubHeaderStyles} from "../../../common/global/modals/ModalHeader";

import {ModalSettings} from "@mantine/modals/lib/context";
import {
    Attribute,
} from "../../../redux/asyncThunks/main/documents/create/getDocKeyDataThunk";
import {DataDisplay, DataDisplayItem} from "../../../common/global/DataDisplay";
import {AttributeValue} from "../../../redux/asyncThunks/main/documents/create/getAttributeValueThunk";
import {CancelButton} from "../../../common/global/buttons/CancelButton";
import {ConfirmButton} from "../../../common/global/buttons/ConfirmButton";
import {ActionGroupType, ModalActionGroup} from "../../../common/global/modals/ModalActionGroup";
import {getErrorText, isAbortError} from "../../../texts/apiErrors";
import {ConfirmModalHeader, ConfirmType} from "../../../common/global/modals/ConfirmModal";
import {useAppDispatch} from "../../../redux/hooks";
import {
    createAttributeValueThunk,
    ICreateAttributeValueRequest
} from "../../../redux/asyncThunks/main/documents/createAttributeValueThunk";
import {ModalLoader} from "../../../common/global/modals/ModalLoader";


export interface ICreateAttributeValue {
    value: string, //the new value that the user wants to create
    projectId: string, //associated projectId
    parentId: string, //id of parent attributeValue
    description: string
    attribute: Attribute
}
export function CreateAttributeValueModal(data: ICreateAttributeValue):ModalSettings{

    const modalId = nanoid(5)
    return {
        modalId: modalId,
        onClose: ()=>{
            // ignore
            return
        },
        size: DEFAULT_STYLES.modalWidth,
        children: (
            <ModalContainer modalId={modalId} data={data}/>
        ),
    }
}


function CustomSubHeader({value, attributeLabel}: { value: string, attributeLabel: string }) {

    const { classes } = modalSubHeaderStyles({ color: undefined });

    return (
        <div>
            <Text className={classes.subHeader}>{UI_TEXTS.main.global.attributeValue.create.confirmMessage1_beforeAttributeValue} <Text span color={DEFAULT_STYLES.headerColor} weight={600}>'{value}'</Text> {UI_TEXTS.main.global.attributeValue.create.confirmMessage2_beforeAttribute}  <Text span color={"#000000"}  weight={600}>'{attributeLabel}'</Text>  {UI_TEXTS.main.global.attributeValue.create.confirmMessage3}</Text>
        </div>
    );
}

function ModalContainer ({modalId, data}: {modalId: string, data: ICreateAttributeValue}){

    return (
        <div key={modalId.concat("0")} style={{
            padding: DEFAULT_STYLES.modalContainerPadding
        }}>
            <ModalHeader closeCallback={()=>{

                closeModal(modalId)

            }} modalId={modalId} key={modalId.concat("1")} title="Neuen Wert erstellen?" subtitle={<CustomSubHeader value={data.value} attributeLabel={data.attribute.label} />}></ModalHeader>

            {
                <Content modalId={modalId} data={data}/>
            }
        </div>
    )
}


function Content({ data, modalId} : {data: ICreateAttributeValue, modalId: any}) {

    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch()

    const dataDisplayItems = [

        <DataDisplayItem label="Wert" data={data.value}/>,
        <DataDisplayItem label="Attribut" data={data.attribute.label}/>,
        <DataDisplayItem label="Beschreibung" data={
            <Textarea
            styles={{
                input: {
                    overflowY: "hidden"
                },
                root: {
                    marginTop: 6
                }
            }}
            placeholder={"Beschreibung hinzufügen"}
            label={null}
            autosize
            value={description} onChange={(event) => {

            const newValue = (event.currentTarget.value)

            if (newValue === " " && description === "") return
            if (newValue[newValue.length - 1] === "\n") return // not allowing new lines.

            if (newValue.length >= AttributeValue.RULES().DESCRIPTION.MAX_LENGTH){
                setError(`Maximal ${AttributeValue.RULES().DESCRIPTION.MAX_LENGTH} Zeichen`)
            }else{


                setError("")
                setDescription(newValue)
            }

        }}
            error={error}
            required={false}
            minRows={1}
        />}/>
    ]

//todo add this actiongroup to all other modals

    if (isLoading){
        return <ModalLoader/>
    }

    return (
            <div>


                <DataDisplay items={dataDisplayItems}/>

                <ModalActionGroup type={ActionGroupType.Together} buttons={{

                    leftButton: <CancelButton onClick={()=>{
                        closeModal(modalId)
                    }}></CancelButton>,
                    rightButton: <ConfirmButton onClick={()=>{

                        const requestData: ICreateAttributeValueRequest = {
                            value: data.value, //the new value that the user wants to create
                            projectId: data.projectId, //associated projectId
                            parentId: data.parentId, //id of parent attributeValue
                            description: description,
                            attributeId: data.attribute._id
                        }

                        setIsLoading(true)
                        const request = dispatch(createAttributeValueThunk(requestData))

                        request.then((action: any)=> {


                            const requestResultModalId = nanoid(4)
                            setIsLoading(false)
                            if (createAttributeValueThunk.fulfilled.match(action)) {

                                // open success message
                                openConfirmModal({
                                    modalId: requestResultModalId,
                                    cancelProps: {style: {display: "none"}},
                                    onConfirm() {
                                        closeModal(modalId)
                                    },
                                    title: <ConfirmModalHeader type={ConfirmType.Success}
                                                               title={"Wert erfolgreich erstellt"}
                                                               message={`"${data.value}" wurde erfolgreich erstellt und kann nun beim Attribut "${data.attribute.label}" verwendet werden`}/>
                                })
                                return;

                            } else {

                                // check if aborted
                                if (isAbortError(action.error)) {
                                    return
                                }
                            }

                            openConfirmModal({
                                modalId: requestResultModalId,
                                cancelProps: {style: {display: "none"}},
                                onConfirm() {
                                    closeModal(modalId)
                                },
                                title: <ConfirmModalHeader type={ConfirmType.Error} title={"Fehler bei Erstellung"}
                                                           message={"Das Attribut konnte nicht erstellt werden. Bitte noch einmal probieren."}/>
                            })
                        })


                        }}>Erstellen</ConfirmButton>

                }}/>

            </div>
        )

}





















