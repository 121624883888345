import {ActionIcon, Text, createStyles, Tooltip} from "@mantine/core";
import {DEFAULT_STYLES} from "../themeStyles";
import {closeModal} from "@mantine/modals";
import {IconX} from "@tabler/icons";
import React, {FC, ReactNode} from "react";

export function ModalHeader({title, subtitle, modalId,closeCallback,hideBorder}: {hideBorder?: boolean,title: string, subtitle: string | undefined | ReactNode, closeCallback?: ()=>void, modalId: any}) {
    const {classes} = createStyles((theme, _params) => ({

        group: {
            width: "100%",
            display: "flex",
            paddingTop: 8,
            marginBottom: 40,
            paddingBottom: 22,
            borderBottom: hideBorder ? "none" : `1.5px solid ${theme.colors.gray[2]}`
        }

    }))()

    return (
        <div className={classes.group}>
            <div>
                <div>
                    <MainHeader>{title}</MainHeader>
                    <div style={{
                        marginTop: 0
                    }}>
                        {(typeof subtitle === "string" && subtitle.length > 0) ? (<ModalSubHeader>{subtitle}</ModalSubHeader>) : (typeof subtitle === "undefined" ? null : subtitle) }
                    </div>
                </div>
            </div>
            <div style={{
                marginLeft: "auto"
            }}>
                <Tooltip openDelay={400} withArrow position="bottom-end" label="schließen">
                    <ActionIcon variant="outline" onClick={()=>{
                        if (closeCallback){
                            closeCallback()
                            console.log("called callback")
                            return
                        }
                        closeModal(modalId)

                    }
                    }>
                        <IconX size={22}  />
                    </ActionIcon>
                </Tooltip>
            </div>
        </div>
    );
}


function MainHeader({children}: {children: any}) {
    const {classes} = createStyles((theme, _params) => ({
        title: {
            color: DEFAULT_STYLES.headerColor,
            fontSize: 29,
            fontStyle: "bold"
        },
    }))()
    return (<>
            <h2 className={classes.title}>{children}</h2>
        </>
    );
}

export const modalSubHeaderStyles = createStyles((theme, {color}: {color: string | undefined}) => ({ //styles used for the standard ModalSubheader

    subHeader: {
        color: typeof color === "string" ? color : DEFAULT_STYLES.modalSubHeaderColor,
        fontWeight: 500,
        fontSize: 15,
        margin: 0
    }
}));

export function ModalSubHeader({color, children}: { color?: string, children: any}) {


    return (
        <Text weight={500} color={typeof color === "string" ? color : DEFAULT_STYLES.modalSubHeaderColor} >{children}</Text>
    );
}
