import {Loader} from "@mantine/core";

export function ComponentLoader(props: {size?: number}) {


    let size = 50
    if (props.size){
        size = props.size
    }
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Loader size={size} variant="dots" />
            </div>
    );
}
