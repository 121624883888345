import {AuthContainer} from "../../common/auth/AuthContainer";
import {Button, createStyles, PasswordInput, TextInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {At, Lock} from 'tabler-icons-react';
import {AuthNavigation} from "../../components/auth/AuthNavigation";
import {closeAllModals, openConfirmModal, openModal} from '@mantine/modals';
import {INPUT_ICON_SIZE} from "../../common/global/themeStyles";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {loginUser} from "../../redux/slices/auth/userSlice";
import {LoadingStatus} from "../../redux/util";
import {ERROR_TEXTS, getErrorText} from "../../texts/apiErrors";
import {UI_TEXTS} from "../../texts/ui";
import {isPasswordValid} from "./Register";
import {userSelector} from "../../redux/rootReducer";
import {ConfirmModalHeader, ConfirmType} from "../../common/global/modals/ConfirmModal";
import {handleHelpClick} from "../../common/auth/HelpButton";
const validator = require('validator');

export const Login = () => {
	return (
		<div>
			<AuthNavigation/>
			<AuthContainer  showReturnButton={false}  title={"Anmelden"} subTitle={"Bitte melde dich mit deinem Docul Konto an."} >
				<ContainerContent/>
			</AuthContainer>
		</div>
	)
}

function ContainerContent() {

	const {classes} = useStyles()

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const navigate = useNavigate();
	const dispatch = useAppDispatch()
	const user = useAppSelector(userSelector)

	return (
		<div>
			<div className={classes.inputSection}>
				<TextInput error={emailError} value={email} onChange={(event) => {
					setEmail(event.currentTarget.value)
				}} required label="E-Mail Adresse" placeholder="E-Mail eingeben" icon={<At size={INPUT_ICON_SIZE} />} />
				<PasswordInput error={passwordError} value={password} onChange={(event) => {
					setPassword(event.currentTarget.value)
				}} required label="Password" placeholder="Passwort eingeben" icon={<Lock size={INPUT_ICON_SIZE} />}/>
			</div>

			<div className={classes.buttonGroup}>
				<div className={classes.buttonGroup_rightSection}>
					<Button loading={(user.loading === LoadingStatus.pending)} variant="filled" onClick={() => {

						let canPerformLogin = true
						//checking email
							if (!validator.isEmail(email)){
								setEmailError(UI_TEXTS.auth.login.invalidEmail)
								canPerformLogin = false
							}else{
								setEmailError("")
							}

						//checking password
						if (!isPasswordValid(password)){
							setPasswordError(UI_TEXTS.auth.login.invalidPassword)
							canPerformLogin = false
						}else{
							setPasswordError("")
						}

						if (!canPerformLogin){
							return
						}

						const promise = dispatch(loginUser({email: email, password: password}))
						//a failed request or error in a thunk will never return a rejected promise
						promise.then((action) => {

							if (loginUser.fulfilled.match(action)){
								navigate("/");
							}else{
								const errorText = getErrorText(ERROR_TEXTS.public.auth.login, String(action.error.code))!

								openConfirmModal({
									cancelProps : { variant: "outline"},
									closeOnClickOutside: true,
									onCancel: () =>{
										handleHelpClick()
									},
									onConfirm:()=> {
										closeAllModals()
									},
									labels: {cancel: UI_TEXTS.auth.resetPassword.modalHelpTitle, confirm: "Weiter" },
									title: <ConfirmModalHeader type={ConfirmType.Error} title={errorText.title} message={errorText.message} />
								})
							}
						})

					}
					}>Anmelden</Button>
				</div>


			</div>

			<div className={classes.buttonGroup_footer}>
				<div className={classes.buttonGroup_footer_right}>

					<Button variant="subtle" onClick={() => {
						navigate("/auth/resetPassword");
					}}>Passwort zurücksetzen</Button>
				</div>


			</div>

		</div>
	);
}


const useStyles = createStyles((theme, _params) => ({

	inputSection: {
		width: "100%",
		marginBottom: 32,
		display: "flex",
		flexDirection: "column",
		gap: 25
	},

	buttonGroup: {
		width: "100%",
		display: "flex",
		marginBottom: 150
	},


	buttonGroup_rightSection: {
		marginLeft: "auto"
	},

	buttonGroup_footer: {
		width: "100%",
		display: "flex",
		justifySelf: "flex-end"
	},

	buttonGroup_footer_right: {
		marginLeft: "auto",
		marginRight:-12
	},

}));

export default Login

