import { createStyles } from '@mantine/core'
import React, { Fragment, PropsWithChildren, useEffect } from 'react'
import {Outlet, useMatch, useNavigate} from 'react-router-dom'
import {DEFAULT_STYLES} from "../../common/global/themeStyles";
import {Footer} from "../../components/main/Footer";
import {useSelector} from "react-redux";
import {resetState, userSelector} from "../../redux/rootReducer";
import {useAppDispatch} from "../../redux/hooks";


const MainRoot = () => {

	const {classes} = useStyles()
	const navigate = useNavigate();
	const isMainRoute = useMatch("/auth");

	const dispatch = useAppDispatch()
	const userState = (useSelector(userSelector))


	//NOTE: reroute empty path
	useEffect(() => {
		if (userState.data){
			navigate("/")
			return
		}else{
			dispatch(resetState())
		}
		if (isMainRoute){
			navigate("/auth/login")
			return
		}

	}, []);

	return (
		<div className={classes.layout}>
			<div style={{width:"100%", minHeight:"100vh"}}>
				<Content>
					<Outlet />
				</Content>
			</div>
			<Footer />

		</div>
			
	)
}

const Content = (props: PropsWithChildren) => {
	return (
		<Fragment>
			{props.children}
		</Fragment>
	)
}

const useStyles = createStyles(theme => ({
	layout: {
		backgroundColor: DEFAULT_STYLES.defaultBackgroundColor,
		marginLeft: "auto",
		marginRight: "auto",
		width: '100%',
		boxSizing: 'border-box'
	},
}))

export default MainRoot