import {createAsyncThunk} from "@reduxjs/toolkit"
import {API_ENDPOINTS, axiosInstance} from "../../apiConfigs";

export interface IRegisterData {
	email: string, password: string, firstName: string, lastName: string
}

export const registerNewUser = createAsyncThunk(
	'register/start',
	async (data: IRegisterData, thunkAPI) => {
		const res = await axiosInstance.post(API_ENDPOINTS.public.auth.register, data)
		return res.data
	}
)

export interface IVerifyNewUserData {
	userId: string, verificationCode: string
}
export const verifyNewUser = createAsyncThunk(
	'register/verifyNewUser',
	async (data: IVerifyNewUserData) => {
		const res = await axiosInstance.get(API_ENDPOINTS.public.auth.verifyUser+"?"+"userId="+data.userId+"&"+"verificationCode="+data.verificationCode)
		return res.data
	}
)
