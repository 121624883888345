import {createAsyncThunk} from "@reduxjs/toolkit"
import {API_ENDPOINTS, axiosInstance} from "../../../../apiConfigs";

export class AttributeValue {


	static RULES = ()=>{
		return {
			VALUE : {
				MIN_LENGTH: 1,
				MAX_LENGTH : 50
			},
			DESCRIPTION: {
				MIN_LENGTH: 0,
				MAX_LENGTH : 85
			}
		}
	};
	_id: string | undefined;
	attributeId: string; // foreignKey of an Attribute
	parentId: string | null; //foreignKey of an AttributeValue
	value: string; // maximum of 100, minimum of 1 characters - value must be unique - will be formatted, that is trimmed.
	isActive: boolean;
	projectId?: string; //
	description: string | null; // describes the attribute value.
	timestamp?: Date; //will be added on bulkAdds

	constructor(attributeId: string, parentId: string |null, value: string, isActive: boolean, timestamp: Date, projectId: string, description: string |null, _id?: string) {

		this._id = _id
		this.attributeId = attributeId
		this.parentId = parentId
		this.value = value
		this.isActive = isActive
		this.timestamp = timestamp
		this.projectId = projectId
		this.description = description
	}
}

interface GetAttributeValueResponse {
	attributeValues: AttributeValue[] // a list of attributeValues
}

export const getAttributeValue = createAsyncThunk(
	'getAttributeValue/start',
	async (data: { projectId: string, parentId: string, attributeId: string}, thunkAPI) => {
		const res = await axiosInstance.post(API_ENDPOINTS.private.attributeValue.getAll, data)
		return res.data as GetAttributeValueResponse
	}
)

