import {createStyles} from "@mantine/core";
import {DEFAULT_STYLES} from "../themeStyles";

export function MainHeader({children}: {children: any}) {

    const { classes } = useStyles();

    return (
        <h1 className={classes.header}>{children}</h1>
    );
}

const useStyles = createStyles((theme) => ({

    header: {
        color: DEFAULT_STYLES.headerColor,
        fontStyle: "bold",
        margin: 0,
        fontSize: 36 //37.5 38.5
    }

}));
