//NOTE Tutorials on configuring Redux Toolkit redux
//LINK https://bluelight.co/blog/redux-toolkit-with-typescript
//LINK https://dev.to/raaynaldo/redux-toolkit-setup-tutorial-5fjf

import {configureStore, createAction} from "@reduxjs/toolkit";
import rootReducer, {AppState} from "./rootReducer";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistReducer,FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER } from "redux-persist";
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import {injectStoreToAPI} from "./apiConfigs";


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


const persistConfig = {
	key: "root",
	storage,
	stateReconciler: autoMergeLevel1, //default: https://www.npmjs.com/package/redux-persist#state-reconciler
	blacklist: ["projects", "configureFilter", "docNumList"] // part of the state, that will be omitted
};

// redux state is saved to persisted storage whenever it changes
const persistedReducer = persistReducer<AppState>(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		})
})

export const getAppStore = ()=> {
	return store
}
export type StoreType = typeof store

injectStoreToAPI(store)