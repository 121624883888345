import React, {useEffect, useState} from 'react';
import {ActionIcon, Badge, Button, createStyles, Menu, Tooltip, Text} from "@mantine/core";
import {IconMenu2} from "@tabler/icons";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {projectsSelector, sessionConfigSelector, sidebarSelector} from "../../redux/rootReducer";
import {setSlidebar} from "../../redux/slices/sidebarSlice";
import {ChevronDown} from "tabler-icons-react";
import {DEFAULT_STYLES} from "../../common/global/themeStyles";
import {nanoid} from "nanoid";
import {useSelector} from "react-redux";
import {changeActiveProject, getAllProjects} from "../../redux/slices/auth/projectsSlice";
import {closeAllModals, openConfirmModal} from "@mantine/modals";
import {UI_TEXTS} from "../../texts/ui";
import {useIsMounted} from "../../hooks/useIsMounted";
import {ConfirmModalHeader, ConfirmType} from "../../common/global/modals/ConfirmModal";
import {updateUserData} from "../../redux/slices/auth/userSlice";
import Description from "../../common/global/Description";
import {Filter, setFilter} from "../../redux/slices/main/documents/get/filterDocNumSlice";
import {setSessionConfigProject} from "../../redux/slices/main/sessionConfig";

function AppNavigationBar() {

    const {classes} = useStyles()
    const sidebarState = useAppSelector(sidebarSelector);
    const dispatch = useAppDispatch()

    return (
        <div className={classes.container}>
            <div>
                {
                    !(sidebarState.open) &&
                    <Tooltip withArrow openDelay={400} label="Menü öffnen">
                        <ActionIcon onClick={()=>{
                            dispatch(setSlidebar(true))
                        }
                        } variant="outline">
                            <IconMenu2 size={22}  />
                        </ActionIcon>
                    </Tooltip>
                }
            </div>
            <div style={{marginLeft: "auto"}}>
                <ProjectSelector/>
            </div>
        </div>
    );
}


function ProjectSelector() {

    const [isProjectTooltipOpen, setProjectToolTipOpen] = useState(false);
    const [isSelectProjectMenuOpen, setSelectProjectMenuOpen] = useState(false)
    const projectsState = useSelector(projectsSelector)
    const dispatch = useAppDispatch()
    const {isMounted} = useIsMounted()
    const {allProjects, activeProject} = projectsState
    const sessionConfig = useSelector(sessionConfigSelector)

    const {classes} = createStyles((theme, _params) => ({

        itemContainer: {

            display: "flex",
            flexDirection: "column",
            gap: 1
        },

    }))()

    //todo add project loading indicator
    useEffect(() => {

        if (allProjects === null){
            const promise = dispatch(getAllProjects())
            promise.then((action)=> {

                if (getAllProjects.fulfilled.match(action)){
                    // got the projects.
                    // check if there is an activeProject
                    if (!activeProject ){
                        if (action.payload.allProjects.length > 0){

                            let lastFoundActiveProject
                            if (sessionConfig.lastActiveProject){
                                lastFoundActiveProject = action.payload.allProjects.find((project) => {
                                    return project._id === sessionConfig.lastActiveProject?._id
                                })
                            }
                            if (lastFoundActiveProject){
                                dispatch(changeActiveProject(lastFoundActiveProject))
                            }else{
                                dispatch(changeActiveProject(action.payload.allProjects[0]))
                            }
                        }
                    }
                    dispatch(updateUserData(action.payload.userData))
                }else{
                    if(!isMounted) return


                    openConfirmModal({
                        cancelProps: { variant: "outline"},
                        onCancel() {
                            closeAllModals()
                        },
                        onConfirm() {
                            window.location.reload();
                        },
                        labels: {cancel: "Schließen", confirm: UI_TEXTS.main.global.projects.getAllProjectsErrorTryAgain},
                        title: <ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.main.global.projects.getAllProjectsErrorTitle} message={UI_TEXTS.main.global.projects.getAllProjectsErrorDescription} />
                    })

                }
            })
        }
    }, []);

    // when there are no projects, show nothing
    if (allProjects === null || allProjects.length < 1 ||  activeProject === null){

        return null
    }

    // we have projects and activeProject
    return (<div>

            <Menu opened={isSelectProjectMenuOpen} onOpen={() => setSelectProjectMenuOpen(true)}
                  onClose={() => setSelectProjectMenuOpen(false)}
                  position="bottom-end"
                  transition={DEFAULT_STYLES.transition} transitionDuration={DEFAULT_STYLES.transitionDuration}
                  withArrow
                  width={250}
            >

            <Menu.Target>
                <Tooltip
                    opened={isProjectTooltipOpen}
                    label="Projekt wechseln"
                    openDelay={2000}
                    withArrow
                >
                    <Button onMouseLeave={() => setProjectToolTipOpen(false)}
                            onMouseOver={() => {
                                if (!isSelectProjectMenuOpen) {
                                    setProjectToolTipOpen(true)
                                } else {
                                    setProjectToolTipOpen(false)
                                }}}
                            onClick={() => {
                                setProjectToolTipOpen(!isProjectTooltipOpen)
                                setSelectProjectMenuOpen(!isSelectProjectMenuOpen)
                            }}
                            variant="default" rightIcon={<ChevronDown size={20} color={DEFAULT_STYLES.defaultIconColor}/>} >{activeProject.label}</Button>
                </Tooltip>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Projekte verwalten</Menu.Label>
                {(() => {


                    // Returning menu Items.
                    return allProjects!.map((project) => {


                        let isActiveProject: boolean = project._id === activeProject!._id

                        const onClickHandler = () => {
                            if (isActiveProject) {
                                return
                            } else {

                                dispatch(changeActiveProject(project))
                                // also reset filter when project changes
                                dispatch(setFilter({}))
                                dispatch(setSessionConfigProject(project))

                            }
                        }

                        if (isActiveProject) {
                            //active project, show badge
                            return (
                                <Menu.Item key={nanoid()}
                                           rightSection={<Badge style={{marginLeft: 10}} size="sm" radius={10}
                                                                color="lime"
                                                                variant="filled">aktiv</Badge>
                                           }
                                           onClick={onClickHandler}
                                >
                                    {
                                        <div className={classes.itemContainer}>
                                            <Text>{project.label}</Text>
                                            <Description>{project.projectName}</Description>
                                        </div>
                                    }
                                </Menu.Item>
                            )
                        } else {
                            return (
                                <Menu.Item key={nanoid()}
                                           onClick={onClickHandler}
                                >
                                    {
                                        <div className={classes.itemContainer}>
                                            <Text>{project.label}</Text>
                                            <Description>{project.projectName}</Description>
                                        </div>
                                    }
                                </Menu.Item>
                            )
                        }

                    })
                })()}
            </Menu.Dropdown>
        </Menu>

        </div>)

}



const useStyles = createStyles(theme => ({

    container: {
        backgroundColor:"white",
        width: "100%",
        height: 83,
        padding: 26,
        display:"flex"
    }
}))

export default AppNavigationBar;