import React, {useEffect, useState} from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    ActionIcon, Pagination, Tooltip,
    Badge
} from '@mantine/core';
import { keys } from '@mantine/utils';
import {IconSelector, IconChevronDown, IconChevronUp} from '@tabler/icons';
import {DEFAULT_STYLES} from "../../../common/global/themeStyles";
import {SmallLabelStyle} from "../../../common/global/headers/SmallLabel";
import {
    GetDocNumRequest,
    GetDocNumResponse,
    getDocNumThunk
} from "../../../redux/asyncThunks/main/documents/listDocumentsThunks";
import {isAbortError} from "../../../texts/apiErrors";
import {useAppDispatch} from "../../../redux/hooks";
import {useSelector} from "react-redux";
import {docNumFilterSelector, projectsSelector} from "../../../redux/rootReducer";
import {ComponentLoader} from "../../../common/global/ComponentLoader";
import {setRowData} from "../../../redux/slices/main/documents/get/docNumListSlice";
import {openModal} from "@mantine/modals";
import {ManageDocumentModal} from "./ManageDocument";
import {useFirstRender} from "../../../hooks/useFirstRender";

// Configurations
const LIST_DOCUMENTS_SETTINGS = {
    TEST_MODE: false,
    MAX_PAGE_ROWS: 50
}

interface TableHeaderProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}
function TableHeader({ children, reversed, sorted, onSort }: TableHeaderProps) {

    const { classes } = createStyles((theme) => ({
        th: {
            padding: '0 !important',
        },

        header: {
            fontSize: theme.fontSizes.sm,
            color: SmallLabelStyle.color,
            fontWeight: 600
        },
        control: {
            width: '100%',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 5,
            borderTopRightRadius: theme.radius.sm,
            borderTopLeftRadius: theme.radius.sm,
            transition: "all 0.2s ease 0s",
            height: 55,
            '&:hover': {
                backgroundColor:DEFAULT_STYLES.defaultFocusBackground,
            },
        },

        icon: {
            width: 21,
            height: 21,
            borderRadius: 21,
        },
    }))();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;

    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart">
                    <Text className={classes.header}>
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}

export interface RowData {
    name: string;
    docNumId: string;
    creatorName: string;
    dateString: string;
    dateTime: number; //dateTime
    activeState: "aktiv" | "inaktiv";
}

function filterData(data: RowData[], search: string) {
    const query = search.toLowerCase().trim();
    //search for "aktiv" does not work
    return data.filter((item) =>
        keys(data[0]).some((key) => {
            if (key ==="dateTime"){
                return false
            }
            return item[key].toLowerCase().includes(query);
        })
    );
}




async function sortData( data: RowData[], payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
) {

    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    if (sortBy ==="dateTime"){

        return filterData([...data].sort((a,b)=> {

            if (payload.reversed) {

                return a.dateTime - b.dateTime;
            }

            return b.dateTime - a.dateTime
        }), payload.search)

    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

async function simulateRowData(rows: number): Promise<RowData[]>{

    const rowData: any[] = []
    for (let i = 0; i < rows; i++) {
        const newRow: RowData  = {
            name: String(i) +"-Name",
            docNumId: String(i) + "index",
            creatorName: String(i) + "-Creator",
            dateString:  String(i) + ".03.2020",
            dateTime: 1,
            activeState: "aktiv"
        }
        rowData.push(newRow)
    }
    return rowData
}


function UiRow({row} : {row: RowData}) {

    const { classes } = createStyles((theme) => ({

        tr: {
            '&:hover': {
                cursor: "pointer"
            },
        }

    }))();

    return (<Tooltip
            label="Dokument verwalten"
            withArrow
            offset={0}
            openDelay={500}
            position="bottom-start"
            >
                <tr className={classes.tr} onClick={()=>{
                    openModal(ManageDocumentModal(row.docNumId));
                }} key={"1" + row.docNumId}>
                    <td>{ <div> {row.name}{ row.activeState === "inaktiv" ? <Badge variant="light" color="red" style={{marginLeft: 8}}>inaktiv</Badge> : <></> }</div>}</td>
                    <td>{row.creatorName}</td>
                    <td>{row.dateString}</td>
                </tr>

            </Tooltip>)
}

async function createUiRows(activePage: number, maxPageRows: number, sortedRows: RowData[] | null | undefined){

    if (!sortedRows){
        return []
    }

    const newUiRows = []
    let startIndex
    if (activePage === 0 || activePage === 1){
        startIndex = 0
    }else{
        startIndex = (((activePage -1) * maxPageRows))
    }
    //                 <td> <ActionIcon size={30} variant="subtle"><Dots width={20} height={20}/> </ActionIcon> </td>
    for (let i = 0; i < maxPageRows; i++) {
        const row = sortedRows[i + startIndex]
        if (row){
            newUiRows.push(<UiRow key={row.docNumId} row={row}/>)
        }
    }
    return newUiRows
}


export function ListDocumentsLoader() {
    return (
        <div style={{
            height: 700,
            width: "100%"
        }}>
            <ComponentLoader />
        </div>
    );
}
export function ListDocuments({projectId, changeTableRowsLength} : {projectId: string, changeTableRowsLength: (newLength: number | null)=>void}) {

    const { classes } = createStyles((theme) => ({

        paginationContainer: {
            width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 48
        },
        statsContainer: {
            display:"flex",  justifyContent:"flex-end"
        },
        statsLabel: {
            fontSize: 13, fontWeight: 400, color: SmallLabelStyle.color
        },
        stats: {
            fontWeight: 400,
            fontSize: 13, marginLeft: 6
        },
        noDocsAvailable: {
            marginTop: 40,
            marginBottom: 220,
            display:"flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 27
        },
        resultHeader: {
            color: DEFAULT_STYLES.headerColor,
            marginTop: 16,
            marginBottom: 8,
        }

    }))()
    const setSorting = (field: keyof RowData) => {

        if (!allRows){ //NOTE: changed from sortedRows to allRows
            return
        }
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);

        setLoading(true)
        sortData(allRows, { sortBy: field, reversed, search: docNumFilter.searchValue }).then((newSortedData) => setSortedRows(newSortedData) ).finally(()=> {
            setLoading(false)
        })
    }

    const [activePage, setPage] = useState(1);
    const [pageNumbers, setPageNumber] = useState(0);
    const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [uiRows, setUiRows] = useState<any[] |null>(null);
    const [sortedRows, setSortedRows] = useState<null | RowData[]>(null);
    const [allRows, setAllRows] = useState<null | RowData[]>(null);
    const [getDocNumResponse, setGetDocNumResponse] = useState<null | GetDocNumResponse>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const docNumFilter = useSelector(docNumFilterSelector)
    const [didMount, setDidMount] = useState(false)
    const projectsState = useSelector(projectsSelector)


    useEffect(() => { setDidMount(true) }, [])

    useEffect(() => {
        setSorting("dateTime")
    }, [allRows]);


    // building page on new sortedRows
    useEffect(() => {

        if (sortedRows?.length){
            let newPageNumbers = Math.ceil(sortedRows.length / LIST_DOCUMENTS_SETTINGS.MAX_PAGE_ROWS)
            setPage(1)
            setPageNumber(newPageNumbers)

            // now we push the new data to the state
            dispatch(setRowData(sortedRows))


            //delegate sortedRows number to the header
            changeTableRowsLength(sortedRows.length)
        }else{
            changeTableRowsLength(null)
            dispatch(setRowData([]))
        }


    }, [sortedRows]);


    // new sortedRows when docNumFilter changes
    useEffect(() => {

        // checking if the dataset has changed
        if ((docNumFilter.filter.filterId) && (getDocNumResponse) && (docNumFilter.filter.filterId !== getDocNumResponse?.filter.filterId)){
            // check if filterId is the same as in the getDocNumResponse
            setGetDocNumResponse(null)
            return
        }

        if (allRows){
            setLoading(true)
            sortData(allRows, { sortBy, reversed: reverseSortDirection, search: docNumFilter.searchValue }).then((newSortedData) => {

                setSortedRows(newSortedData)
            } ).finally(()=> {
                setLoading(false)
            })
        }

    }, [docNumFilter]);

    /*
         <th style={{
                        padding: 0,
                        alignContent: "center",
                        width: 70
                    }} >

                    </th>
     */
    // fetching data
    // todo on attribute change
    // todo prettify no documents maybe
    useEffect(() => {

        if (!getDocNumResponse){

            setLoading(true)
            const requestModel: GetDocNumRequest = {
                projectId: projectId,
                filter: docNumFilter.filter
            }

            const request = dispatch(getDocNumThunk(requestModel))
            request.then((action)=> {

                if (getDocNumThunk.fulfilled.match(action)){

                    if (action.payload){
                        setGetDocNumResponse(action.payload)
                        const rowData: RowData[] = action.payload.data.map((docNumQuery) => {

                            let creatorName = null
                            let date = null
                            let dateString = null

                            if (docNumQuery.creator){
                                creatorName =  docNumQuery.creator.firstName + " " + docNumQuery.creator.lastName
                            }

                            if (docNumQuery.timestamp){
                                date = new Date (docNumQuery.timestamp)
                                dateString = date.toLocaleString("de-DE", {
                                    timeZone: 'Europe/Berlin',
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: '2-digit',
                                    hour: 'numeric', minute: 'numeric'  })
                            }

                            if (!creatorName){
                                creatorName = "n/a"
                            }
                            if (!dateString){
                                dateString = "n/a"
                            }

                            const newRow: RowData = {
                                docNumId: docNumQuery._id,
                                name: docNumQuery.value,
                                creatorName: creatorName,
                                dateString: dateString,
                                dateTime: docNumQuery.timestamp ? new Date (docNumQuery.timestamp).getTime() : new Date().getTime(),
                                activeState: docNumQuery.isActive ? "aktiv" : "inaktiv"
                            }
                            return newRow
                        })

                        if (LIST_DOCUMENTS_SETTINGS.TEST_MODE){

                            simulateRowData(200000).then((simulatedRowData)=>{
                                setSortedRows(simulatedRowData)
                                setAllRows(simulatedRowData)
                                setError(false)
                                setError(false)
                                setLoading(false)
                            })

                            return;
                        }
                        setSortedRows(rowData)
                        setAllRows(rowData)

                        setError(false)
                    }else{
                        setError(true)
                    }

                }else{

                    // check if aborted
                    if (isAbortError(action.error)){
                        return
                    }
                    setError(true)
                    // todo handle error

                }
                setLoading(false)
            })

            return () => {
                // `createAsyncThunk` attaches an `abort()` method to the promise
                request.abort()
            }
        }

    }, [getDocNumResponse, projectId]);


    // building ui rows on activePage and sortedRows change
    useEffect(() => {

        if (!didMount) {return}

        setUiRows(null)
        setLoading(true)
        // starting index
        createUiRows(activePage, LIST_DOCUMENTS_SETTINGS.MAX_PAGE_ROWS, sortedRows).then((newUiRows) => {
            setUiRows(newUiRows)
        }).catch(()=>{
            setUiRows([])
        }).finally(()=> {
            setLoading(false)
        })

    }, [activePage, sortedRows]);

    // showing loading if needed
    if (loading || !uiRows) {
        return <ListDocumentsLoader/>
    }

    // showing error if needed
    if (error){
        return <div style={{
            height: 400,
            width: "100%"
        }} >
            Error
        </div>
    }


    const renderStatistics = (getDocNumResponse  && sortedRows && sortedRows.length && getDocNumResponse.data && activePage && (activePage === 1))
    const renderPagination = (pageNumbers && sortedRows && sortedRows.length)

    


    return (
        <div style={{paddingLeft: 0, paddingRight: 0}}>

            {(sortedRows) && (sortedRows?.length !== null) && (sortedRows.length > 0) ? (<Text weight={600} className={classes.resultHeader}> { Intl.NumberFormat('de-DE').format(sortedRows.length)} Dokumente verfügbar</Text>) : <></> }
            <div style={{height: "100%",paddingLeft: 0, paddingRight: 0}}>
                <Table
                    highlightOnHover
                    striped
                    horizontalSpacing="md"
                    verticalSpacing={15}
                    sx={{ tableLayout: 'fixed', minWidth: 700 }}
                >
                    <thead>
                    <tr>

                        <TableHeader
                            sorted={sortBy === 'name'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('name')}
                        >
                            Name
                        </TableHeader>
                        <TableHeader
                            sorted={sortBy === 'creatorName'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('creatorName')}
                        >
                            Ersteller
                        </TableHeader>
                        <TableHeader
                            sorted={sortBy === 'dateTime'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('dateTime')}
                        >
                            Datum
                        </TableHeader>

                    </tr>
                    </thead>
                    <tbody>
                    {uiRows.length > 0 ? (
                        uiRows
                    ) : (
                        <tr>
                            <td colSpan={3}>
                                <div className={classes.noDocsAvailable}>
                                    <img src='./illustrations/main/documents/DocumentNotFound.svg' width={110}/>
                                    <Text weight={500} size={18}>{"Keine Dokumente im Projekt \""+ projectsState.activeProject?.projectName+"\" gefunden"}</Text>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

            <div>

            {renderPagination ?  (<div className={classes.paginationContainer}>

                    <div style={{display:"flex", marginRight: 50, alignItems: "center"}}>
                        <Text weight={500} color={SmallLabelStyle.color} style={{marginRight: 8}} >Seite:</Text>
                        <Text weight={500}>{activePage} von {pageNumbers}</Text>
                    </div>

                <Pagination siblings={1} boundaries={2} styles={{item: {
                        backgroundColor: "transparent",
                        '&[data-active]': {
                            color: DEFAULT_STYLES.activationFontColor,
                            backgroundColor: DEFAULT_STYLES.activationColor,
                        },
                    }}} page={activePage} onChange={setPage} total={pageNumbers} />

            </div>) : <></> }

                { renderStatistics  ?  (<div style={{marginTop: 140, width: "100%"}}>

                    <div className={classes.statsContainer}>
                        <Text className={classes.statsLabel}>Abfragendauer:</Text>
                        <Text className={classes.stats}>{getDocNumResponse!.queryDuration}</Text>
                    </div>
                    <div className={classes.statsContainer}>
                        <Text className={classes.statsLabel}>Datenabruf um</Text>
                        <Text className={classes.stats}>{new Date (getDocNumResponse!.timestamp).toLocaleString("de-DE", {
                            timeZone: 'Europe/Berlin',
                            hour: 'numeric', minute: 'numeric', second: "numeric"  }) } Uhr</Text>
                    </div>
                    <div style={{display: "none"}} className={classes.statsContainer}>
                        <Text className={classes.statsLabel} >Dokumente abgerufen:</Text>
                        <Text className={classes.stats}>{Intl.NumberFormat('de-DE').format(getDocNumResponse!.data!.length)}</Text>
                    </div>
                </div> ): <></>
                }

            </div>

        </div>
    );
}


