import React from 'react';
import {Button, CopyButton} from "@mantine/core";
import {IconCheck, IconCopy} from "@tabler/icons";
import {DEFAULT_STYLES} from "../themeStyles";

export function CopyValueButton(props: {value: string, beforeCopyValue: string, afterCopyValue: string}) {
    return (
        <CopyButton value={props.value}>
            {({ copied, copy }) => (
                <Button style={{marginTop: 0}} styles={{root: { "&:hover": {backgroundColor: copied? DEFAULT_STYLES.activationColor: undefined}, color: (copied? DEFAULT_STYLES.activationFontColor : "black") , backgroundColor: copied? DEFAULT_STYLES.activationColor : "transparent"}}} variant="outline" leftIcon={copied ? (<IconCheck size={19} color={DEFAULT_STYLES.activationFontColor} />) : (<IconCopy size={19} color={DEFAULT_STYLES.defaultSubIconColor}/>)} color={copied ? DEFAULT_STYLES.activationFontColor : undefined} onClick={copy}>
                    {copied ? props.afterCopyValue : props.beforeCopyValue}
                </Button>
            )}
        </CopyButton>
    );
}
