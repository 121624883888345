import {CombinedState, combineReducers, createAction, Reducer} from "@reduxjs/toolkit";
import userReducer, {initialUserState, IUserState} from "./slices/auth/userSlice";
import {RootState} from "./store";
import sidebarReducer, {initialSidebarState, ISidebarState} from "./slices/sidebarSlice";
import projectsReducer, {initialProjectState, IProjectsState} from "./slices/auth/projectsSlice";
import {API_ENDPOINTS, axiosInstance} from "./apiConfigs";
import createDocNumReducer, {
	ICreateDocNumData,
	initialCreateDocNumDataState
} from "./slices/main/documents/create/createDocNumDataSlice";
import docNumFilterReducer, {IFilterDocNumState, initialFilterDocNumSate} from "./slices/main/documents/get/filterDocNumSlice";
import configureFilterReducer, {IConfigureFilterState, initialConfigureFilterState} from "./slices/main/documents/get/configureFilterSlice";
import docNumListReducer, {IDocNumListState, initialDocNumList} from "./slices/main/documents/get/docNumListSlice";
import sessionConfigReducer, {initialSessionConfig, ISessionConfig} from "./slices/main/sessionConfig";


export interface AppState {
	user: IUserState,
	sidebar: ISidebarState,
	projects: IProjectsState,
	createDocNumData: ICreateDocNumData,
	docNumFilter: IFilterDocNumState,
	configureFilter: IConfigureFilterState,
	docNumList: IDocNumListState,
	sessionConfig: ISessionConfig
}

const initialAppState: AppState = {
	user: initialUserState,
	sidebar: initialSidebarState,
	projects: initialProjectState,
	createDocNumData: initialCreateDocNumDataState,
	docNumFilter: initialFilterDocNumSate,
	configureFilter: initialConfigureFilterState,
	docNumList: initialDocNumList,
	sessionConfig: initialSessionConfig
}

export const resetState = createAction<undefined>('resetState')

const combinedReducer = combineReducers<AppState>({
	user: userReducer,
	sidebar: sidebarReducer,
	projects: projectsReducer,
	createDocNumData: createDocNumReducer,
	docNumFilter: docNumFilterReducer,
	configureFilter: configureFilterReducer,
	docNumList: docNumListReducer,
	sessionConfig: sessionConfigReducer
})

const RootReducer = (state: any, action: any) => {
	if (action.type === resetState.type) { // check for action type
		state = {...initialAppState};
		axiosInstance.get(API_ENDPOINTS.public.auth.logout).finally() //ignoring promise
	}
	return combinedReducer(state, action);
};
export default RootReducer as unknown as Reducer<CombinedState<AppState>>



// SELECTORS
// global

// main
export const sidebarSelector = (state: RootState) => state.sidebar;
export const sessionConfigSelector = (state: RootState) => state.sessionConfig;

// ../documents
export const createDocNumDataSelector = (state: RootState) => state.createDocNumData;
export const docNumFilterSelector = (state: RootState) => state.docNumFilter;
export const configureFilterSelector = (state: RootState) => state.configureFilter;
export const docNumListSelector = (state: RootState) => state.docNumList;


// auth
export const userSelector = (state: RootState) => state.user;
export const projectsSelector = (state: RootState) => state.projects;



