import React from 'react';
import {ComponentLoader} from "../ComponentLoader";

export function ModalLoader() {
    return (
        <div style={{
            width: "100%",
            height: 150
        }}>
            <ComponentLoader/>
        </div>
    );
}

