import React from 'react';
import {Button} from "@mantine/core";
import {DEFAULT_STYLES} from "../themeStyles";

export function CancelButton({children, onClick, disabled}: {children?: any, onClick: ()=>void, disabled?:boolean }) {
    return (
        <Button disabled={(disabled) ? true : false} onClick={onClick} styles={{
            root: {
                backgroundColor: DEFAULT_STYLES.cancelColor,
                '&:hover': {
                    backgroundColor: "#a71313",
                }
            }
        }} color="red">{children ? children : "Abbrechen"}</Button>
    );
}

