import React, {useEffect} from "react";
import {createStyles, LoadingOverlay} from '@mantine/core';


const useStyles = createStyles((theme, _params, getRef) => ({

    container: {
        width: "100vw",
        height: "100vh"
    }
}));

export function FullscreenLoader({visible, children}: {visible: boolean,children?: any}) {
    // Note that position: relative is required
    const styles = useStyles();

    //NOTE: Dear React Gods, I'm deeply sorry for this sin.
    useEffect(() => {
        return () =>{
            document.body.style.overflowY = 'auto';
        }
    }, []);
    useEffect(() => {

        if (visible){
            document.body.style.overflowY = 'hidden';
        }else{
            document.body.style.overflowY = 'auto';
        }
    }, [visible])
    return (
        <>
            <div style={{  }}>
                <LoadingOverlay className={styles.classes.container} loaderProps={{ size: 'xl', color: "#FFFFFF", variant: "dots" }}
                                transitionDuration={300} overlayOpacity={0.7}
                                overlayColor="#1D2327"
                                visible={visible} >
                    {children}
                </LoadingOverlay>
            </div>
        </>


    );
}