import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Badge, Button, Text, createStyles} from '@mantine/core';
import {closeAllModals, openConfirmModal} from "@mantine/modals";
import 'dayjs/locale/de';
import {useAppDispatch} from "../../../redux/hooks";
import {useSelector} from "react-redux";
import {
    docNumFilterSelector, projectsSelector, userSelector,
} from "../../../redux/rootReducer";
import {UI_TEXTS} from "../../../texts/ui";
import {isAbortError} from "../../../texts/apiErrors";
import {ConfirmModalHeader, ConfirmType, ThunkButtons} from "../../../common/global/modals/ConfirmModal";
import {DEFAULT_STYLES} from "../../../common/global/themeStyles";
import {nanoid} from "nanoid";
import {ModalHeader} from "../../../common/global/modals/ModalHeader";

import {ModalSettings} from "@mantine/modals/lib/context";
import {ModalLoader} from "../../../common/global/modals/ModalLoader";
import {
     useSectionStyle, SectionIconWidth
} from "./CreateDocument";

import {
    setConfigureFilter
} from "../../../redux/slices/main/documents/get/configureFilterSlice";
import {GetDocNumDataResponse, getDocNumDataThunk} from "../../../redux/asyncThunks/main/documents/getDocNumDataThunk";
import SmallLabel from "../../../common/global/headers/SmallLabel";
import {IconAlertCircle} from "@tabler/icons";
import {Trash} from "tabler-icons-react";
import {deactivateDocNumThunk} from "../../../redux/asyncThunks/main/documents/deactivateDocument";
import {setFilter} from "../../../redux/slices/main/documents/get/filterDocNumSlice";
import {DataDisplay, DataDisplayItem} from "../../../common/global/DataDisplay";
import {hasMinimumAppRole} from "../../../redux/slices/auth/userSlice";
import {hasMinimumProjectRole, PROJECT_ROLE} from "../../../redux/slices/auth/projectsSlice";

export function ManageDocumentModal(documentId: string):ModalSettings{

    const modalId = nanoid(3)
    return {
        modalId: modalId,
        onClose: ()=>{
            // ignore
            return
        },
        size: DEFAULT_STYLES.modalWidth,
        children: (
            <ModalContainer modalId={modalId} documentId={documentId}/>
        ),
    }
}

function ModalContainer ({modalId, documentId}: {modalId: string, documentId: string}){

    const [docNumData, setDocNumData] = useState<null | GetDocNumDataResponse>(null);
    const dispatch = useAppDispatch()

    useEffect(() => {

        if (!docNumData){

            // getting docNumData
            const request = dispatch(getDocNumDataThunk(documentId))
            request.then((action)=> {

                if (getDocNumDataThunk.fulfilled.match(action)){

                    if (action.payload){
                        setDocNumData(action.payload)
                        return;
                    }

                }else{

                    // check if aborted
                    if (isAbortError(action.error)){
                        return
                    }
                }

                // unable to get DocKeyData
                openConfirmModal({
                    cancelProps: { style: {display:"none"} },
                    onConfirm() {
                        closeAllModals()
                    },
                    labels: {cancel: "", confirm: UI_TEXTS.main.global.docNum.manage.errorOpeningButton},
                    title: <ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.main.global.docNum.manage.errorOpeningTitle} message={UI_TEXTS.main.global.docNum.manage.errorOpeningMessage} />
                })
            })

            return () => {
                // `createAsyncThunk` attaches an `abort()` method to the promise
                request.abort()
            }
        }
    }, []);

    return (
        <div key={modalId.concat("0")} style={{
            padding: DEFAULT_STYLES.modalContainerPadding
        }}>
            <ModalHeader closeCallback={()=>{

                //check if there are any inputs
                closeAllModals()

            }} modalId={modalId} key={modalId.concat("1")} title="Dokument verwalten" hideBorder subtitle=""></ModalHeader>
            {
                [(docNumData !== null) ? <Content key={modalId.concat("2")} docNumData={docNumData!}/> : <ModalLoader/>]
            }
        </div>
    )
}

function InformationSection({title, children, docNumData} : {title?: string, children: any, docNumData: GetDocNumDataResponse}) {

    const {classes} = useSectionStyle()
    const projectsState = (useSelector(projectsSelector))

    return (
        <div className={classes.section} style={{paddingBottom: docNumData.docNum.isActive ? 14 : 0, borderBottom: docNumData.docNum.isActive && hasMinimumProjectRole(projectsState.activeProject?.projectRole, PROJECT_ROLE.SUPER_USER ) ? undefined : "none",}}>
            <div className={classes.headerContainer}>
                <img src='./illustrations/main/documents/InfoIcon.svg' width={SectionIconWidth}/>
                <p className={classes.header}>{title ? title : "Informationen"}</p>
            </div>
            <div>
                {children}
            </div>
        </div>
    );

}

function DocNumDataDisplay({docNumData} : {docNumData: GetDocNumDataResponse}) {

    const attributeBadges = docNumData.extendedAttributeValueLinks.map(element => {
        return <AttributeBadge key={element._id} label={element.attribute.label} value={element.attributeValue.value}/>
    })

    const dataDisplayItems = [
        <DataDisplayItem label="Erstellungsdatum" data={new Date (docNumData.docNum.timestamp).toLocaleString("de-DE", {
            timeZone: 'Europe/Berlin',
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: 'numeric', minute: 'numeric'  })}/>,
        <DataDisplayItem label="Ersteller" data={docNumData.creatorName}/>,
        <DataDisplayItem label="Projekt" data={docNumData.projectName}/>,
        <DataDisplayItem label="Status" data={ docNumData.docNum.isActive ? <Badge style={{marginLeft: 0}} variant="light" color="green">aktiv</Badge> : <Badge style={{marginLeft: 0}} variant="light" color="red">inaktiv</Badge>}/>,
        <DataDisplayItem label="Id" data={docNumData.docNum._id}/>
    ]

    if (attributeBadges && attributeBadges.length > 0){
        dataDisplayItems.push( <DataDisplayItem label="Attribute" data={<div style={{display: "flex", marginTop: 2, flexWrap:"wrap", gap: 18}}>
            {attributeBadges}
        </div>}/>)
    }

    return <DataDisplay items={dataDisplayItems}/>

}

function AttributeBadge({label, value}: {label: string, value: string}) {

    const {classes} = createStyles((theme, _params) => ({
        filterContainer: {
            display: "inline-flex",
            backgroundColor: "rgba(242,244,245,0.5)",
            color: "#000000",
            paddingTop:8,
            flexDirection: "row",
            paddingBottom:8,
            alignContent: "center",
            alignItems: "center",
            paddingLeft:18,
            paddingRight:18,
            borderRadius: 100,
            gap: 8,
            fontSize: theme.fontSizes.sm
        }
    }))()

    return (
        <div className={classes.filterContainer}>
            <div style={{display: "none", width: 3, height: 3, borderRadius: 100, backgroundColor: "#929292"}}/>
            <Text weight={500}>{label +":"}<Text style={{marginLeft: 5}} span weight={400}>{value}</Text></Text>

        </div>
    );
}


function NameSection({ docNumData} : {docNumData: GetDocNumDataResponse}) {

    const {classes} = useSectionStyle()
    //<Text weight={500}>Dokumentenname:<Text style={{marginLeft: 5}} span weight={400}>{docNumData.docNum.value}</Text></Text>
    /*
            <div style={{padding: 16, backgroundColor: DEFAULT_STYLES.defaultBackgroundColor}}>

            </div>
     */

    // marginTop: -35, paddingBottom: 36
    return (
        <div className={classes.section} style={{ marginTop: -38, paddingBottom: 38}}>

            <div style={{padding: 20, backgroundColor: DEFAULT_STYLES.defaultBackgroundColor, borderRadius: 3}}>
                <DataDisplayItem label="DOKUMENTENNAME" data={docNumData.docNum.value} />

            </div>

        </div>
    );
    return (
        <div className={classes.section} style={{ marginTop: -35, marginBottom: 31}}>

            <div style={{backgroundColor: "#FAFAFA", padding: 22, paddingBottom: 22, border:"none", marginBottom: 35}}>
                <Text style={{marginLeft: 0, marginTop: 2}} weight={500}>{docNumData.docNum.value}</Text>
            </div>

        </div>
    );
}

function EditSection({title, children} : {title?: string, children: any}) {

    const {classes} = useSectionStyle()

    return (
        <div className={classes.section} style={{ border: "none", marginBottom: 25}}>
            <div className={classes.headerContainer}>
                <img src='./illustrations/main/documents/EditIcon.svg' width={SectionIconWidth}/>
                <p className={classes.header}>{title ? title : "Bearbeiten"}</p>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}

function EditContent({docNumData} : {docNumData: GetDocNumDataResponse}) {

    const filter = useSelector(docNumFilterSelector)
    const dispatch = useAppDispatch()

    if (!docNumData.docNum.isActive){
        //TODO create a global Alert message style
        return <Alert  styles={{

            root: {
                color: "#000000",
                backgroundColor: DEFAULT_STYLES.defaultBackgroundColor,

            },
            title: {
                fontWeight: 600
            },
            wrapper: {
                backgroundColor: DEFAULT_STYLES.defaultBackgroundColor,
            }
        }} icon={<IconAlertCircle size={20} />} variant="light" title="Dokument ist inaktiv">
            Inaktive Dokumente können nicht bearbeitet werden.
        </Alert>
    }
    const {classes} = createStyles((theme, _params) => ({
        container: {
            display: "flex",
            gap: 8

        },
        section: {
            paddingBottom: 55,
            display: "flex",
            flexDirection: "column",
            gap: 3
        },
        labelContainer: {
            marginBottom: 4
        }
    }))()


    return (
        <div className={classes.container}>

            <Button onClick={()=>{
                const questionModalId = nanoid(4)
                openConfirmModal({
                    modalId: questionModalId,
                    children: (
                        <ThunkButtons modalId={questionModalId}
                                      labels={{
                                          confirm: UI_TEXTS.main.global.docNum.deactivate.deactivationQuestionButton
                                      }}
                                      responseTexts={{
                            success: {
                                title: UI_TEXTS.main.global.docNum.deactivate.deactivationSuccessTitle,
                                message: UI_TEXTS.main.global.docNum.deactivate.deactivationSuccessMessage,
                                buttonContinue: UI_TEXTS.main.global.docNum.deactivate.deactivationSuccessButton
                            },
                            error: {
                                title: UI_TEXTS.main.global.docNum.deactivate.deactivationFailedTitle,
                                message: UI_TEXTS.main.global.docNum.deactivate.deactivationFailedMessage,
                                buttonContinue: UI_TEXTS.main.global.docNum.deactivate.deactivationFailedButton
                            },
                        }} thunk={deactivateDocNumThunk} callback={()=> {
                            if (Object.keys(filter.filter).length === 0){
                                // empty filter
                                dispatch(setFilter({}))
                            }else {
                                dispatch(setFilter(filter.filter))
                            }
                        }} data={{ docNumId: docNumData.docNum._id, projectId: docNumData.docNum.projectId}}/>
                    ),
                    groupProps: {
                      style: {
                          display: "none"
                      }
                    },
                    closeOnConfirm: false,
                    closeOnClickOutside: true,
                    title: <ConfirmModalHeader  type={ConfirmType.None}
                                                title={UI_TEXTS.main.global.docNum.deactivate.deactivationQuestionTitle}
                                                message={UI_TEXTS.main.global.docNum.deactivate.deactivationQuestionMessage}/>
                })
            }} leftIcon={ <Trash style={{margin: 0, padding: 0}} strokeWidth={2} color={DEFAULT_STYLES.buttonGrayIconColor} size={22} />}  variant="outline">Deaktivieren</Button>

        </div>
    );

}


function Content({ docNumData} : {docNumData: GetDocNumDataResponse}) {

    const docNumFilter = useSelector(docNumFilterSelector)
    const dispatch = useAppDispatch()
    const projectsState = (useSelector(projectsSelector))

    useMemo(() => {

        dispatch(setConfigureFilter(docNumFilter.filter))

    }, [docNumFilter.filter]);


    return (
        <div>
            <div key={docNumData.docNum._id}>


                <NameSection docNumData={docNumData}/>
                <InformationSection docNumData={docNumData}><DocNumDataDisplay docNumData={docNumData}/></InformationSection>

                {docNumData.docNum.isActive && hasMinimumProjectRole(projectsState.activeProject?.projectRole, PROJECT_ROLE.SUPER_USER )  ? <EditSection><EditContent docNumData={docNumData} /></EditSection> : <></>}

            </div>
        </div>
    );
}





















