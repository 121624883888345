import {createStyles, Text} from "@mantine/core";
import React from "react";
import {SectionLabel} from "./SectionLabel";
import {DEFAULT_STYLES} from "../../global/themeStyles";

export function SectionFullHeader({label, description, required}: { label: string, description: string, required: boolean }) {

    const showDescription = description.trim() !== ""
    const {classes} = createStyles((theme, _params) => ({

        headerContainer: {
            marginBottom: showDescription ? 19 : 9,
            display: "flex",
            flexDirection: "column",
            gap: -20
        },
        header: {
            fontWeight: 600,
            color: DEFAULT_STYLES.headerColor,
            fontSize: theme.fontSizes.md
        },
        sectionContainer: {
            marginTop: "-2px !important;",
        }

    }))()

    return (
        <div className={classes.headerContainer}>
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <Text className={classes.header} >{label}</Text> {required ? <p style={{ marginLeft: 5, fontSize: 19, color:"#fa5252"}}>*</p> : null}
            </div>
            { showDescription ?  <SectionLabel className={classes.sectionContainer} >{description.trim()}</SectionLabel>  : <></> }
        </div>
    );
}
