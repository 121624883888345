import {createAsyncThunk} from "@reduxjs/toolkit"
import {API_ENDPOINTS, axiosInstance} from "../../../apiConfigs";



export interface IDeactivateAttributeValueThunk{
	attributeValueId: string,
	projectId: string
}

export const deactivateAttributeValueThunk = createAsyncThunk(
	'deactivateAttributeValueThunk/start',
	async (data: IDeactivateAttributeValueThunk, thunkAPI) => {
		const res = await axiosInstance.post(API_ENDPOINTS.private.attributeValue.deactivate, data)
		return res.data
	}
)

