import {
	AttributeSelectionValue
} from "../../../../asyncThunks/main/documents/create/getDocKeyDataThunk";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type ICreateDocNumData = DocKeyPartValue[]
export interface DocKeyPartValue {
	docKeyPartId : string;
	typeId: string;
	value: AttributeSelectionValue | string | null  // for comment: string, maximum of 100 characters - // escape null - empty comment either ignore or as "". No null
}

export const initialCreateDocNumDataState: ICreateDocNumData = []

const createDocNumDataSlice = createSlice({ //will be used to send to backend
	name: 'createDocNumData',
	initialState: initialCreateDocNumDataState,
	reducers: {

		resetCreateDocNumData: (state: ICreateDocNumData, action?: PayloadAction<undefined>) => {
			return initialCreateDocNumDataState
		},
		changeCreateDocNumData: (state, action: PayloadAction<DocKeyPartValue>) => {

			state = state.filter((existingDocKeyPartValue: any)=> {
				return existingDocKeyPartValue.docKeyPartId !== action.payload.docKeyPartId
			})

			if (action.payload.value !== null){
				// add the new one
				state.push(action.payload)
			}
			return state
		}
	}
})

export default createDocNumDataSlice.reducer
export const {resetCreateDocNumData, changeCreateDocNumData} = createDocNumDataSlice.actions
