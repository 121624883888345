import {ActionIcon, Container, createStyles, Group, Text, ThemeIcon} from '@mantine/core';
import {
    IconAt,
    IconBrandInstagram,
    IconBrandLinkedin,
    IconBrandTwitter,
    IconBrandYoutube,
    IconPhone
} from '@tabler/icons';
import {DEFAULT_STYLES} from "../../common/global/themeStyles";
import {nanoid} from "nanoid";
import {AppLogo} from "../../common/assetComponents/logos/appLogo";

const footerData = [
        {
            "title": "App",
            "links": [
                {
                    "label": "Informationen",
                    "link": "https://proquinn.com/index.html"
                },
                {
                    "label": "Fragen & Antworten",
                    "link": "https://proquinn.com/index.html"
                },
                {
                    "label": "Sicherheitskonzept",
                    "link": "https://proquinn.com/index.html"
                }
            ]
        }
        ]

export function Footer() {
    const { classes } = useStyles();

    const groups = footerData.map((group) => {
        const links = group.links.map((link, index) => (
            <Text<'a'>
                key={index}
                className={classes.link}
                component="a"
                href={link.link}
                onClick={(event) => event.preventDefault()}
            >
                {link.label}
            </Text>
        ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}

            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <img src='./logos/main.svg' width={DEFAULT_STYLES.logoSizes.md}/>
                    <Text size="xs" className={classes.description}>
                       Ein intelligentes Tool mit dem Dokumentenmanagement effizient gemacht wird. Entwickelt von ProQuinn.
                    </Text>
                    <Text size="xs"  className={classes.description}>
                    </Text>
                </div>

                   <div className={classes.groups}>
                     <div>
                            {groups}
                        </div>
                        <div>
                            <Text className={classes.title}>Kontakt</Text>
                            <div>
                                <div className={classes.contactLink}>
                                    <ThemeIcon variant="light" color="gray" size={28}>
                                        <IconAt size={21}/>
                                    </ThemeIcon>
                                    <Text<'a'>
                                        className={classes.link}
                                        key={nanoid(4)}
                                        component="a"
                                        href={"mailto:support@proquinn.com"}
                                        onClick={(event) => {
                                            event.preventDefault()

                                        }}
                                    >
                                        support@proquinn.com
                                    </Text>
                                </div>
                                <div className={classes.contactLink}>
                                    <ThemeIcon variant="light" color="gray" size={28}>
                                        <IconPhone size={21}/>
                                    </ThemeIcon>
                                    <Text<'a'>
                                        className={classes.link}
                                        key={nanoid(4)}
                                        onClick={(event) => {
                                            event.preventDefault()

                                        }}
                                    >
                                        +43 1 9978096
                                    </Text>
                                </div>
                            </div>

                        </div>
                    </div>

            </Container>



            <Container className={classes.afterFooter}>


                <div>
                    <Group style={{display: "none"}} spacing={0} className={classes.social} position="left" noWrap>
                        <ActionIcon size="xl">
                            <IconBrandTwitter size={25} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon style={{
                            display: "none"
                        }} size="xl">
                            <IconBrandYoutube size={18} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="xl">
                            <IconBrandLinkedin size={25} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                    <div className={classes.bottomContainer} >

                        <Text size="xs">
                            © 2022 ProQuinn GmbH. Alle Rechte vorbehalten.
                        </Text>

                        <a
                            href="https://proquinn.com/imprint.html"
                            className={classes.links}
                        >
                            Impressum
                        </a>
                        <a
                            href="https://proquinn.com/privacy_policy.html"
                            className={classes.links}
                        >
                            Datenschutzerklärung
                        </a>


                    </div>

                </div>



                <div style={{opacity: 0.5, display: "flex", gap: 17}}>
                    <AppLogo size={DEFAULT_STYLES.logoSizes.sm}/>
                    <img src='./logos/footer/ssl.png' width={115}/>
                    <img style={{marginRight: 39, marginLeft: 22, display:"none"}} src='./logos/footer/server-deutschland.png' width={105}/>
                    <img style={{marginRight: 39, marginLeft: 22, display:"none"}} src='./logos/footer/certificate.png' width={48}/>
                </div>
            </Container>

        </footer>
    );
}

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: 0,
        paddingTop: 12,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: "#737c84"

    },

    logo: {
        maxWidth: 500,
        marginRight: 30,

        [theme.fn.smallerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    description: {
        marginTop: 16,
        fontSize: 14
    },

    inner: {
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
        maxWidth: "100%",
        padding: DEFAULT_STYLES.defaultPadding,
        display: 'none', //flex
        justifyContent: 'space-between',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    groups: {
        display: 'flex',
        marginRight: 10,
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    wrapper: {
        width: 240,
    },

    link: {
        display: 'block',
        color: "#79828b",
        fontSize: 15,
        paddingTop: 3,
        fontWeight: 400,
        paddingRight: 20,
        paddingBottom: 3,

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    title: {
        fontSize: 15,
        fontWeight: 600,
        marginTop: 16,
        marginBottom: theme.spacing.xs / 2,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    afterFooter: {
        maxWidth: "100%",
        paddingLeft: DEFAULT_STYLES.defaultPadding,
        paddingRight: DEFAULT_STYLES.defaultPadding,
        paddingTop: 10,
        marginTop: 28,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },
    links: {
        fontSize: 11,
        fontWeight: 400,
        color: "#5a656c"

    },
    bottomContainer: {
        marginTop: 22,
        gap: 8,
        display: "flex",
    },
    contactLink: {
        gap: 3,
        color: "#79828b",
        alignItems: "center",
        display: "flex",
        fontWeight: 400,
        marginBottom: 6,
        marginRight: 0
    },
    social: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
        },
    },
}));
