import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ActionIcon, Alert, Button, createStyles, Loader, Select, SelectItem, Textarea, Tooltip} from '@mantine/core';
import {closeAllModals, closeModal, openConfirmModal, openModal} from "@mantine/modals";
import {ModalHeader} from "../../../common/global/modals/ModalHeader";
import {IconX} from "@tabler/icons";
import {ComponentLoader} from "../../../common/global/ComponentLoader";

import {nanoid} from "nanoid";
import {DEFAULT_STYLES} from "../../../common/global/themeStyles";
import {ModalLoader} from "../../../common/global/modals/ModalLoader";
import {useSelector} from "react-redux";
import {createDocNumDataSelector, projectsSelector} from "../../../redux/rootReducer";
import {
    DocNum,
    simulateCreateDocNumThunk,
    SimulateDocNumCreationResponse
} from "../../../redux/asyncThunks/main/documents/create/createDocNumThunk";
import {getDocKeyData} from "../../../redux/asyncThunks/main/documents/create/getDocKeyDataThunk";
import {useAppDispatch} from "../../../redux/hooks";
import {getAttributeValue} from "../../../redux/asyncThunks/main/documents/create/getAttributeValueThunk";
import {isAbortError} from "../../../texts/apiErrors";
import SmallLabel from "../../../common/global/headers/SmallLabel";
import {CancelButton} from "../../../common/global/buttons/CancelButton";
import {ConfirmButton} from "../../../common/global/buttons/ConfirmButton";
import {SendCreationRequest} from "./SendCreationRequest";
import {CopyValueButton} from "../../../common/global/buttons/CopyValueButton";
import {UI_TEXTS} from "../../../texts/ui";
import {ConfirmModalHeader, ConfirmType} from "../../../common/global/modals/ConfirmModal";
import {ActionGroupType, ModalActionGroup} from "../../../common/global/modals/ModalActionGroup";
import {DataDisplayItem} from "../../../common/global/DataDisplay";


export function SimulateCreationModal(){

    const modalId = nanoid(4)
    return {
        modalId: modalId,
        closeOnClickOutside: false,
        size: DEFAULT_STYLES.modalWidth,
        children: (
            <ModalContainer modalId={modalId}/>
        ),
    }
}

function ModalContainer ({modalId}: any){

    const createDocNumData = useSelector(createDocNumDataSelector)
    const projectState = useSelector(projectsSelector)

    const [data, setData] = useState<null | SimulateDocNumCreationResponse>(null);
    const [error, setError] = useState<null | string>(null);
    const dispatch = useAppDispatch()


    useEffect(() => {

        if (!data){

           const request = dispatch(simulateCreateDocNumThunk({projectId: projectState.activeProject?._id!, docKeyPartValues: createDocNumData}))
           request.then((action)=>{

               if (simulateCreateDocNumThunk.fulfilled.match(action)){

                   if (action.payload){
                       setData(action.payload)
                       setError(null)
                   }


               }else{
                   // check if aborted
                   if (isAbortError(action.error)){
                       return
                   }
                   setError("Error")

                   console.log("Error simulating docNum")
                   console.log("Message: " + action.error.message)
                   const confirmModalId = nanoid(4)
                   openConfirmModal({
                       cancelProps: {style: {display: "none"}},
                       onConfirm() {
                           closeModal(modalId)
                           closeModal(confirmModalId)
                       },
                       labels: {cancel: "", confirm: UI_TEXTS.main.global.docNum.create.simulationFailedButton},
                       closeOnClickOutside: false,
                       title:<ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.main.global.docNum.create.simulationFailedTitle} message={UI_TEXTS.main.global.docNum.create.simulationFailedDescription}/>
                   })

               }
           })
            return () => {
                // `createAsyncThunk` attaches an `abort()` method to the promise
               request.abort()
            }
        }

    }, []);

    return (
        <div style={{
            padding: DEFAULT_STYLES.modalContainerPadding
        }}>
            <ModalHeader modalId={modalId} title={data ? "Überprüfen" : "Wird simuliert.." } subtitle={data ? "Bitte den simulierten Namen überprüfen" : ""}></ModalHeader>
            {data ? <SimulateCreation modalId={modalId} docNum={data.docNum}/> : ( error ? "error" : <ModalLoader/>)}
        </div>
    )
}


function SimulateCreation({docNum, modalId}: {docNum: DocNum, modalId: string}) {

    return (
        <div>
            <DocNumDisplay docNum={docNum.value}/>

            <ModalActionGroup type={ActionGroupType.Together} buttons={{
                leftButton: <CancelButton onClick={()=>{
                    closeModal(modalId)
                }}>Abbrechen</CancelButton>,
                rightButton: <ConfirmButton onClick={()=>{
                    openModal(SendCreationRequest())
                }}>Erstellen</ConfirmButton>
            }}/>


        </div>
    );
}


export function DocNumDisplay({docNum} : {docNum: string}) {
    return (
        <div>
            <div style={{marginBottom: 30, padding: 20, backgroundColor: DEFAULT_STYLES.defaultBackgroundColor}}>
                <DataDisplayItem label={"DOKUMENTENNAME"} data={docNum}/>
            </div>
            <div style={{display: "flex"}}>
                <div style={{marginTop: -14, marginLeft: "auto", marginBottom: 60}}>
                    <CopyValueButton value={docNum} beforeCopyValue={"Name kopieren"} afterCopyValue={"Kopiert"}/>
                </div>
            </div>
        </div>


    );
}

