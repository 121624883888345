import {AuthContainer} from "../../common/auth/AuthContainer";
import {Button, Checkbox, createStyles, PasswordInput, TextInput} from "@mantine/core";
import React, {useEffect, useRef, useState} from "react";
import {At, Lock} from 'tabler-icons-react';
import {AuthNavigation} from "../../components/auth/AuthNavigation";
import {openConfirmModal, openModal} from '@mantine/modals';
import {INPUT_ICON_SIZE} from "../../common/global/themeStyles";
import HelpButton, {handleHelpClick} from "../../common/auth/HelpButton";
import {useModalCleanup} from "../../hooks/useModalCleanup";
import {UI_TEXTS} from "../../texts/ui";
import {useAppDispatch} from "../../redux/hooks";
import {IRegisterData, registerNewUser} from "../../redux/asyncThunks/auth/registerThunks";
import {ERROR_TEXTS, getErrorText} from "../../texts/apiErrors";
import {LoadingStatus} from "../../redux/util";
import {useIsMounted} from "../../hooks/useIsMounted";
import {ConfirmModalHeader, ConfirmType} from "../../common/global/modals/ConfirmModal";

const validator = require('validator');

export const Register = () => {
	return (
		<div>
			<AuthNavigation hideRightSection/>
			<AuthContainer  showReturnButton={true}  title={"Registrieren"} subTitle={"Um ein Konto zu erstellen, musst du alle Felder ausfüllen."} >
				<ContainerContent/>
			</AuthContainer>
		</div>
	)
}

function ContainerContent() {

	const {classes} = useStyles()

	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');

	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');


	const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState(true);

	const [requestState, setRequestState] = useState(LoadingStatus.idle);

	useModalCleanup()
	const dispatch = useAppDispatch()
	//const register = useAppSelector(registerSelector)

	const {isMounted} = useIsMounted()

	return (
		<div>
			<div className={classes.inputSection}>
				<TextInput description="" error={emailError} value={email} onChange={(event) => setEmail(event.currentTarget.value)} required label="E-Mail Adresse" placeholder="E-Mail" icon={<At size={INPUT_ICON_SIZE} />} />
				<PasswordInput description="Mindestens 8 Zeichen" error={passwordError} value={password} onChange={(event) => setPassword(event.currentTarget.value)} required label="Password" placeholder="Passwort" icon={<Lock size={INPUT_ICON_SIZE}  />}/>
				<TextInput error={firstNameError} value={firstName} onChange={(event) => setFirstName(event.currentTarget.value)} required label="Vorname" placeholder="Vorname"/>
				<TextInput error={lastNameError} value={lastName} onChange={(event) => setLastName(event.currentTarget.value)} required label="Nachname" placeholder="Nachname"/>

			</div>

			<div>
				<Checkbox styles={{
					root: {
						marginTop: 50
					},
					labelWrapper: {
						marginLeft: 12
					},
					label: {
						padding: 0
					}

				}} checked={privacyPolicyAgreed} onChange={(event) => setPrivacyPolicyAgreed(event.currentTarget.checked)}
					label={
						<div className={classes.checkboxSection}>
							Ich habe die {' '}
							<a  style={{

								color: "#363676"
							}} href="https://proquinn.com/imprint.html" target="_blank">
								Nutzungsbedingungen
							</a> und {' '}
							<a style={{
								color: "#363676"
							}} href="https://proquinn.com/privacy_policy.html" target="_blank">
								Datenschutzerklärungen
							</a> gelesen und stimme zu.
						</div>
					}
				/>
			</div>


			<div className={classes.buttonGroup}>
				<div className={classes.buttonGroup_leftSection}>
				</div>
				<div className={classes.buttonGroup_rightSection}>
					<Button loading={(requestState === LoadingStatus.pending)} variant="filled"  onClick={() => {

						let canPerformLogin = true

						// checking firstName
						if (firstName.length < 1){
							setFirstNameError(UI_TEXTS.auth.register.invalidFirstName)
							canPerformLogin = false;
						}else if (firstName.length > 30){
							setLastNameError(UI_TEXTS.auth.register.nameTooLong)
							canPerformLogin = false;
						}else{
							setFirstNameError("")
						}

						// checking lastName
						if (lastName.length < 1){
							setLastNameError(UI_TEXTS.auth.register.invalidLastName)
							canPerformLogin = false;
						}else if (lastName.length > 30){
							setLastNameError(UI_TEXTS.auth.register.nameTooLong)
							canPerformLogin = false;
						} else{
							setLastNameError("")
						}

						//checking email
						if (!validator.isEmail(email)){
							setEmailError(UI_TEXTS.auth.login.invalidEmail)
							canPerformLogin = false
						}else{
							setEmailError("")
						}

						//checking password
						if (!isPasswordValid(password)){
							setPasswordError(UI_TEXTS.auth.login.invalidPassword)
							canPerformLogin = false
						}else{
							setPasswordError("")
						}

						if (!canPerformLogin){
							return
						}

						// check if privacy policy is checked
						if (!privacyPolicyAgreed){

							openConfirmModal({
								cancelProps: { style: {display:"none"} },
								closeOnClickOutside: true,
								labels: {confirm: UI_TEXTS.auth.resetPassword.modalHelpTitle, cancel: "" },
								title: <ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.auth.register.privacyPolicyNotCheckedTitle} message={UI_TEXTS.auth.register.privacyPolicyNotCheckedDescription} />
							})

							return;
						}

						const registerData: IRegisterData = {
							firstName,
							lastName,
							password,
							email
						}

						const promise = dispatch(registerNewUser(registerData))
						setRequestState(LoadingStatus.pending)
						//a failed request or error in a thunk will never return a rejected promise
						promise.then((action) => {

							// don't display modal popup when component isn't mounted
							if (!isMounted.current) return

							if (registerNewUser.fulfilled.match(action)){

								// can perform login
								openConfirmModal({
									cancelProps: { style: {display:"none"} },
									confirmProps: { variant: "subtle" },
									closeOnClickOutside: false,
									onConfirm: () =>{
										handleHelpClick()
									},
									labels: {confirm: UI_TEXTS.auth.register.modalHelpTitle, cancel: "" },
									title: <ConfirmModalHeader type={ConfirmType.Success} title={UI_TEXTS.auth.register.modalSuccessTitle} message={UI_TEXTS.auth.register.modalSuccessDescription} />
								})


							}else{

								const errorText = getErrorText(ERROR_TEXTS.public.auth.register, String(action.error.code))!
								openConfirmModal({
									cancelProps: { style: {display:"none"} },
									title: <ConfirmModalHeader type={ConfirmType.Error} title={errorText.title} message={errorText.message} />
								})
							}

						}).finally(()=>{
							setRequestState(LoadingStatus.idle)
						} )

					}

					}>Weiter</Button>
				</div>
			</div>

			<div className={classes.buttonGroup_footer}>
				<div className={classes.buttonGroup_footer_left}>
				</div>
				<div className={classes.buttonGroup_footer_right}>
				</div>
			</div>

		</div>
	);
}

const useStyles = createStyles((theme, _params) => ({

	inputSection: {
		width: "100%",
		marginBottom: 32,
		display: "flex",
		flexDirection: "column",
		gap: 25
	},

	checkboxSection: {
		color: "#444445",
		fontSize: "13px !important;"
	},

	buttonGroup: {
		marginTop: 50,
		width: "100%",
		display: "flex",
		marginBottom: 30
	},

	buttonGroup_leftSection: {
	},

	buttonGroup_rightSection: {
		marginLeft: "auto"
	},

	buttonGroup_footer: {
		width: "100%",
		display: "flex",
		justifySelf: "flex-end"
	},

	buttonGroup_footer_right: {
		marginLeft: "auto",
		marginRight:-12
	},
	buttonGroup_footer_left: {
		marginLeft:-13
	}




}));

export function isPasswordValid (password: string){
	return password.length >= 8;
}

export default Register

