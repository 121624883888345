

export interface IErrorText {
    code: string,
    title: string,
    message: string
}


const ABORT_ERROR = "AbortError"
export function isAbortError (error: any){
    if (error.name === ABORT_ERROR){
        //name is "AbortError"
        //message is "Aborted"
        return true
    }else{
        return false
    }
}
const DEFAULT_ERROR_TEXT: IErrorText = {
    code: "500",
    title: "Fehler aufgetreten",
    message: "Leider ist ein Fehler aufgetreten. Bitte versuche es erneut. Wenn der Fehler weiterhin auftritt, kontaktiere den Support."
}
export const ERROR_TEXTS = {

    global: {
        unauthenticated : {
            code: "401",
            title: "Sitzung abgelaufen",
            message:"Du musst dich nun erneut anmelden. Falls dies öfters als normal vorkommt, überprüfe bitte deine Cookies oder kontaktiere unseren Support."
        }
    },
    public: {
        auth : {
            login : [
                {
                    code: "400",
                    title: "Anmeldung fehlgeschlagen",
                    message: "Leider war die Anmeldung nicht erfolgreich. Bitte probiere es erneut, oder versuche es später noch einmal."
                },
                {
                    code: "406",
                    title: "Passwort falsch",
                    message: "Das eingegebene Passwort stimmt nicht. Bitte überprüfe es, und versuche es noch einmal."
                },
                {
                    code: "404",
                    title: "Konto nicht gefunden",
                    message: "Ein Konto mit der angegebenen Email Adresse existiert nicht."
                }

            ] as IErrorText[],
            register : [
                {
                    code: "404",
                    title: "Felder nicht ausgefüllt",
                    message: "Es müssen alle Felder ausgefüllt werden, damit ein Konto erstellt werden kann."
                },
                {
                    code: "406",
                    title: "Konto existiert bereits",
                    message: "Für diese E-Mail Adresse ist bereits ein Konto hinterlegt. Gehe zurück zur Anmeldung und melde dich an."
                }

            ] as IErrorText[],
            verifyUser : [
                {
                    code: "401",
                    title: "Link abgelaufen",
                    message: "Ihr Konto konnte nicht verifiziert werden, da der Verifizierungslink bereits abgelaufen ist."
                }

            ] as IErrorText[],
            resetPassword : [
            {
                code: "404",
                title: "Kein Konto gefunden",
                message: "Es ist kein Konto mit der angegebenen E-Mail Adresse hinterlegt. Bitte überprüfe die E-Mail Adresse und versuche es erneut."
            },
            {
                    code: "429",
                    title: "Zurücksetzen nicht möglich",
                    message: "Du, oder jemand anderer, hat zu oft versucht das Passwort dieses Kontos zurückzusetzen. Aus Sicherheitsgründen kann das Passwort nicht mehr zurückgesetzt werden. Bitte kontaktiere den Support"
            }
        ] as IErrorText[],
            newPassword : [
                {
                    code: "401",
                    title: "Link ist abgelaufen",
                    message: "Der Verifizierungslink ist leider abgelaufen. Die Passwort-Zurücksetzung muss nun von vorne begonnen werden."
                }
            ] as IErrorText[]

        }
    },
    private: {
        documents: {
            attributeValue : {
                deactivate : [
                    {
                        code: "406",
                        title: "Löschung fehlgeschlagen",
                        message: "Es sind noch Dokumentennummern aktiv, die mit diesen Attributwert verknüpft sind. Der Attributwert kann erst gelöscht werden, wenn keine aktiven Verknüpfungen bestehen."
                    },
                    {
                        code: "402",
                        title: "Löschung fehlgeschlagen",
                        message: "Der Attributwert kann nicht gelöscht werden, da eine Abhängigkeit mit einem anderen Attributwert vorhanden ist."
                    }

                ] as IErrorText[]
            }
        }

    }

}

export const getErrorText = (array: IErrorText[] , targetCode: string, returnUndefined?: boolean) => {
    let errorText = array.find((element:IErrorText ) => {
        return element.code === targetCode
    })
    if (!errorText){
        if (returnUndefined === true){
            return undefined
        }
        errorText = DEFAULT_ERROR_TEXT
    }
    return errorText
}


