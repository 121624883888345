import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    ActionIcon,
    Alert,
    Button, CheckIcon,
    CopyButton,
    createStyles,
    Loader,
    Select,
    SelectItem,
    Textarea,
    Tooltip
} from '@mantine/core';
import {closeAllModals, closeModal, openConfirmModal, openModal} from "@mantine/modals";
import {ModalHeader} from "../../../common/global/modals/ModalHeader";
import {IconCheck, IconCopy, IconX} from "@tabler/icons";
import {ComponentLoader} from "../../../common/global/ComponentLoader";

import {nanoid} from "nanoid";
import {DEFAULT_STYLES} from "../../../common/global/themeStyles";
import {ModalLoader} from "../../../common/global/modals/ModalLoader";
import {useSelector} from "react-redux";
import {createDocNumDataSelector, docNumFilterSelector, projectsSelector} from "../../../redux/rootReducer";
import {
    createDocNumThunk,
    DocNum,
    simulateCreateDocNumThunk,
    SimulateDocNumCreationResponse
} from "../../../redux/asyncThunks/main/documents/create/createDocNumThunk";
import {getDocKeyData} from "../../../redux/asyncThunks/main/documents/create/getDocKeyDataThunk";
import {useAppDispatch} from "../../../redux/hooks";
import {getAttributeValue} from "../../../redux/asyncThunks/main/documents/create/getAttributeValueThunk";
import {isAbortError} from "../../../texts/apiErrors";
import SmallLabel from "../../../common/global/headers/SmallLabel";
import {CancelButton} from "../../../common/global/buttons/CancelButton";
import {ConfirmButton} from "../../../common/global/buttons/ConfirmButton";
import {UI_TEXTS} from "../../../texts/ui";
import {ConfirmModalHeader, ConfirmModalSubHeading, ConfirmType} from "../../../common/global/modals/ConfirmModal";
import {DocNumDisplay} from "./SimulateCreation";
import {ChevronDown} from "tabler-icons-react";
import {CopyValueButton} from "../../../common/global/buttons/CopyValueButton";
import {setFilter} from "../../../redux/slices/main/documents/get/filterDocNumSlice";
import {ActionGroupType, ModalActionGroup} from "../../../common/global/modals/ModalActionGroup";
import {ManageDocumentModal} from "./ManageDocument";


export function SendCreationRequest(){

    const modalId = nanoid(4)
    return {
        modalId: modalId,
        closeOnClickOutside: false,
        size: DEFAULT_STYLES.modalWidth,
        children: (
            <ModalContainer modalId={modalId}/>
        ),
    }
}

function ModalContainer ({modalId}: any){

    const createDocNumData = useSelector(createDocNumDataSelector)
    const projectState = useSelector(projectsSelector)

    const [data, setData] = useState<null | any>(null);

    const dispatch = useAppDispatch()
    const filter = useSelector(docNumFilterSelector)

    useEffect(() => {

        if (!data){

           const request = dispatch(createDocNumThunk({projectId: projectState.activeProject?._id!, docKeyPartValues: createDocNumData}))
            request.then((action)=>{

                if (createDocNumThunk.fulfilled.match(action)) {
                   setData(action.payload)

                   //success
                    closeAllModals()

                    const confirmModalId = nanoid(5)
                   openConfirmModal({
                       cancelProps: {style: {display: "none"}},
                       confirmProps: {style: {display: "none"}},
                       modalId: confirmModalId,
                       size: DEFAULT_STYLES.modalWidth,
                       labels: {cancel: "", confirm: UI_TEXTS.main.global.docNum.create.creationSuccessfulButton},
                       closeOnClickOutside: false,
                       children: <div>
                           <DocNumDisplay docNum={action.payload.docNum.value}/>
                           <ModalActionGroup type={ActionGroupType.Together} buttons={{
                               rightButton: <Button onClick={()=>{
                                   closeAllModals()
                                   // todo: maybe prettify?
                                   // todo: maybe show docNumDetails

                                   // triggering re-rendering by setting the filter object
                                   if (Object.keys(filter.filter).length === 0){
                                       dispatch(setFilter({}))
                                   }else {
                                       dispatch(setFilter(filter.filter))
                                   }

                                   openModal(ManageDocumentModal(action.payload.docNum._id))
                               }} >Weiter</Button>
                           }}/>
                       </div>,
                       title: <div style={{padding: DEFAULT_STYLES.modalContainerPadding }}><ConfirmModalHeader  type={ConfirmType.Success}
                                                       title={UI_TEXTS.main.global.docNum.create.creationSuccessfulTitle}
                                                       message={UI_TEXTS.main.global.docNum.create.creationSuccessfulDescription}/></div>
                   })

                    return
               }
                   // error
                   // check if aborted
                   if (isAbortError(action.error)){
                       return
                   }

                console.log("Error creating docNum")
                console.log("Message: " + action.error.message)

                //failed
                const confirmModalId = nanoid(4)
                openConfirmModal({
                    cancelProps: {style: {display: "none"}},
                    onConfirm() {
                        closeModal(confirmModalId)
                        closeModal(modalId)
                    },
                    labels: {cancel: "", confirm: UI_TEXTS.main.global.docNum.create.creationFailedButton},
                    closeOnClickOutside: false,
                    title:<ConfirmModalHeader type={ConfirmType.Error} title={UI_TEXTS.main.global.docNum.create.creationFailedTitle} message={UI_TEXTS.main.global.docNum.create.creationFailedDescription}/>
                })

           })
            return () => {
                // `createAsyncThunk` attaches an `abort()` method to the promise
               request.abort()
            }
        }

    }, []);

    return (
        <div style={{
            padding: DEFAULT_STYLES.modalContainerPadding
        }}>
            <ModalHeader modalId={modalId} title={"Wird erstellt.." } subtitle={"Bitte warten, das Dokument wird nun erstellt."}></ModalHeader>
            <ModalLoader/>

            <ModalActionGroup type={ActionGroupType.Together} buttons={{
                leftButton: <CancelButton onClick={()=>{
                    closeModal(modalId)
                }} />
            }}/>

        </div>
    )
}





