import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {API_ENDPOINTS, axiosInstance} from "../../../apiConfigs";



export interface IDeactivateDocumentRequest {
	projectId: string,
	docNumId: string,
}

export const deactivateDocNumThunk = createAsyncThunk(
	'deactivateDocNumThunk/start',
	async (data: IDeactivateDocumentRequest, thunkAPI) => {
		const res = await axiosInstance.post(API_ENDPOINTS.private.docNum.deactivate, data)
		return res.data
	}
)

