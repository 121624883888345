import {createStyles, Group, Menu, Navbar, Tooltip} from "@mantine/core"
import {IconChevronRight, IconMail, IconX} from "@tabler/icons"
import { PropsWithChildren, useState } from "react"
import {Link} from "react-router-dom"
import { ActionIcon } from '@mantine/core';
import {Logout} from "tabler-icons-react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {resetState, sidebarSelector, userSelector} from "../../redux/rootReducer";
import {setSlidebar} from "../../redux/slices/sidebarSlice";
import {DEFAULT_STYLES} from "../../common/global/themeStyles";
import {AppLogo} from "../../common/assetComponents/logos/appLogo";

interface Props {
	show?: boolean
}

enum SidebarLinks{
    Documents= "Dokumente"
}

export const Sidebar = (props: PropsWithChildren<Props>)  => {

    const { classes, cx } = useStyles()
    const [active, setActive] = useState(SidebarLinks.Documents);
    const [accountInfoOpen, setAccountInfoOpen] = useState(false);
    const sidebarState = useAppSelector(sidebarSelector);
    const dispatch = useAppDispatch()
    const userState = useAppSelector(userSelector);

    return  (
    <Navbar className={cx(classes.root, {[classes.sidebarClosed] : (!sidebarState.open)}) }  >

        <Navbar.Section className={classes.headerSection}>
          <div style={{
              display:"flex",
              justifyContent:"end",
              height: 45,
              width: "100%"
          }}>
                  {
                      sidebarState.open && <Tooltip openDelay={400} withArrow label="Menü schließen">
                          <ActionIcon variant="outline" onClick={()=>{
                              dispatch(setSlidebar(false))
                          }
                          }>
                              <IconX size={22}  />
                          </ActionIcon>
                      </Tooltip>

                  }

          </div>
				<Group align='baseline' position="apart" className={classes.logo}>
                    <AppLogo size={DEFAULT_STYLES.logoSizes.lg}/>
				</Group>
      </Navbar.Section>
        <Navbar.Section grow mt="xl">

            <div className={classes.linkContainer}>
                <div className={cx(classes.linkLeftSection, { [classes.linkLeftSectionActive]: SidebarLinks.Documents === active } )}/>
                <Link
                    to={`/documents`}
                    className={cx(classes.link, { [classes.linkActive]: SidebarLinks.Documents === active })}
                    key={1}
                    onClick={(event) => {
                        setActive(SidebarLinks.Documents);
                    }}
                >
                    <div>
                        <span>{SidebarLinks.Documents}</span>
                    </div>

                </Link>
            </div>

        </Navbar.Section>
        <Navbar.Section style={{
          display: "none"
      }}>
        <a
          href='mailto:support@proquinn.com'
          className={classes.link}>
          <IconMail className={classes.linkIcon} />
          <span>Hilfe</span>
        </a>
      </Navbar.Section>
        <Tooltip style={{
            display: accountInfoOpen ? "none":"initial"
        }} position="right" withArrow label="Konto verwalten">
        <Navbar.Section >
              <Menu opened={accountInfoOpen} onChange={setAccountInfoOpen}  position="right" withArrow offset={-15} transition="rotate-right" transitionDuration={200}>
                  <Menu.Target>
                      <div className={cx(classes.userGroup, {[classes.userGroup_active] : accountInfoOpen})}>
                          <div>
                              <p className={classes.userGroup_name}>{userState.data?.firstName + " " + userState.data?.lastName}</p>
                              <p className={classes.userGroup_email}>{userState.data?.email}</p>
                          </div>
                          <div className={cx(classes.userGroup_menuIcon)}>
                              <IconChevronRight color={DEFAULT_STYLES.defaultSubIconColor} />
                          </div>
                      </div>
                  </Menu.Target>
                  <Menu.Dropdown>
                      <Menu.Label>Konto verwalten</Menu.Label>
                      <Menu.Item color="red" icon={<Logout size={16}/>} onClick={() => {
                          dispatch(resetState())
                      }}
                      >
                          Abmelden
                      </Menu.Item>
                  </Menu.Dropdown>

              </Menu>

      </Navbar.Section>
        </Tooltip>

    </Navbar>
	)
}

const useStyles = createStyles((theme, _params, getRef) => {
    const icon = getRef("icon")
    return {

        sidebarClosed: {
            transform: "translateX(-500px)",
            opacity: 0,
            width: 0
        },
        root: {
            maxHeight: "100vh",
            width: 360,
            zIndex:0,
            transition: "all 0.5s ease 0s",
            boxSizing: 'border-box'
        },
        header: {
            paddingBottom: theme.spacing.md,
            marginBottom: theme.spacing.md * 1.5,
            borderBottom: `1px solid ${
                theme.colorScheme === "dark"
                    ? theme.colors.dark[4]
                    : theme.colors.gray[2]
            }`,
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
        } ,
        userGroup: {
            paddingLeft: 26,
            paddingRight: 16,
            paddingTop: 20,
            alignItems: "center",
            paddingBottom: 26,
            display: "flex",
            marginTop: theme.spacing.md,
            borderTop: `1px solid ${
                theme.colors.gray[3]
            }`,
            '&:hover': {
                backgroundColor: theme.colors.gray[0],
                cursor: "pointer",
                [`& .${getRef('menuIcon')}`]: {

                }
            }
        },

        linkLeftSection: {
            minWidth: 4,
            minHeight: "100%"
        },
        linkLeftSectionActive: {
            backgroundColor: "#0804C9",
        },
        linkContainer: {
            height: 46,
            width: "100%",
            display: "flex"
        },
        headerSection: {
            padding: 26,
            marginBottom: 16,
        },
        logo: {
            marginTop: 22
        },
        link: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
            paddingTop: 15,
            width: "100%",
            paddingBottom: 15,
            paddingLeft: 30,
            paddingRight: 30,
            fontWeight: 500,
            '&:hover': {
                backgroundColor: "#e6ebfd",
                color: "#4444bf",
                textDecoration: "underline"
            },

        },

        linkIcon: {
            ref: icon,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
            marginRight: theme.spacing.sm,
        },

        linkActive: {
            color: "#0804C9",
            backgroundColor: "#F8F7FD"
        },
        userGroup_name:{
            fontSize: (theme.fontSizes.sm),
            fontWeight: 500,
            marginRight: 20,
            maxWidth: "100%"
        },
        userGroup_email:{
            marginTop: 1,
            color: "#86858D",
            fontSize: theme.fontSizes.xs
        },
        userGroup_menuIcon:{
            ref: getRef('menuIcon'),
            marginLeft: "auto"
        },
        userGroup_active:{
            opacity: "0.2",
            transition: "all 0.2s ease 0s"
        }
    }
})
